




export const NumberTop = (numero, cor) => {

  const red = ['vermelho', 'vermelhoVerde'];
  const orange= ['laranja', 'laranjaCiano'];
  const yellow= ['amarelo', 'goldVermelho', 'goldVerde', 'goldAzul', 'goldRoxo', 'goldAmarelo'];
  const white= ['branco', 'silverVermelho', 'silverVerde', 'silverAzul', 'silverRoxo', 'silverAmarelo'];
  const green= ['verde', 'verdeVermelho'];
  const blue= ['azul', 'azulAmarelo'];
  

    if (numero === '7' && red.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F01%20ver%2F7%20(2).png?alt=media&token=4cb65e20-a3b4-4a1a-ad70-9961e847b411&_gl=1*15h33c2*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3MTI0OC4yMDMuMS4xNjk2MzczNjYyLjU3LjAuMA..';
    } else if (numero === '6' && red.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F01%20ver%2F6%20(1).png?alt=media&token=b5945986-7069-4689-9e09-98d6f7984c96&_gl=1*1rnsgs7*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3MTI0OC4yMDMuMS4xNjk2MzczNjcyLjQ3LjAuMA..';
    } else if (numero === '5' && red.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F01%20ver%2F5%20(1).png?alt=media&token=25ce5643-eb89-472a-9bda-9e80e5133af0&_gl=1*xhvk5i*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3MTI0OC4yMDMuMS4xNjk2MzczNjgxLjM4LjAuMA..';
    } else if (numero === '4' && red.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F01%20ver%2F4%20(2).png?alt=media&token=f4aa162c-6b64-468b-9fd1-42f19a705c98&_gl=1*s15jgx*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3MTI0OC4yMDMuMS4xNjk2MzczNjg5LjMwLjAuMA..';
    } else if (numero === '3' && red.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F01%20ver%2F3%20(1).png?alt=media&token=b510306c-b0bd-4c24-b603-7af84e3a0f61&_gl=1*ixd5g2*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3MTI0OC4yMDMuMS4xNjk2MzczNjk4LjIxLjAuMA..';
    } else if (numero === '2' && red.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F01%20ver%2F2%20(2).png?alt=media&token=c81e91f4-64fa-4a36-b661-2cb93c156ca3&_gl=1*1qopows*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3MTI0OC4yMDMuMS4xNjk2MzczNzA2LjEzLjAuMA..';
    } else if (numero === '1' && red.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F01%20ver%2F1%20(2).png?alt=media&token=bb7762e8-171b-456d-a16f-031cc53b2d87&_gl=1*q7ofwd*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3MTI0OC4yMDMuMS4xNjk2MzczNzE3LjIuMC4w';
    } 

    else if (numero === '7' && orange.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F02%20lan%2F7%20(2).png?alt=media&token=3ff27571-c844-41c7-90f6-41a5713165fd&_gl=1*1b7ttia*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2ODIzLjUwLjAuMA..';
    } else if (numero === '6' && orange.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F02%20lan%2F6%20(1).png?alt=media&token=a2f9b907-5f8b-42bb-bff9-9b7c4c22fa08&_gl=1*1umjzo6*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2ODEzLjYwLjAuMA..';
    } else if (numero === '5' && orange.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F02%20lan%2F5%20(1).png?alt=media&token=6f9ea052-41ab-4c2c-9bae-5b07ae0a3420&_gl=1*1nr2u7w*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2ODAwLjQuMC4w';
    } else if (numero === '4' && orange.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F02%20lan%2F4%20(2).png?alt=media&token=dd9a0af0-7448-469f-add3-af66fb7a012c&_gl=1*1if7x15*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2Nzg1LjE5LjAuMA..';
    } else if (numero === '3' && orange.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F02%20lan%2F3%20(1).png?alt=media&token=942b5057-ac7a-4f22-8bcc-9a33a694b8b2&_gl=1*4uhf43*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2NzcyLjMyLjAuMA..';
    } else if (numero === '2' && orange.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F02%20lan%2F2%20(2).png?alt=media&token=edc1a2df-304d-474f-8c17-314d54be1204&_gl=1*h2s63s*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2NzU5LjQ1LjAuMA..';
    } else if (numero === '1' && orange.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F02%20lan%2F1%20(2).png?alt=media&token=405e3734-ce24-46f3-86fe-e74d3e0f46cd&_gl=1*19vyhk7*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2NzQ2LjU4LjAuMA..';
    }

    else if (numero === '7' && yellow.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F03%20ama%2F7%20(2).png?alt=media&token=8f787161-ecb4-4744-831d-06474751b1d6&_gl=1*54swzp*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2OTIwLjU2LjAuMA..';
    } else if (numero === '6' && yellow.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F03%20ama%2F6%20(1).png?alt=media&token=24d0c13d-7af0-4108-87a7-daf2efbe81a4&_gl=1*1m77gm4*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2OTI5LjQ3LjAuMA..';
    } else if (numero === '5' && yellow.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F03%20ama%2F5%20(1).png?alt=media&token=7b4d68ae-0567-4a35-98cb-35f503a30d4b&_gl=1*l3h3vx*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2OTM4LjM4LjAuMA..';
    } else if (numero === '4' && yellow.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F03%20ama%2F4%20(2).png?alt=media&token=ccce97b1-227c-47a9-ab20-509f34d97767&_gl=1*rko7lt*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2OTQ4LjI4LjAuMA..';
    } else if (numero === '3' && yellow.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F03%20ama%2F3%20(1).png?alt=media&token=faff6134-f30c-4580-a059-3a76272a5c98&_gl=1*1o7teu0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2OTU5LjE3LjAuMA..';
    } else if (numero === '2' && yellow.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F03%20ama%2F2%20(2).png?alt=media&token=a2fc6696-4858-4087-9c8b-6a825b08f2c5&_gl=1*10vja1l*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2OTY5LjcuMC4w';
    } else if (numero === '1' && yellow.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F03%20ama%2F1%20(2).png?alt=media&token=66f86fc8-6a7c-431c-b3e4-1e3a82cb480e&_gl=1*1uqf07g*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc2OTgwLjYwLjAuMA..';
    }

    else if (numero === '7' && green.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F04%20verd%2F7%20(2).png?alt=media&token=916376d8-8c31-4b3c-81a8-de86570d17f3&_gl=1*ldow42*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MTE1LjU3LjAuMA..';
    } else if (numero === '6' && green.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F04%20verd%2F6%20(1).png?alt=media&token=e94c19f7-bde9-40e5-91b2-9916ae93a344&_gl=1*1wt80ce*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MTIzLjQ5LjAuMA..';
    } else if (numero === '5' && green.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F04%20verd%2F5%20(1).png?alt=media&token=87438224-fc3c-4383-8d63-fbb9f71fab3f&_gl=1*1bq5dd6*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MTMyLjQwLjAuMA..';
    } else if (numero === '4' && green.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F04%20verd%2F4%20(2).png?alt=media&token=db076a9c-ce64-4c80-9967-6964663435b1&_gl=1*1pjwzwt*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MTQyLjMwLjAuMA..';
    } else if (numero === '3' && green.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F04%20verd%2F3%20(1).png?alt=media&token=2e6e672f-9065-40d4-a81e-7f6f56b510c2&_gl=1*1l1bz58*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MTUyLjIwLjAuMA..';
    } else if (numero === '2' && green.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F04%20verd%2F2%20(2).png?alt=media&token=2400b809-854d-419e-af4b-96bf051a2f79&_gl=1*fwjgwa*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MTY0LjguMC4w';
    } else if (numero === '1' && green.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F04%20verd%2F1%20(2).png?alt=media&token=22825b9c-8c1f-4d0b-b51d-6b056d86d2af&_gl=1*1r3qeku*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MTc2LjYwLjAuMA..';
    }

    else if (numero === '7' && cor === 'ciano') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F05%20cia%2F7%20(2).png?alt=media&token=e12232a5-3408-4526-aa3e-7274d4f84633&_gl=1*1jut64h*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3Mjg2LjU1LjAuMA..';
    } else if (numero === '6' && cor === 'ciano') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F05%20cia%2F6%20(1).png?alt=media&token=a65d3734-8960-4c52-b3c2-153cda6e7c1e&_gl=1*930ogg*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3Mjk3LjQ0LjAuMA..';
    } else if (numero === '5' && cor === 'ciano') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F05%20cia%2F5%20(1).png?alt=media&token=ace2f937-0a08-4359-a3ce-42671c07e3fc&_gl=1*78i33g*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MzA4LjMzLjAuMA..';
    } else if (numero === '4' && cor === 'ciano') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F05%20cia%2F4%20(2).png?alt=media&token=05130890-5d43-4057-b28f-46ca814d5551&_gl=1*1hkg5il*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MzIyLjE5LjAuMA..';
    } else if (numero === '3' && cor === 'ciano') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F05%20cia%2F3%20(1).png?alt=media&token=80383ac4-3fc1-4364-a095-cfde11987ddf&_gl=1*1h72vyw*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MzM0LjcuMC4w';
    } else if (numero === '2' && cor === 'ciano') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F05%20cia%2F2%20(2).png?alt=media&token=1755c2a5-9acd-4c52-8e23-12c18c293786&_gl=1*1rh6yzi*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MzQzLjYwLjAuMA..';
    } else if (numero === '1' && cor === 'ciano') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F05%20cia%2F1%20(2).png?alt=media&token=a334e895-e91a-45ce-90a0-d93307e0f4bc&_gl=1*1s6t05n*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3MzUzLjUwLjAuMA..';
    }

    else if (numero === '7' && blue.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F06%20azu%2F7%20(2).png?alt=media&token=e3728284-9b3a-41ff-9267-9d901cab338e&_gl=1*1123hwa*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NDMwLjU1LjAuMA..';
    } else if (numero === '6' && blue.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F06%20azu%2F6%20(1).png?alt=media&token=970670d5-b540-4878-87fa-fcd2e93da080&_gl=1*saype3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NDQyLjQzLjAuMA..';
    } else if (numero === '5' && blue.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F06%20azu%2F5%20(1).png?alt=media&token=256518ca-40a4-4542-bbb5-07dca110b010&_gl=1*1muh9pm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NDUxLjM0LjAuMA..';
    } else if (numero === '4' && blue.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F06%20azu%2F4%20(2).png?alt=media&token=ed1b6437-dc89-4c57-a2bb-7cb2f014f853&_gl=1*qy8mxt*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NDYyLjIzLjAuMA..';
    } else if (numero === '3' && blue.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F06%20azu%2F3%20(1).png?alt=media&token=b56ea142-578b-4f35-9d4d-ee80f7e2155e&_gl=1*15an9ng*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NDcxLjE0LjAuMA..';
    } else if (numero === '2' && blue.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F06%20azu%2F2%20(2).png?alt=media&token=82580f57-69c2-4d3e-bdd4-bdd2e6242854&_gl=1*z0fn3n*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NDgxLjQuMC4w';
    } else if (numero === '1' && blue.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F06%20azu%2F1%20(2).png?alt=media&token=e1cbd8f2-cc10-436b-b06b-88f863d18c2f&_gl=1*b8bx7t*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NDkwLjYwLjAuMA..';
    }

    else if (numero === '7' && cor === 'roxo') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F07%20rox%2F7%20(2).png?alt=media&token=d9258e35-4fff-46ea-8558-5e5a07f04a88&_gl=1*9fqkkg*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NTU5LjU3LjAuMA..';
    } else if (numero === '6' && cor === 'roxo') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F07%20rox%2F6%20(1).png?alt=media&token=6256d57a-534f-4664-8f30-0b6c24ae0715&_gl=1*1migy3k*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NTY3LjQ5LjAuMA..';
    } else if (numero === '5' && cor === 'roxo') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F07%20rox%2F5%20(1).png?alt=media&token=53ff7410-f943-43d1-932a-d64c8d5fb950&_gl=1*11fxdor*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NTc2LjQwLjAuMA..';
    } else if (numero === '4' && cor === 'roxo') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F07%20rox%2F4%20(2).png?alt=media&token=3f5f5ab7-5fe1-4d20-a498-374f0cbdad1f&_gl=1*11euop4*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NTg3LjI5LjAuMA..';
    } else if (numero === '3' && cor === 'roxo') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F07%20rox%2F3%20(1).png?alt=media&token=2a6da577-5dd4-4802-be1f-ba6d761e1862&_gl=1*1r5s8fy*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NTk1LjIxLjAuMA..';
    } else if (numero === '2' && cor === 'roxo') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F07%20rox%2F2%20(2).png?alt=media&token=7ac233ec-c492-47cd-86d4-0da21a574a36&_gl=1*128fef1*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NjA1LjExLjAuMA..';
    } else if (numero === '1' && cor === 'roxo') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F07%20rox%2F1%20(2).png?alt=media&token=716b8c57-22d7-47c7-aed5-6cb6cd9f1ce6&_gl=1*f4v0mw*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NjE1LjEuMC4w';
    }

    else if (numero === '7' && cor === 'rosa') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F08%20ros%2F7%20(2).png?alt=media&token=404f8cf3-123b-442d-8951-6958af378abc&_gl=1*1qqz1au*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NzAzLjU4LjAuMA..';
    } else if (numero === '6' && cor === 'rosa') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F08%20ros%2F6%20(1).png?alt=media&token=74a08ee1-880e-40fd-9200-630bcc4ac146&_gl=1*on6b3u*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NzEyLjQ5LjAuMA..';
    } else if (numero === '5' && cor === 'rosa') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F08%20ros%2F5%20(1).png?alt=media&token=47910c55-4535-446a-90c3-794f44430459&_gl=1*6qwu4u*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NzIwLjQxLjAuMA..';
    } else if (numero === '4' && cor === 'rosa') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F08%20ros%2F4%20(2).png?alt=media&token=09913fe3-0bb2-4589-8e5e-7a7ba73e7eee&_gl=1*36bvgr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NzI4LjMzLjAuMA..';
    } else if (numero === '3' && cor === 'rosa') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F08%20ros%2F3%20(1).png?alt=media&token=26a1d8a4-a12e-48cc-9770-d29691154331&_gl=1*1ysydd6*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NzM5LjIyLjAuMA..';
    } else if (numero === '2' && cor === 'rosa') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F08%20ros%2F2%20(2).png?alt=media&token=64a7a7a6-5cb2-4885-87f6-17679d0cb9ff&_gl=1*xxv7tp*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NzUwLjExLjAuMA..';
    } else if (numero === '1' && cor === 'rosa') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F08%20ros%2F1%20(2).png?alt=media&token=741793f3-2130-484d-86b9-419a5ecd2422&_gl=1*pyhy54*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3NzU5LjIuMC4w';
    }

    else if (numero === '7' && cor === 'magenta') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F09%20mag%2F7%20(2).png?alt=media&token=f9d3a3fb-d533-41ea-929c-348082f8db0f&_gl=1*npkfkh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3ODE0LjU2LjAuMA..';
    } else if (numero === '6' && cor === 'magenta') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F09%20mag%2F6%20(1).png?alt=media&token=9388b102-447b-40fc-83d6-2d8f961d9f9b&_gl=1*hldnwv*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3ODIyLjQ4LjAuMA..';
    } else if (numero === '5' && cor === 'magenta') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F09%20mag%2F5%20(1).png?alt=media&token=030c89f8-57b1-4c41-a267-1ec4737e273c&_gl=1*mudxbv*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3ODM0LjM2LjAuMA..';
    } else if (numero === '4' && cor === 'magenta') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F09%20mag%2F4%20(2).png?alt=media&token=0b274c2d-ebab-4a60-a7a0-5119e2352569&_gl=1*1bap44t*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3OTIzLjEwLjAuMA..';
    } else if (numero === '3' && cor === 'magenta') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F09%20mag%2F3%20(1).png?alt=media&token=464da2cf-e0db-4626-84ad-f182b5aac8bf&_gl=1*oltdj2*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3ODUxLjE5LjAuMA..';
    } else if (numero === '2' && cor === 'magenta') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F09%20mag%2F2%20(2).png?alt=media&token=bc50a7c3-e7e6-4721-9804-e29984364582&_gl=1*huzdhv*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3ODYxLjkuMC4w';
    } else if (numero === '1' && cor === 'magenta') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F09%20mag%2F1%20(2).png?alt=media&token=3aea8a0d-f7ed-4132-9834-f7b77bc65927&_gl=1*1a2ruog*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3ODczLjYwLjAuMA..';
    }
    
    else if (numero === '7' && white.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F10%20bra%2F7%20(2).png?alt=media&token=4e2131f2-18e6-4a26-a024-b2398994d64c&_gl=1*1tukktj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3OTg1LjU3LjAuMA..';
    } else if (numero === '6' && white.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F10%20bra%2F6%20(1).png?alt=media&token=d6c2202d-0add-49a9-bb36-78c8ccc7e13f&_gl=1*1s45bqq*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc3OTkzLjQ5LjAuMA..';
    } else if (numero === '5' && white.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F10%20bra%2F5%20(1).png?alt=media&token=0057da5f-8096-4e19-ae98-2ce329ef290d&_gl=1*o2gkih*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc4MDAyLjQwLjAuMA..';
    } else if (numero === '4' && white.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F10%20bra%2F4%20(2).png?alt=media&token=c4371bc4-139b-4d46-bc4a-545d7216ccb5&_gl=1*v9l5v4*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc4MDExLjMxLjAuMA..';
    } else if (numero === '3' && white.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F10%20bra%2F3%20(1).png?alt=media&token=80b7577a-06c6-47c5-a24d-0e14bfcda358&_gl=1*2mw22u*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc4MDIwLjIyLjAuMA..';
    } else if (numero === '2' && white.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F10%20bra%2F2%20(2).png?alt=media&token=53c858da-d6a4-4c19-846c-3fdf7f68115f&_gl=1*gc98bp*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc4MDMxLjExLjAuMA..';
    } else if (numero === '1' && white.includes(cor)) {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F10%20bra%2F1%20(2).png?alt=media&token=925d934d-3a0c-401d-92c9-3451672d010b&_gl=1*5dbmzi*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjM3NjYyNC4yMDQuMS4xNjk2Mzc4MDQ2LjYwLjAuMA..';
    }
    
    return 'URL_IMAGEM_DEFAULT';
  };


  const somethingElse = () => {};

  export default somethingElse;