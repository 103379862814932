import './progress.css';
import PropTypes from 'prop-types';


const ProgressoC = ({progress}) => {
   return( <div className="bgProgressC">
    <div className="progress">
       {/*<div className='progress--logo'>
            <img className='progress-img'
            src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FKtalusBlack%20off.svg?alt=media&token=837ef109-a147-4a42-973c-1854f19d5a0d&_gl=1*9uuo5s*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzQ2OTU3MC4yNDkuMS4xNjk3NDcwNjU2LjYwLjAuMA..' 
            alt=''/>
            <div className='progress--reflex'></div>
        </div>*/}


        <div className='progress--container'>
            <div className='progress--barra' style={{ width: `${progress}%` }}/>
        </div>

    </div>
</div>)
}

ProgressoC.propTypes = {
    progress: PropTypes.number.isRequired,
  };


export default ProgressoC;