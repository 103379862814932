// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDHWEmF6SMqj_7-rRVWGLE5IxiJuaIfEGQ",
  authDomain: "ktalus-tattoo.firebaseapp.com",
  projectId: "ktalus-tattoo",
  storageBucket: "ktalus-tattoo.appspot.com",
  messagingSenderId: "617502609774",
  appId: "1:617502609774:web:5100925649740f1d2df17f"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp); 



export default firebaseApp;