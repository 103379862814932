import React, { useState } from "react";

function Preco({updatePreco}) {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [preco, setPreco] = useState("");

  const handleToggleClick = () => {
    const toggleONP = document.querySelector(".toggleONP");
    const toggleOFFP = document.querySelector(".toggleOFFP");
    const precoInput = document.querySelector("#preco");

    if (!isToggleOn) {
      toggleONP.style.display = "flex";
      toggleOFFP.style.display = "none";
      precoInput.style.visibility = "visible";
      precoInput.style.height = "auto";
    } else {
      toggleONP.style.display = "none";
      toggleOFFP.style.display = "flex";
      precoInput.style.visibility = "hidden";
      precoInput.style.height = "0";
    }

    setIsToggleOn(!isToggleOn);
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR').format(value);
  };

  const handlePrecoChange = (e) => {
    const value = parseFloat(e.target.value.replace(/[.,]/g, ""));
    setPreco(value);
    updatePreco(value);
  };

  return (
    <div className="alCol">
      <div className="labelToggle">
        Preço:
        <div className={`toggleONP ${isToggleOn ? "" : "hidden"}`} onClick={handleToggleClick}>
          <div className="toggleIn"></div>
        </div>
        <div className={`toggleOFFP ${isToggleOn ? "hidden" : ""}`} onClick={handleToggleClick}>
          <div className="toggleIn"></div>
        </div>
      </div>
      <input
        id="preco"
        className={`inputPreco ${isToggleOn ? "" : "hidden"}`}
        type="text"
        name="preco"
        autocomplete="off"
        value={formatPreco(preco)}
        onChange={handlePrecoChange}
        inputMode="numeric"
        style={{ 
          visibility: isToggleOn ? "visible" : "hidden",
          height: isToggleOn ? "auto" : "0",
          overflow: "hidden"
        }}
      />
    </div>
  );
} 

export default Preco;
