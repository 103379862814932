const EnviarMensagemAPI = ({ numero, nomeEstudio }) => {
  const apiUrl = 'https://teste.uzapi.com.br:3333/sendText';
  const sessionKey = '123456';

  const primeiraMensagem = `*Hey, ${nomeEstudio}!*\n\n*Seja bem-vindo(a) à KTALUS!*\nAgora você faz parte dos profissionais que têm um catálogo moderno e diferenciado.\n\nSempre que quiser acessar a plataforma para configurar o seu catálogo, use o link abaixo com o e-mail e a senha cadastrados:\nhttps://ktalus.pro/home\n\nPara facilitar sua jornada inicial, preparamos um Manual com as principais orientações para colocar seu catálogo online. Você encontrará este material na aba "Manual" dentro da plataforma.\n\nPor fim, salve este contato, pois será através dele que você poderá acessar nosso Suporte. Estamos disponíveis de segunda a sexta (exceto feriados), das 9h às 12h e das 14h às 18h.\n\nAtenciosamente, _*Equipe KTALUS*_`;

  const segundaMensagem = `🎁 *PARABÉNS!*\nPor ser 1 dos 100 primeiros a ter uma conta Ktalus, liberamos uma oferta especial para você. É só acessar a Plataforma e conferir.\n\nMas ó, só vale por 2 dias, hein? Não perca essa oportunidade.`;

  const enviarMensagem = async (text) => {
    const requestBody = {
      session: 'Ktalus',
      number: numero,
      text,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'SessionKey': sessionKey,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Erro na chamada API: ${response.statusText}`);
      }

      console.log(`Mensagem enviada com sucesso: ${text}`);
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };

  // Função que envia ambas as mensagens com um pequeno atraso
  const enviarMensagens = async () => {
    await enviarMensagem(primeiraMensagem); // Envia a primeira mensagem
    setTimeout(() => {
      enviarMensagem(segundaMensagem); // Envia a segunda mensagem após 2 segundos
    }, 2000);
  };

  // Chama a função ao montar o componente
  enviarMensagens();

  return null; // Retorna null, pois não há JSX a ser renderizado
};

export default EnviarMensagemAPI;
