

export const getImageURL = (estilo, cor) => {
    // Pode ser uma lógica mais complexa aqui, usando switch ou if-else
    if (estilo === 'estilo1' && cor === 'vermelho') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ver.png?alt=media&token=43f9552e-3285-42d5-a4d5-fc228df8cc99&_gl=1*1rphwk0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjE0LjYwLjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'laranja') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20lan.png?alt=media&token=13b3227d-9867-4fcc-99cd-e10c9aff3387&_gl=1*dkm9uk*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5MzI3LjYwLjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'amarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'verde') {
      return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20verd.png?alt=media&token=bf34a3f6-9465-4cd4-9581-2ea7d81cb022&_gl=1*1e6z1y0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDMwLjU4LjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'ciano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20cia.png?alt=media&token=8525c3c4-b2e1-4fec-acce-b60bfa26c037&_gl=1*15q9t0i*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDUxLjM3LjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'azul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20azul.png?alt=media&token=5ada0c3c-ea23-47d5-ba67-d97a30ea5c5c&_gl=1*1tjv2vx*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDkyLjYwLjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'roxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20rox.png?alt=media&token=04610136-270d-478e-b403-7dcf6f196a74&_gl=1*1gg9ose*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTEyLjQwLjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'rosa') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ros.png?alt=media&token=cc1aee4f-7364-45c1-9ff1-3e4c10d3d74a&_gl=1*poz97x*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTI4LjI0LjAuMA..';
    } else if (estilo === 'estilo1' && cor === 'magenta') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20mag.png?alt=media&token=dd32d22e-b80a-4caf-9fa6-2c9fa3f21618&_gl=1*16gp1v1*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTQ0LjguMC4w';
    } else if (estilo === 'estilo1' && cor === 'branco') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    } 
    
    else if (estilo === 'estilo1' && cor === 'goldVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'goldVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'goldAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'goldRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'goldAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ama.png?alt=media&token=a81d5561-1e3c-46eb-b3d3-ab6118b9c053&_gl=1*g7f4li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjQ2LjI4LjAuMA..';
    }

    else if (estilo === 'estilo1' && cor === 'silverVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'silverVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'silverAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'silverRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'silverAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20bra.png?alt=media&token=4e782170-fdfe-4fc5-83fb-2b3ef120bb8b&_gl=1*clgixj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NTU2LjYwLjAuMA..';
    }

    else if (estilo === 'estilo1' && cor === 'vermelhoVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20ver.png?alt=media&token=43f9552e-3285-42d5-a4d5-fc228df8cc99&_gl=1*1rphwk0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg4MjE0LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'laranjaCiano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20lan.png?alt=media&token=13b3227d-9867-4fcc-99cd-e10c9aff3387&_gl=1*dkm9uk*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5MzI3LjYwLjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'verdeVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20verd.png?alt=media&token=bf34a3f6-9465-4cd4-9581-2ea7d81cb022&_gl=1*1e6z1y0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDMwLjU4LjAuMA..';
    }else if (estilo === 'estilo1' && cor === 'azulAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fcanto%2Fst1%20azul.png?alt=media&token=5ada0c3c-ea23-47d5-ba67-d97a30ea5c5c&_gl=1*1tjv2vx*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4NjM5NS4xOTYuMS4xNjk2Mjg5NDkyLjYwLjAuMA..';
    }




        else if (estilo === 'estilo2' && cor === 'vermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ver.png?alt=media&token=84d7ffb8-37d3-4b93-a83d-ba5d21045762&_gl=1*9gi7pt*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjIzLjIyLjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'laranja') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20lan.png?alt=media&token=77e85cbc-644b-45e4-8120-6c1128597b89&_gl=1*cptgls*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjM5LjYuMC4w';
      } else if (estilo === 'estilo2' && cor === 'amarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'verde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20verd.png?alt=media&token=020adab3-da6c-4333-9dd0-964743289ae2&_gl=1*yezvxf*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjY4LjQ3LjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'ciano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20cia.png?alt=media&token=979de97e-47f8-4e53-ae2f-ca6d41938b65&_gl=1*4l4ltm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjgxLjM0LjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'azul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20azu.png?alt=media&token=8ccce2c9-3813-492c-87fc-dbef40815c8c&_gl=1*ebabsd*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjkxLjI0LjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'roxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20rox.png?alt=media&token=85cb560c-9ba8-4265-af40-0a22b906239a&_gl=1*qwjsu0*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzAzLjEyLjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'rosa') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ros.png?alt=media&token=c6adeeae-c5f7-4c8d-99d1-db0c2cb91a18&_gl=1*1pr4qfp*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzE0LjEuMC4w';
      } else if (estilo === 'estilo2' && cor === 'magenta') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20mag.png?alt=media&token=009ea0a0-534a-401d-9e53-e0ffc201ac8a&_gl=1*97esqf*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzI0LjYwLjAuMA..';
      } else if (estilo === 'estilo2' && cor === 'branco') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
      }


      else if (estilo === 'estilo2' && cor === 'goldVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'goldVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'goldAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'goldRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'goldAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ama.png?alt=media&token=94e535d2-070b-40c2-9328-45a719d7b718&_gl=1*1ow957m*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjU1LjYwLjAuMA..';
    }

    else if (estilo === 'estilo2' && cor === 'silverVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'silverVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'silverAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'silverRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'silverAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20bra.png?alt=media&token=34d4d16d-245c-4d51-b5e5-6face49e4213&_gl=1*9n0vvm*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NzM0LjUwLjAuMA..';
    }

    else if (estilo === 'estilo2' && cor === 'vermelhoVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20ver.png?alt=media&token=84d7ffb8-37d3-4b93-a83d-ba5d21045762&_gl=1*9gi7pt*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjIzLjIyLjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'laranjaCiano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20lan.png?alt=media&token=77e85cbc-644b-45e4-8120-6c1128597b89&_gl=1*cptgls*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjM5LjYuMC4w';
    }else if (estilo === 'estilo2' && cor === 'verdeVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20verd.png?alt=media&token=020adab3-da6c-4333-9dd0-964743289ae2&_gl=1*yezvxf*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjY4LjQ3LjAuMA..';
    }else if (estilo === 'estilo2' && cor === 'azulAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fcanto%2Fst2%20azu.png?alt=media&token=8ccce2c9-3813-492c-87fc-dbef40815c8c&_gl=1*ebabsd*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0NjkxLjI0LjAuMA..';
    }




      else if (estilo === 'estilo3' && cor === 'vermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ver.png?alt=media&token=625f2a29-509d-4603-b86a-dab688d96658&_gl=1*stohcl*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0Nzk0LjYwLjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'laranja') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20lan.png?alt=media&token=4f53ed04-15e7-4bc6-8c9b-f565973c42db&_gl=1*pal4e5*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODA4LjQ2LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'amarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'verde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20verd.png?alt=media&token=cdad931c-f10a-42e3-9be0-143d053b8867&_gl=1*12yesgl*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODM2LjE4LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'ciano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20cia.png?alt=media&token=61dd45eb-81d5-4be6-a5fa-968389e712f5&_gl=1*1hu5krv*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODQ4LjYuMC4w';
      } else if (estilo === 'estilo3' && cor === 'azul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20azu.png?alt=media&token=519a022d-f58a-408a-a28d-4b3edae553bc&_gl=1*10ee0jj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTIwLjU4LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'roxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20rox.png?alt=media&token=80b006d1-141c-423f-8267-18469ff84f95&_gl=1*7sl7li*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTM2LjQyLjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'rosa') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ros.png?alt=media&token=e84b6932-8d9d-453b-ae7b-7bfce631bb9a&_gl=1*1dd76ck*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTUxLjI3LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'magenta') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20mag.png?alt=media&token=7b57b9a7-0935-41de-a2f9-6bae491a8aed&_gl=1*l18eip*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTYyLjE2LjAuMA..';
      } else if (estilo === 'estilo3' && cor === 'branco') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
      }



      else if (estilo === 'estilo3' && cor === 'goldVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'goldVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'goldAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'goldRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'goldAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ama.png?alt=media&token=fb476ad1-1620-400b-bcf5-74236bc5da59&_gl=1*dnnqr*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODI0LjMwLjAuMA..';
    }

    else if (estilo === 'estilo3' && cor === 'silverVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }else if (estilo === 'estilo3' && cor === 'silverVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }else if (estilo === 'estilo3' && cor === 'silverAzul') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }else if (estilo === 'estilo3' && cor === 'silverRoxo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }else if (estilo === 'estilo3' && cor === 'silverAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20bra.png?alt=media&token=11def282-e253-47ba-a952-4c3ed089034e&_gl=1*1axa9z3*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTczLjUuMC4w';
    }

    else if (estilo === 'estilo3' && cor === 'vermelhoVerde') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20ver.png?alt=media&token=625f2a29-509d-4603-b86a-dab688d96658&_gl=1*stohcl*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0Nzk0LjYwLjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'laranjaCiano') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20lan.png?alt=media&token=4f53ed04-15e7-4bc6-8c9b-f565973c42db&_gl=1*pal4e5*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODA4LjQ2LjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'verdeVermelho') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20verd.png?alt=media&token=cdad931c-f10a-42e3-9be0-143d053b8867&_gl=1*12yesgl*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0ODM2LjE4LjAuMA..';
    }else if (estilo === 'estilo3' && cor === 'azulAmarelo') {
        return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fcanto%2Fst3%20azu.png?alt=media&token=519a022d-f58a-408a-a28d-4b3edae553bc&_gl=1*10ee0jj*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI5NDQ1My4xOTcuMS4xNjk2Mjk0OTIwLjU4LjAuMA..';
    }

// st4


else if (estilo === 'estilo4' && cor === 'vermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20ver.svg?alt=media&token=93517df6-b135-4d55-8c0f-611342e8f1a5';
} else if (estilo === 'estilo4' && cor === 'laranja') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20lar.svg?alt=media&token=84b28c01-d974-42ea-a789-229a50f1227d';
} else if (estilo === 'estilo4' && cor === 'amarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20ama.svg?alt=media&token=15f593c4-5278-4b05-9cf6-ef0e28bd0927';
} else if (estilo === 'estilo4' && cor === 'verde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20verd.svg?alt=media&token=188095bc-b20e-48e6-8412-852fc0a3cdff';
} else if (estilo === 'estilo4' && cor === 'ciano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20cia.svg?alt=media&token=626a391d-8f59-49f1-b3e1-a4831c752e67';
} else if (estilo === 'estilo4' && cor === 'azul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20azu.svg?alt=media&token=cbdcf299-f3e9-4e52-8c99-53be18376ba5';
} else if (estilo === 'estilo4' && cor === 'roxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20rox.svg?alt=media&token=a981d12a-0bfd-4e1b-aba5-7c3e16213f87';
} else if (estilo === 'estilo4' && cor === 'rosa') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20ros.svg?alt=media&token=f1ac768c-702e-4a53-afa0-0960112746c6';
} else if (estilo === 'estilo4' && cor === 'magenta') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20mag.svg?alt=media&token=cab3475e-f7dd-4c34-a79a-04a7da906c3d';
} else if (estilo === 'estilo4' && cor === 'branco') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20bra.svg?alt=media&token=a62cc7c7-778f-49d8-b15d-7df686694e16';
}



else if (estilo === 'estilo4' && cor === 'goldVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20ama.svg?alt=media&token=15f593c4-5278-4b05-9cf6-ef0e28bd0927';
}else if (estilo === 'estilo4' && cor === 'goldVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20ama.svg?alt=media&token=15f593c4-5278-4b05-9cf6-ef0e28bd0927';
}else if (estilo === 'estilo4' && cor === 'goldAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20ama.svg?alt=media&token=15f593c4-5278-4b05-9cf6-ef0e28bd0927';
}else if (estilo === 'estilo4' && cor === 'goldRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20ama.svg?alt=media&token=15f593c4-5278-4b05-9cf6-ef0e28bd0927';
}else if (estilo === 'estilo4' && cor === 'goldAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20ama.svg?alt=media&token=15f593c4-5278-4b05-9cf6-ef0e28bd0927';
}

else if (estilo === 'estilo4' && cor === 'silverVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20bra.svg?alt=media&token=a62cc7c7-778f-49d8-b15d-7df686694e16';
}else if (estilo === 'estilo4' && cor === 'silverVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20bra.svg?alt=media&token=a62cc7c7-778f-49d8-b15d-7df686694e16';
}else if (estilo === 'estilo4' && cor === 'silverAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20bra.svg?alt=media&token=a62cc7c7-778f-49d8-b15d-7df686694e16';
}else if (estilo === 'estilo4' && cor === 'silverRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20bra.svg?alt=media&token=a62cc7c7-778f-49d8-b15d-7df686694e16';
}else if (estilo === 'estilo4' && cor === 'silverAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20bra.svg?alt=media&token=a62cc7c7-778f-49d8-b15d-7df686694e16';
}

else if (estilo === 'estilo4' && cor === 'vermelhoVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20ver.svg?alt=media&token=93517df6-b135-4d55-8c0f-611342e8f1a5';
}else if (estilo === 'estilo4' && cor === 'laranjaCiano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20lar.svg?alt=media&token=84b28c01-d974-42ea-a789-229a50f1227d';
}else if (estilo === 'estilo4' && cor === 'verdeVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4%20verd.svg?alt=media&token=188095bc-b20e-48e6-8412-852fc0a3cdff';
}else if (estilo === 'estilo4' && cor === 'azulAmarelo') {
  return '';
}


// st5


else if (estilo === 'estilo5' && cor === 'vermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20ver.svg?alt=media&token=4b731d2c-55cc-4357-b9e7-12c75a34835e';
} else if (estilo === 'estilo5' && cor === 'laranja') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20lar.svg?alt=media&token=21ddb61c-9c1a-4008-bb16-dd21fed5e767';
} else if (estilo === 'estilo5' && cor === 'amarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20ama.svg?alt=media&token=9fa8eb54-d60c-418f-9435-557e0214a8b6';
} else if (estilo === 'estilo5' && cor === 'verde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20verd.svg?alt=media&token=00b7e220-b200-41ee-b2f5-1b284590900f';
} else if (estilo === 'estilo5' && cor === 'ciano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20cia.svg?alt=media&token=fea8a129-cd9f-4f2a-9494-f6091266c9af';
} else if (estilo === 'estilo5' && cor === 'azul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20azu.svg?alt=media&token=9ea0bc04-0311-4a95-ba58-eca0d2f5712b';
} else if (estilo === 'estilo5' && cor === 'roxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20rox.svg?alt=media&token=0c9f0e17-6b64-44c1-9fdd-675ffda8382f';
} else if (estilo === 'estilo5' && cor === 'rosa') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20ros.svg?alt=media&token=eebe3134-4a84-4efe-bcca-8d64867c0cda';
} else if (estilo === 'estilo5' && cor === 'magenta') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20mag.svg?alt=media&token=12e77f97-3d73-4409-9d3c-08e6d3e50299';
} else if (estilo === 'estilo5' && cor === 'branco') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20bra.svg?alt=media&token=4b51033e-1697-44d3-82e9-2dccc61dbc39';
}



else if (estilo === 'estilo5' && cor === 'goldVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20ama.svg?alt=media&token=9fa8eb54-d60c-418f-9435-557e0214a8b6';
}else if (estilo === 'estilo5' && cor === 'goldVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20ama.svg?alt=media&token=9fa8eb54-d60c-418f-9435-557e0214a8b6';
}else if (estilo === 'estilo5' && cor === 'goldAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20ama.svg?alt=media&token=9fa8eb54-d60c-418f-9435-557e0214a8b6';
}else if (estilo === 'estilo5' && cor === 'goldRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20ama.svg?alt=media&token=9fa8eb54-d60c-418f-9435-557e0214a8b6';
}else if (estilo === 'estilo5' && cor === 'goldAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20ama.svg?alt=media&token=9fa8eb54-d60c-418f-9435-557e0214a8b6';
}

else if (estilo === 'estilo5' && cor === 'silverVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20bra.svg?alt=media&token=4b51033e-1697-44d3-82e9-2dccc61dbc39';
}else if (estilo === 'estilo5' && cor === 'silverVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20bra.svg?alt=media&token=4b51033e-1697-44d3-82e9-2dccc61dbc39';
}else if (estilo === 'estilo5' && cor === 'silverAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20bra.svg?alt=media&token=4b51033e-1697-44d3-82e9-2dccc61dbc39';
}else if (estilo === 'estilo5' && cor === 'silverRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20bra.svg?alt=media&token=4b51033e-1697-44d3-82e9-2dccc61dbc39';
}else if (estilo === 'estilo5' && cor === 'silverAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20bra.svg?alt=media&token=4b51033e-1697-44d3-82e9-2dccc61dbc39';
}

else if (estilo === 'estilo5' && cor === 'vermelhoVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20ver.svg?alt=media&token=4b731d2c-55cc-4357-b9e7-12c75a34835e';
}else if (estilo === 'estilo5' && cor === 'laranjaCiano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20lar.svg?alt=media&token=21ddb61c-9c1a-4008-bb16-dd21fed5e767';
}else if (estilo === 'estilo5' && cor === 'verdeVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5%20verd.svg?alt=media&token=00b7e220-b200-41ee-b2f5-1b284590900f';
}else if (estilo === 'estilo5' && cor === 'azulAmarelo') {
  return '';
}


// st6


else if (estilo === 'estilo6' && cor === 'vermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20ver.svg?alt=media&token=78636c2f-2592-47f1-bbff-3768a6e3daff';
} else if (estilo === 'estilo6' && cor === 'laranja') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20lar.svg?alt=media&token=65931b9f-f6ad-4d99-a171-fadde96d1465';
} else if (estilo === 'estilo6' && cor === 'amarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20ama.svg?alt=media&token=f5a9def5-524f-4ae7-932f-2b3956a99a49';
} else if (estilo === 'estilo6' && cor === 'verde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20verd.svg?alt=media&token=0855335c-7ecd-4a01-910d-ee5c37bc5399';
} else if (estilo === 'estilo6' && cor === 'ciano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20cia.svg?alt=media&token=275e2884-9eef-4e6e-897d-8834c88a7371';
} else if (estilo === 'estilo6' && cor === 'azul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20azu.svg?alt=media&token=c0c26497-56ad-418b-8355-f73757a2a81a';
} else if (estilo === 'estilo6' && cor === 'roxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20rox.svg?alt=media&token=0647c8a7-3984-4913-a39f-cbd23f4f60ad';
} else if (estilo === 'estilo6' && cor === 'rosa') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20ros.svg?alt=media&token=c5a764ac-1495-434a-8ea0-367fcbfad973';
} else if (estilo === 'estilo6' && cor === 'magenta') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20mag.svg?alt=media&token=65938e63-6876-4d9a-af56-2eb2efb5c1d3';
} else if (estilo === 'estilo6' && cor === 'branco') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20bra.svg?alt=media&token=53a86aae-621a-41d2-bc26-c2d49792f41d';
}



else if (estilo === 'estilo6' && cor === 'goldVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20ama.svg?alt=media&token=f5a9def5-524f-4ae7-932f-2b3956a99a49';
}else if (estilo === 'estilo6' && cor === 'goldVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20ama.svg?alt=media&token=f5a9def5-524f-4ae7-932f-2b3956a99a49';
}else if (estilo === 'estilo6' && cor === 'goldAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20ama.svg?alt=media&token=f5a9def5-524f-4ae7-932f-2b3956a99a49';
}else if (estilo === 'estilo6' && cor === 'goldRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20ama.svg?alt=media&token=f5a9def5-524f-4ae7-932f-2b3956a99a49';
}else if (estilo === 'estilo6' && cor === 'goldAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20ama.svg?alt=media&token=f5a9def5-524f-4ae7-932f-2b3956a99a49';
}

else if (estilo === 'estilo6' && cor === 'silverVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20bra.svg?alt=media&token=53a86aae-621a-41d2-bc26-c2d49792f41d';
}else if (estilo === 'estilo6' && cor === 'silverVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20bra.svg?alt=media&token=53a86aae-621a-41d2-bc26-c2d49792f41d';
}else if (estilo === 'estilo6' && cor === 'silverAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20bra.svg?alt=media&token=53a86aae-621a-41d2-bc26-c2d49792f41d';
}else if (estilo === 'estilo6' && cor === 'silverRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20bra.svg?alt=media&token=53a86aae-621a-41d2-bc26-c2d49792f41d';
}else if (estilo === 'estilo6' && cor === 'silverAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20bra.svg?alt=media&token=53a86aae-621a-41d2-bc26-c2d49792f41d';
}

else if (estilo === 'estilo6' && cor === 'vermelhoVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20ver.svg?alt=media&token=78636c2f-2592-47f1-bbff-3768a6e3daff';
}else if (estilo === 'estilo6' && cor === 'laranjaCiano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20lar.svg?alt=media&token=65931b9f-f6ad-4d99-a171-fadde96d1465';
}else if (estilo === 'estilo6' && cor === 'verdeVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6%20verd.svg?alt=media&token=0855335c-7ecd-4a01-910d-ee5c37bc5399';
}else if (estilo === 'estilo6' && cor === 'azulAmarelo') {
  return '';
}


// st7


else if (estilo === 'estilo7' && cor === 'vermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20ver.svg?alt=media&token=531a91d0-6239-4478-9b75-fe43cdcea48b';
} else if (estilo === 'estilo7' && cor === 'laranja') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20lar.svg?alt=media&token=e4d199b1-7b32-4127-8496-a88a6806bc5b';
} else if (estilo === 'estilo7' && cor === 'amarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20ama.svg?alt=media&token=af275bd1-2cdb-476e-be62-6a839ffa4697';
} else if (estilo === 'estilo7' && cor === 'verde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20verd.svg?alt=media&token=01a98af6-269a-43cd-8ce2-79c7118893a9';
} else if (estilo === 'estilo7' && cor === 'ciano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20cia.svg?alt=media&token=3fd017a8-3204-4ee0-9f5b-d803e6ccc52d';
} else if (estilo === 'estilo7' && cor === 'azul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20azu.svg?alt=media&token=0b9b9c78-e1e1-4e1e-a6af-916fcb5d1577';
} else if (estilo === 'estilo7' && cor === 'roxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20rox.svg?alt=media&token=8c1ce8bd-f0df-4d4a-a401-cd08f89fd849';
} else if (estilo === 'estilo7' && cor === 'rosa') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20ros.svg?alt=media&token=ca2ef292-165a-4389-b9c1-9b1adea9e43e';
} else if (estilo === 'estilo7' && cor === 'magenta') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20mag.svg?alt=media&token=718f4d5c-341e-4e37-bcef-cb5de57eca2a';
} else if (estilo === 'estilo7' && cor === 'branco') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20bra.svg?alt=media&token=f2aab4d7-039b-46fa-bbc8-9f28ef7c6c72';
}



else if (estilo === 'estilo7' && cor === 'goldVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20ama.svg?alt=media&token=af275bd1-2cdb-476e-be62-6a839ffa4697';
}else if (estilo === 'estilo7' && cor === 'goldVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20ama.svg?alt=media&token=af275bd1-2cdb-476e-be62-6a839ffa4697';
}else if (estilo === 'estilo7' && cor === 'goldAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20ama.svg?alt=media&token=af275bd1-2cdb-476e-be62-6a839ffa4697';
}else if (estilo === 'estilo7' && cor === 'goldRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20ama.svg?alt=media&token=af275bd1-2cdb-476e-be62-6a839ffa4697';
}else if (estilo === 'estilo7' && cor === 'goldAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20ama.svg?alt=media&token=af275bd1-2cdb-476e-be62-6a839ffa4697';
}

else if (estilo === 'estilo7' && cor === 'silverVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20bra.svg?alt=media&token=f2aab4d7-039b-46fa-bbc8-9f28ef7c6c72';
}else if (estilo === 'estilo7' && cor === 'silverVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20bra.svg?alt=media&token=f2aab4d7-039b-46fa-bbc8-9f28ef7c6c72';
}else if (estilo === 'estilo7' && cor === 'silverAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20bra.svg?alt=media&token=f2aab4d7-039b-46fa-bbc8-9f28ef7c6c72';
}else if (estilo === 'estilo7' && cor === 'silverRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20bra.svg?alt=media&token=f2aab4d7-039b-46fa-bbc8-9f28ef7c6c72';
}else if (estilo === 'estilo7' && cor === 'silverAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20bra.svg?alt=media&token=f2aab4d7-039b-46fa-bbc8-9f28ef7c6c72';
}

else if (estilo === 'estilo7' && cor === 'vermelhoVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20ver.svg?alt=media&token=531a91d0-6239-4478-9b75-fe43cdcea48b';
}else if (estilo === 'estilo7' && cor === 'laranjaCiano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20lar.svg?alt=media&token=e4d199b1-7b32-4127-8496-a88a6806bc5b';
}else if (estilo === 'estilo7' && cor === 'verdeVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7%20verd.svg?alt=media&token=01a98af6-269a-43cd-8ce2-79c7118893a9';
}else if (estilo === 'estilo7' && cor === 'azulAmarelo') {
  return '';
}


// st8


else if (estilo === 'estilo8' && cor === 'vermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20ver.svg?alt=media&token=e5a7eb55-d4f2-47ef-9bdb-8f16a0aabd34';
} else if (estilo === 'estilo8' && cor === 'laranja') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20lar.svg?alt=media&token=30676d22-03cc-4b9c-b04e-8b246fdfc202';
} else if (estilo === 'estilo8' && cor === 'amarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20ama.svg?alt=media&token=037b9891-9f21-4fd7-93fe-8497355326b2';
} else if (estilo === 'estilo8' && cor === 'verde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20verd.svg?alt=media&token=bccf95d9-5659-4aed-85c2-68df338c4b89';
} else if (estilo === 'estilo8' && cor === 'ciano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20cia.svg?alt=media&token=15085f79-cc8c-484d-97d3-55d36793e360';
} else if (estilo === 'estilo8' && cor === 'azul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20azu.svg?alt=media&token=3b4d95c6-bd64-476e-b8c5-e5beab5fd5cf';
} else if (estilo === 'estilo8' && cor === 'roxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20rox.svg?alt=media&token=d77991f9-a661-4c16-9ef3-ff05188bfefe';
} else if (estilo === 'estilo8' && cor === 'rosa') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20ros.svg?alt=media&token=738de188-2cc3-4e5c-951b-963a6acdc46e';
} else if (estilo === 'estilo8' && cor === 'magenta') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20mag.svg?alt=media&token=3f4fce4b-d4a4-4e1c-be77-6d51e28f4a65';
} else if (estilo === 'estilo8' && cor === 'branco') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20bra.svg?alt=media&token=d75a7da7-ef60-4a2f-817a-7ed0f822746b';
}



else if (estilo === 'estilo8' && cor === 'goldVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20ama.svg?alt=media&token=037b9891-9f21-4fd7-93fe-8497355326b2';
}else if (estilo === 'estilo8' && cor === 'goldVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20ama.svg?alt=media&token=037b9891-9f21-4fd7-93fe-8497355326b2';
}else if (estilo === 'estilo8' && cor === 'goldAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20ama.svg?alt=media&token=037b9891-9f21-4fd7-93fe-8497355326b2';
}else if (estilo === 'estilo8' && cor === 'goldRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20ama.svg?alt=media&token=037b9891-9f21-4fd7-93fe-8497355326b2';
}else if (estilo === 'estilo8' && cor === 'goldAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20ama.svg?alt=media&token=037b9891-9f21-4fd7-93fe-8497355326b2';
}

else if (estilo === 'estilo8' && cor === 'silverVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20bra.svg?alt=media&token=d75a7da7-ef60-4a2f-817a-7ed0f822746b';
}else if (estilo === 'estilo8' && cor === 'silverVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20bra.svg?alt=media&token=d75a7da7-ef60-4a2f-817a-7ed0f822746b';
}else if (estilo === 'estilo8' && cor === 'silverAzul') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20bra.svg?alt=media&token=d75a7da7-ef60-4a2f-817a-7ed0f822746b';
}else if (estilo === 'estilo8' && cor === 'silverRoxo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20bra.svg?alt=media&token=d75a7da7-ef60-4a2f-817a-7ed0f822746b';
}else if (estilo === 'estilo8' && cor === 'silverAmarelo') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20bra.svg?alt=media&token=d75a7da7-ef60-4a2f-817a-7ed0f822746b';
}

else if (estilo === 'estilo8' && cor === 'vermelhoVerde') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20ver.svg?alt=media&token=e5a7eb55-d4f2-47ef-9bdb-8f16a0aabd34';
}else if (estilo === 'estilo8' && cor === 'laranjaCiano') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20lar.svg?alt=media&token=30676d22-03cc-4b9c-b04e-8b246fdfc202';
}else if (estilo === 'estilo8' && cor === 'verdeVermelho') {
  return 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8%20verd.svg?alt=media&token=bccf95d9-5659-4aed-85c2-68df338c4b89';
}else if (estilo === 'estilo8' && cor === 'azulAmarelo') {
  return '';
}



// st9


else if (estilo === 'estilo9' && cor === 'vermelho') {
  return '';
} else if (estilo === 'estilo9' && cor === 'laranja') {
  return '';
} else if (estilo === 'estilo9' && cor === 'amarelo') {
  return '';
} else if (estilo === 'estilo9' && cor === 'verde') {
  return '';
} else if (estilo === 'estilo9' && cor === 'ciano') {
  return '';
} else if (estilo === 'estilo9' && cor === 'azul') {
  return '';
} else if (estilo === 'estilo9' && cor === 'roxo') {
  return '';
} else if (estilo === 'estilo9' && cor === 'rosa') {
  return '';
} else if (estilo === 'estilo9' && cor === 'magenta') {
  return '';
} else if (estilo === 'estilo9' && cor === 'branco') {
  return '';
}



else if (estilo === 'estilo9' && cor === 'goldVermelho') {
  return '';
}else if (estilo === 'estilo9' && cor === 'goldVerde') {
  return '';
}else if (estilo === 'estilo9' && cor === 'goldAzul') {
  return '';
}else if (estilo === 'estilo9' && cor === 'goldRoxo') {
  return '';
}else if (estilo === 'estilo9' && cor === 'goldAmarelo') {
  return '';
}

else if (estilo === 'estilo9' && cor === 'silverVermelho') {
  return '';
}else if (estilo === 'estilo9' && cor === 'silverVerde') {
  return '';
}else if (estilo === 'estilo9' && cor === 'silverAzul') {
  return '';
}else if (estilo === 'estilo9' && cor === 'silverRoxo') {
  return '';
}else if (estilo === 'estilo9' && cor === 'silverAmarelo') {
  return '';
}

else if (estilo === 'estilo9' && cor === 'vermelhoVerde') {
  return '';
}else if (estilo === 'estilo9' && cor === 'laranjaCiano') {
  return '';
}else if (estilo === 'estilo9' && cor === 'verdeVermelho') {
  return '';
}else if (estilo === 'estilo9' && cor === 'azulAmarelo') {
  return '';
}


// st10


else if (estilo === 'estilo10' && cor === 'vermelho') {
  return '';
} else if (estilo === 'estilo10' && cor === 'laranja') {
  return '';
} else if (estilo === 'estilo10' && cor === 'amarelo') {
  return '';
} else if (estilo === 'estilo10' && cor === 'verde') {
  return '';
} else if (estilo === 'estilo10' && cor === 'ciano') {
  return '';
} else if (estilo === 'estilo10' && cor === 'azul') {
  return '';
} else if (estilo === 'estilo10' && cor === 'roxo') {
  return '';
} else if (estilo === 'estilo10' && cor === 'rosa') {
  return '';
} else if (estilo === 'estilo10' && cor === 'magenta') {
  return '';
} else if (estilo === 'estilo10' && cor === 'branco') {
  return '';
}



else if (estilo === 'estilo10' && cor === 'goldVermelho') {
  return '';
}else if (estilo === 'estilo10' && cor === 'goldVerde') {
  return '';
}else if (estilo === 'estilo10' && cor === 'goldAzul') {
  return '';
}else if (estilo === 'estilo10' && cor === 'goldRoxo') {
  return '';
}else if (estilo === 'estilo10' && cor === 'goldAmarelo') {
  return '';
}

else if (estilo === 'estilo10' && cor === 'silverVermelho') {
  return '';
}else if (estilo === 'estilo10' && cor === 'silverVerde') {
  return '';
}else if (estilo === 'estilo10' && cor === 'silverAzul') {
  return '';
}else if (estilo === 'estilo10' && cor === 'silverRoxo') {
  return '';
}else if (estilo === 'estilo10' && cor === 'silverAmarelo') {
  return '';
}

else if (estilo === 'estilo10' && cor === 'vermelhoVerde') {
  return '';
}else if (estilo === 'estilo10' && cor === 'laranjaCiano') {
  return '';
}else if (estilo === 'estilo10' && cor === 'verdeVermelho') {
  return '';
}else if (estilo === 'estilo10' && cor === 'azulAmarelo') {
  return '';
}




    // ... assim por diante
    return 'URL_IMAGEM_DEFAULT';
  };


  const somethingElse = () => {};

  export default somethingElse;