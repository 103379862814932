import React, { useEffect, useState } from 'react';
import HeaderPage from "../../components/C_Body/HeaderPage";
import AssTest from './P06AssTest';
import AssC from './P06AssC';

function P06Assinatura ({estudio, email, modoTest, statusConta, plano,
     dataConta, dataVal, proxCobranca, lastPagData, lastPagValor,  setComponenteAtivo }){
        console.log("Data ",dataVal)
        console.log("mode teste ",modoTest)

    const [mostrarPlanos, setMostrarPlanos]=useState(false);
    const [mostrarPlanosC, setMostrarPlanosC]=useState(false);

    const handleMostrarPlanos = () => {
        setMostrarPlanos(true);
      };


      function formataData(dataISO) {
        const meses = [
          "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"
        ];
      
        // Converte a string ISO para um objeto Date
        const data = new Date(dataISO);
      
        // Obtém o dia, mês e ano
        const dia = data.getDate();
        const mes = meses[data.getMonth()];
        const ano = data.getFullYear();
      
        // Retorna a data formatada
        return `${dia} ${mes} ${ano}`;
      }
      
      const dataValFormatada = formataData(dataVal);
      const dataLastPag = formataData(lastPagData);
      
      function converterParaDecimal(numero) {
        const numeroDecimal = numero / 100;
        return numeroDecimal.toFixed(2);
    }

    const valorConvertido = converterParaDecimal(lastPagValor);

    function abrirLink() {
        window.open('https://wa.me/5561986395151?text=Quero+cancelar+minha+assinatura', '_blank');
      }

    return(
        <div className='AssinaturaPage'>
            <HeaderPage pg={'Assinatura'} setComponenteAtivo={setComponenteAtivo} />
        
        <div className='assinaturaPage--container bgly' style={{ padding:'16px', borderRadius:'16px'}}>

            {/* Test */}
            { modoTest  && <div className='assinatura--test' >
                <div className='assinatura--info'>
                    <div className='assinatura--if'>Status<span className='spInfo' style={{color: statusConta ? '#94FF94' : '#FF8C8C'}}>{statusConta ? 'Ativo' : 'Inativo'}</span></div>
                    <div className='assinatura--if'>Plano atual<span className='spInfo'>Teste de 7 dias</span></div>
                    <div className='assinatura--if'>Válido até<span className='spInfo'>{dataValFormatada}</span></div>
                </div>

                <div className='btAssinatura btKtalus tDet--button2' onClick={handleMostrarPlanos}>Conhecer Planos</div>
            </div>}


            {/* Cliente */}
            {!modoTest && (
    <div className='assinatura--cliente'>
        <div className='assinatura--info'>
            <div className='assinatura--if'>
                Status
                <span className='spInfo' style={{ color: statusConta ? '#94FF94' : '#FF8C8C' }}>
                    {statusConta ? 'Ativo' : 'Inativo'}
                </span>
            </div>

            <div className='assinatura--if'>
                Plano atual
                <span className='spInfo'>
                    {plano === 'monthly' 
                        ? 'Mensal' 
                        : plano === 'yearly' 
                        ? 'Anual' 
                        : plano === 'teste' 
                        ? 'Teste' 
                        : plano === 'vital' 
                        ? 'Vitalício' 
                        : plano}
                </span>
            </div>

            <div className='assinatura--if'>
                Válido até
                <span className='spInfo'>
                    {plano === 'vital' ? 'Indeterminado' : dataValFormatada}
                </span>
            </div>
        </div>

        {/* Se o plano for diferente de 'vital', mostrar .assinatura--info2 e .botoesCliente */}
        {plano !== 'vital' && (
            <>
                <div className='assinatura--info2'>
                    <div className='assinatura--if2'>
                        Próxima data de cobrança
                        <span className='spInfo'>{dataValFormatada}</span>
                    </div>
                    <div className='assinatura--if2'>
                        Último pagamento
                        <span className='spInfo'>{dataLastPag} R${valorConvertido}</span>
                    </div>
                </div>

                <div className='botoesCliente'>
                    <div className='btAssinatura btKtalus tDet--button2' onClick={() => setMostrarPlanosC(true)}>
                        Planos
                    </div>
                    {/* <div className='btAssinatura'>Faturas</div> */}
                    <div className='btAssinaturaCancel' onClick={abrirLink}>
                        Cancelar assinatura
                    </div>
                </div>
            </>
        )}
    </div>
)}

        </div>
        
        {mostrarPlanos  && <AssTest setMostrarPlanos={setMostrarPlanos}/>}
        {mostrarPlanosC  && <AssC plano={plano} setMostrarPlanosC={setMostrarPlanosC} email={email}/>}
        </div>


    )
}


export default P06Assinatura;



