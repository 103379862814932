import React, { useState, useEffect } from 'react';

import Home from './AMembros';
import Home3 from './DKT/AMembros3';

const MembrosPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    // Adiciona o listener de redimensionamento
    window.addEventListener('resize', handleResize);

    // Remove o listener quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? <Home /> : <Home3 />}
    </div>
  );
};

export default MembrosPage;
