import { Helmet } from 'react-helmet';
import './App.css';
//import Header from './components/Header';
//import Body from './components/C_Body/Body'; 
//<Header/>
//<Body className="body"/>

//import Login from './pages/login/LoginPage';
//import Register from './pages/register/register';
import { AppRoutes } from './routes/AppRoutes';
import { BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <Helmet>
        <title>KTALUS</title>
        <meta property="og:title" content="KTALUS" />
        {/*<meta property="og:description" content="O catálogo das Tattoos" />*/}
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Fthumb2.jpg?alt=media&token=fe0dae06-0a33-4d4a-a27f-345a5ab1602e" />
        
      </Helmet>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
