import './items.css';
import { BiSolidEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { useState, useEffect } from 'react';
import EditSecao from './EditSecao';
import DeleteTattoo from './DeleteTattoo';// Importe o componente DeleteSecao
import EditTattoo from './EditTattoo';

function SettingsTattoo({ eID, scID, tattooID, nomeT, stDesc, descr,
  capa, img1, img2, img3, img4,
   stPreco, preco, stTamanho, tam, setIsSettingsVisible, openDeleteTattoo, closeDeleteTattoo, handleCancelar, onTattooSelect, openEditTattoo, closeEditTattoo }) {
  console.log('Este é o id da tattoo:',  tattooID)
  console.log('Este é o nome da tattoo:',  nomeT)
  const [editTattooVisible, setEditTattooVisible] = useState(false);
  const [deleteTattooVisible, setDeleteTattooVisible] = useState(false);


  const handleTattooSelect = () => {
    console.log('Este é o nome da tattoo2:',  nomeT)
    onTattooSelect({
      nomeT,
      id: tattooID,
      scID,
      eID,
      stDesc,
      stPreco,
      stTamanho,
      descr,
      preco,
      tam,
      img1,
      img2,
      img3,
      img4,
    });
  };

  useEffect(() => {
    console.log('Este é o nome da tattoo2:',  nomeT);
    handleTattooSelect();
    
  }, []);

  const openEditTattoo1 = () => {
    openEditTattoo(true);
    setEditTattooVisible(true);
  };

  const closeEditTattoo1 = () => {
    closeEditTattoo(false)
    setEditTattooVisible(false);
  };

  const openDeleteTattoo1 = () => {
    openDeleteTattoo(true)
    setDeleteTattooVisible(true);
  };

  const closeDeleteTattoo1 = () => {
    closeDeleteTattoo(true)
    setDeleteTattooVisible(false);
  };

  return (
    <div className='boxSet'>
      <div className='setEdit' onClick={openEditTattoo}>
        <BiSolidEdit className='iconSet'/>
        Editar Tattoo
      </div>
      <div className='setDel' onClick={openDeleteTattoo}>
        <MdDeleteForever className='iconSet'/>
        Deletar Tattoo
      </div>

     {/*} {editTattooVisible && (
        <EditTattoo ttID={tattooID} nomeT={nomeT} stDesc={stDesc} descr={descr} 
        img1={img1} img2={img2} img3={img3} img4={img4} 
        stTamanho={stTamanho} tam={tam} stPreco={stPreco} preco={preco}
        
        handleCancelar={closeEditTattoo} setIsSettingsVisible={setIsSettingsVisible} />
      )}

      {deleteTattooVisible && (
        <DeleteTattoo ttID={tattooID} nome={nomeT} eID={eID} scID={scID}
        img1={img1} img2={img2} img3={img3} img4={img4} handleCancelar={closeDeleteTattoo1} setIsSettingsVisible={setIsSettingsVisible} />
      )}*/}
    </div>
  );
}

export default SettingsTattoo;
