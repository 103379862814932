import React, { useState, useEffect } from "react";

function EditDescricao({ formData, setFormData }) {
  const [descr, setDescr] = useState(formData.descr);
  const [stDesc, setStDesc] = useState(formData.stDesc);
  const [contagem, setContagem] = useState((formData.descr && formData.descr.length) || 0);

  useEffect(() => {
    // Sincronizando o estado local com o estado do formulário.
    setDescr(formData.descr);
    setStDesc(formData.stDesc);
    setContagem(formData.descr.length);
  }, [formData]);

  const handleToggleClick = (status) => {
    setStDesc(status);
    setFormData({ ...formData, stDesc: status });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();  // Prevenir a ação padrão de quebra de linha no textarea
      const cursorPosition = e.target.selectionStart;
      const valor = e.target.value;
  
      if (valor) { // Verificar se 'valor' não é undefined
        const novoValor = valor.slice(0, cursorPosition) + '\n' + valor.slice(cursorPosition);
        if (novoValor && novoValor.length <= 800)  { // Verificar se 'novoValor' não é undefined
          setDescr(novoValor);  // Atualiza o estado local
          setFormData({ ...formData, descr: novoValor });  // Atualiza o estado global
        }
      }
    }
  };

  const handleDescricaoChange = (e) => {
    const newDescr = e.target.value;
    setDescr(newDescr);
    setFormData({ ...formData, descr: newDescr });
    setContagem(newDescr.length);
  };

  return (
    <div>
      <div className="labelToggle">
        Descrição:
        <div 
          className="toggleOND"
          style={{ display: stDesc ? 'flex' : 'none' }} 
          onClick={() => handleToggleClick(false)}
        >
          <div className="toggleIn"></div>
        </div>
        <div 
          className="toggleOFFD"
          style={{ display: !stDesc ? 'flex' : 'none' }} 
          onClick={() => handleToggleClick(true)}
        >
          <div className="toggleIn"></div>
        </div>
      </div>
      <textarea
        id="descricaoTattoo"
        rows="4"
        className="inputTextArea"
        maxLength="800"
        autocomplete="off"
        type="text"
        style={{ display: stDesc ? 'flex' : 'none' }}
        value={descr}
        onChange={handleDescricaoChange}
        onKeyDown={(e) => handleKeyDown(e, setDescr)}
      ></textarea>
      <div className='contadorDesc' style={{ display: stDesc ? 'flex' : 'none' }}>{contagem}/800</div>
    </div>
  );
} 

export default EditDescricao;
