import React, { useEffect, useState } from 'react';
import Marquee from 'react-marquee-slider';
import { collection, getDocs, query, where, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { getAuth, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'; // Autenticação
import { db } from '../.../../../services/firebaseConfig'; // Verifique o caminho correto para sua configuração do Firebase

function Clientes() {
  const [estudios, setEstudios] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const auth = getAuth();

  // Função para buscar os dados de estúdios e as respectivas sessões e tatuagens
  const fetchData = () => {
    const estudioRef = collection(db, 'estudio');

    // Usando onSnapshot para ouvir as mudanças em tempo real
    const unsubscribe = onSnapshot(estudioRef, async (snapshot) => {
      const estudioData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Itera sobre cada estúdio encontrado e busca as sessões e tatuagens correspondentes
      const estudiosComSSeTT = await Promise.all(estudioData.map(async (estudio) => {
        const estudioID = estudio.id;

        // Busca a quantidade de sessões para o estúdio
        const sessoesRef = collection(db, 'sessoes');
        const sessoesQuery = query(sessoesRef, where("EstudioID", "==", estudioID));
        const sessoesSnapshot = await getDocs(sessoesQuery);
        const SS = sessoesSnapshot.size;

        // Busca a quantidade de tatuagens para o estúdio
        const tattoosRef = collection(db, 'tattoos');
        const tattoosQuery = query(tattoosRef, where("EstudioID", "==", estudioID));
        const tattoosSnapshot = await getDocs(tattoosQuery);
        const TT = tattoosSnapshot.size;

        // Retorna o estúdio com as informações adicionais de SS e TT
        return {
          ...estudio,
          SS,
          TT
        };
      }));

      // Atualiza o estado com os estúdios e suas respectivas informações de SS e TT
      setEstudios(estudiosComSSeTT);
    });

    return () => unsubscribe();
  };

  // useEffect para buscar os dados quando o componente é carregado
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setIsAuthorized(true);
      fetchData();
    }
  }, [auth]);

  // Função para formatar a data para DD/MM/AA
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const jsDate = timestamp.toDate ? timestamp.toDate() : timestamp;
    return new Date(jsDate).toLocaleDateString('pt-BR');
  };

  // Função para realizar o login
  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsAuthorized(true);
      setError('');
      fetchData();
    } catch (error) {
      setError("Erro ao fazer login: " + error.message);
    }
  };

  // Função para criar uma nova conta (registro)
  const handleRegister = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setIsAuthorized(true);
      setError('');
      fetchData();
    } catch (error) {
      setError("Erro ao registrar: " + error.message);
    }
  };

  // Função para realizar logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsAuthorized(false);
      setEstudios([]);
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  // Função para excluir o estúdio e suas dependências
  const handleDelete = async (estudio) => {
    const estudioID = estudio.id;
    const userID = estudio.userID;

    try {
      // Apagar tatuagens
      const tattoosQuery = query(collection(db, 'tattoos'), where("EstudioID", "==", estudioID));
      const tattoosSnapshot = await getDocs(tattoosQuery);
      await Promise.all(tattoosSnapshot.docs.map((doc) => deleteDoc(doc.ref)));

      // Apagar sessões
      const sessoesQuery = query(collection(db, 'sessoes'), where("EstudioID", "==", estudioID));
      const sessoesSnapshot = await getDocs(sessoesQuery);
      await Promise.all(sessoesSnapshot.docs.map((doc) => deleteDoc(doc.ref)));

      // Apagar estúdio e usuário
      await deleteDoc(doc(db, 'user', userID));
      await deleteDoc(doc(db, 'estudio', estudioID));

      setEstudios((prev) => prev.filter((e) => e.id !== estudioID));
    } catch (error) {
      console.error("Erro ao excluir o estúdio:", error);
    }
  };

  // Renderização condicional com base na autenticação
  if (!isAuthorized) {
    return (
      <div className="login-container">
        <h2>Login</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="Senha" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button onClick={handleLogin}>Entrar</button>
        
      </div>
    );
  }

  return (
    <div className="clientes-container">
      <button onClick={handleLogout} style={{ backgroundColor: '#ff5555', color: '#fff', marginBottom: '20px' }}>
        Sair
      </button>
      <div className="clientes-box">
        <table className="clientes-tabela">
          <thead>
            <tr style={{ color: '#dffc01' }}>
              <th>Cliente</th>
              <th>eID</th>
              <th>User</th>
              <th>SS</th>
              <th>TT</th>
              <th>Status</th>
              <th>Plano</th>
              <th>Pagamento</th>
              <th>Validade</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            {estudios.map((estudio) => (
              <tr key={estudio.id}>
                <td>{estudio.nomeEstudio}</td>
                <td>{estudio.id}</td>
                <td>{estudio.userID}</td>
                <td>{estudio.SS}</td>
                <td>{estudio.TT}</td>
                <td>{estudio.statusConta ? 'Ativo' : 'Inativo'}</td>
                <td>{estudio.plano}</td>
                <td>{formatDate(estudio.lastPagData)}</td>
                <td>{formatDate(estudio.dataVal)}</td>
                <td onClick={() => handleDelete(estudio)} style={{ color: 'red', cursor: 'pointer' }}>
                  Excluir
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ width: '100%', height: '100px', background: '#333', color: 'white' }}>
      <Marquee velocity={25}>
        <h3 style={{ marginRight: '50px' }}>Texto no ticker 🚀</h3>
        <h3 style={{ marginRight: '50px' }}>Mais conteúdo! 🎉</h3>
        <h3 style={{ marginRight: '50px' }}>Deslizando sem parar! 💨</h3>
      </Marquee>
    </div>
    </div>
  );
}

export default Clientes;
