import './convites.css';
import { FaTicketAlt } from "react-icons/fa";
import { BiCopy } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import { useState, useEffect } from 'react';
import { collection, query, onSnapshot, addDoc, serverTimestamp, orderBy } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';  // Importando Firebase Authentication

function Convites() {
    const [convites, setConvites] = useState([]);
    const [isAuthorized, setIsAuthorized] = useState(false);  // Estado para verificar autorização
    const [showLoginForm, setShowLoginForm] = useState(false);  // Estado para controlar a exibição do formulário de login
    const [email, setEmail] = useState("");  // Estado para armazenar o e-mail
    const [password, setPassword] = useState("");  // Estado para armazenar a senha
    const [error, setError] = useState("");  // Estado para armazenar o erro de login

    // Verifica a autenticação e o e-mail
    useEffect(() => {
        const auth = getAuth();
        
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && user.email === 'plataforma@ktalus.pro') {
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
                console.log('Não autorizado');  // Se não for o e-mail correto, define como não autorizado
            }
        });

        return () => unsubscribe();
    }, []);

    // Função para gerar convite
    async function gerarConvite() {
        const novoConvite = {
            email: "", // campo de email vazio
            dataCriacao: serverTimestamp() // data e hora atuais do servidor
        };

        try {
            const docRef = await addDoc(collection(db, "invitation"), novoConvite);
            console.log("Convite gerado com sucesso com o ID:", docRef.id);
        } catch (error) {
            console.error("Erro ao gerar o convite:", error);
        }
    }

    // Obter os convites
    useEffect(() => {
        const convitesCollection = collection(db, 'invitation');
        const q = query(convitesCollection, orderBy("dataCriacao", "desc"));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newConvites = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

            setConvites(newConvites);
        });

        return () => unsubscribe();
    }, []);

    const copiarParaClipboard = (conviteId) => {
        const textoParaCopiar = `Seu *Convite-Link* é https://ktalus.pro/kreg?${conviteId}`;
        const textArea = document.createElement("textarea");
        textArea.value = textoParaCopiar;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        alert("Link de convite copiado para a área de transferência!");
    };

    // Função para atualizar o campo 'user'
    const atualizarUsuario = async (conviteId, novoUsuario) => {
        const conviteRef = doc(db, 'invitation', conviteId);

        try {
            await updateDoc(conviteRef, {
                user: novoUsuario
            });

            console.log("Usuário atualizado com sucesso!");
        } catch (error) {
            console.error("Erro ao atualizar o usuário:", error);
        }
    };

    // Função de login
    const login = async () => {
        const auth = getAuth();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setShowLoginForm(false);  // Fecha o formulário após o login
        } catch (err) {
            setError("Erro de login. Verifique as credenciais.");
            console.error("Erro de login:", err);
        }
    };

    // Função de logout
    const logout = async () => {
        const auth = getAuth();
        try {
            await signOut(auth);
            setIsAuthorized(false);  // Define como não autorizado após o logout
        } catch (err) {
            console.error("Erro ao sair:", err);
        }
    };

    // Renderizar o componente somente se o usuário estiver autorizado
    if (!isAuthorized) {
        return (
            <div>
                {!showLoginForm ? (
                    <div>
                        <button onClick={() => setShowLoginForm(true)}>Login</button>
                    </div>
                ) : (
                    <div>
                        <h3>Login</h3>
                        <input
                            type="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Senha"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <button onClick={login}>Entrar</button>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="plataforma-bg">
            <div className="convites--container">
                <div className='convite--button' onClick={gerarConvite}>
                    <FaTicketAlt style={{ fontSize: '32px' }} />
                    Gerar Convite
                </div>

                <div className='convite--codigos'>
                    {convites.map((convite, index) => (
                        <div className='codigo--container' key={convite.id}>
                            <div className='ctn--codigo'>
                                <div className='ctn--index'>#{index + 1}</div>
                                <div className='ctn--cd' style={{ color: convite.email ? '#FEEC6E' : '#fff' }}>{convite.id}</div>
                                {!convite.email ? (
                                    <div className='ctn--disp'><AiOutlineCheck />Disponível</div>
                                ) : (
                                    <div className='ctn--email'>{convite.email}</div>
                                )}
                                <div className='ctn--email'>{convite.user}</div>
                            </div>
                            {/*<div className='ctn--user'>
                                <input
                                    type='text'
                                    className='input--user'
                                    value={convite.user || ''}
                                    onChange={(e) => setConvites(prevConvites => {
                                        const updatedConvites = [...prevConvites];
                                        updatedConvites[index].user = e.target.value;
                                        return updatedConvites;
                                    })}
                                />
                                <div className='save--user' onClick={() => atualizarUsuario(convite.id, convite.user)}>Salvar</div>
                            </div>*/}
                            <div className='ctn--copy' onClick={() => copiarParaClipboard(convite.id)}><BiCopy /></div>
                        </div>
                    ))}
                </div>

                {/* Botão de logout */}
                <div className='logout--button'>
                    <button onClick={logout}>Sair</button>
                </div>
            </div>
        </div>
    );
}

export default Convites;
