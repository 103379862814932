import React, { useState, useEffect } from "react";

import {  AiOutlineClose } from "react-icons/ai";

import AssOfertaMensal from "./P06AssOfertaMensal";
import AssOfertaAnual from "./P06AssOfertaAnual";

function AssOferta({ setMostrarOferta, plano, email }){
    const [componenteAtivo, setComponenteAtivo] = useState("mensal");

    

    return(
        <div className="bgAcao">
            <div className={componenteAtivo === "mensal" ? "cardAssM" : "cardAssA"}>
                
                

<div className="closePlan" onClick={() => setMostrarOferta(false)}><AiOutlineClose/></div>

               {componenteAtivo === 'mensal' && <AssOfertaMensal plano={plano} setComponenteAtivo={setComponenteAtivo} email={email}/>}
               {componenteAtivo === 'anual' && <AssOfertaAnual plano={plano} setComponenteAtivo={setComponenteAtivo} email={email} />}




                

               

            </div>
        </div>
    )
}


export default AssOferta;