import React, { useEffect } from 'react';
import { FiArrowLeft } from "react-icons/fi";



function HeaderPage({ pg, setComponenteAtivo }) {

  const handleClick = () => {
    setComponenteAtivo('Plataforma');
  };

  useEffect(() => {
    // Adiciona um novo estado no histórico do navegador
    // Isso faz com que o botão voltar possa ser ativado
    window.history.pushState(null, null, window.location.pathname);

    const handlePopState = (event) => {
      event.preventDefault(); // previne o comportamento padrão de voltar
      handleClick(); // chama a função handleClick
      window.history.pushState(null, null, window.location.pathname); // reinsere o estado
    };

    // Adiciona o evento popstate ao window
    window.addEventListener('popstate', handlePopState);

    // Remove o evento ao desmontar o componente
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };

  }, []); // Dependência vazia para executar apenas na montagem

  return (
    <div className='bgHeaderPage'> 
    <div className='divider'></div>
    {/*<img className='headerCantoA'
    src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA..'
    alt=''/>
    <img className='headerCantoD'
    src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus.png?alt=media&token=6f3f33c2-b9e1-4df0-9b3b-e247d8f63421&_gl=1*1hc7r3b*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjUzNzc2Mi4yMTIuMS4xNjk2NTM3OTQyLjYwLjAuMA..'
    alt=''/>*/}
<FiArrowLeft className='backPlataform' onClick={handleClick}/>
    <img className='logoKtalus2' 
    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Ficon-ktalus%40150px.webp?alt=media&token=1a5b8fa0-94fa-448e-be45-c76cca121431" alt=""/>
    <p className="nomePagina">{pg}</p>
    
    
    </div>
  );
}

export default HeaderPage;
