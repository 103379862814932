import React, { useState, useEffect } from "react";
import {  BsWhatsapp } from "react-icons/bs";
import {  AiOutlineClose } from "react-icons/ai";
import AssCMensal from "./P06AssCMensal";
import AssCAnual from "./P06AssCAnual";


function Manual({onClose, plano, email }){
    
   

    return(

        <div id="suportecard" className="bgAcao" style={{ fontFamily:'montserrat'}}>
            <div className="cardAssM">
                
               

<div className="closePlan" onClick={onClose}><AiOutlineClose/></div>

<div className="ass-modoMensal " >
<div className="video-container">
<iframe
        src={`https://www.youtube.com/embed/XMBsvTTvyJ4`}
        title="Manual KTALUS"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe></div>
    
        </div>




                

               

     
         </div> </div>
    )
}


export default Manual;