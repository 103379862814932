import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode';

const CustomQRCode = ({ text, imagePath }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const generateQR = async () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const canvasSize = 316; // Tamanho do Canvas
        const imageSize = 100; // Tamanho da imagem
        const borderColor = 'white'; // Cor da borda
        const borderWidth = 4; // Largura da borda
      
        // Coordenadas para centralizar a imagem
        const x = (canvasSize - imageSize) / 2;
        const y = (canvasSize - imageSize) / 2;
      
        // 1. Gerar o QR Code com alta correção de erro
        await QRCode.toCanvas(canvas, text, {
          errorCorrectionLevel: 'H',
          width: canvasSize,
        });
      
        // 2. Sobrepor uma imagem no centro
        const img = new Image();
        img.src = imagePath;
        img.onload = function () {
          // 2.1 Salvar o estado atual do contexto
          ctx.save();
          
          // 2.2 Criar um caminho circular para o clip
          ctx.beginPath();
          ctx.arc(x + imageSize / 2, y + imageSize / 2, imageSize / 2, 0, Math.PI * 2);
          ctx.clip();
          
          // 2.3 Desenhar a imagem
          ctx.drawImage(img, x, y, imageSize, imageSize);
      
          // 2.4 Restaurar o contexto para remover o clip
          ctx.restore();
      
          // 3. Desenhar a borda
          ctx.beginPath();
          ctx.arc(x + imageSize / 2, y + imageSize / 2, imageSize / 2, 0, Math.PI * 2);
          ctx.strokeStyle = borderColor;
          ctx.lineWidth = borderWidth;
          ctx.stroke();
        };
      };

    generateQR();
  }, [text, imagePath]);

  return <canvas ref={canvasRef} width={316} height={316}></canvas>;
};

export default CustomQRCode;
