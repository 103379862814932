import React from 'react';
import EnviarMensagemAPI from '../pages/register/whats';

function Header ({ nome, perfil }){

    const handleCadastrarUsuarioClick = () => {
        EnviarMensagemAPI({numero:'5561985081771', nomeEstudio: `${nome}`}); // Chama a função cadastrarUsuario ao clicar no botão
      };

    return(
        <div className='bgHeader'> 
        <div className='divider'></div>
        {/*<img className='headerCantoA'
        src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus%202.svg?alt=media&token=efb9dc0a-e0e0-4eb2-98ed-41056c4e2c0b&_gl=1*scnnpt*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzEzMjQ0Mi4yMzUuMS4xNjk3MTM4Mjg5LjYwLjAuMA..'
        alt=''/>
        <img className='headerCantoD'
        src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus%202.svg?alt=media&token=efb9dc0a-e0e0-4eb2-98ed-41056c4e2c0b&_gl=1*scnnpt*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzEzMjQ0Mi4yMzUuMS4xNjk3MTM4Mjg5LjYwLjAuMA..'
        alt=''/>*/}

        <img className='logoKtalus' onClick=''
        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2" alt=""/>
        <div className='perfil1'>
        <p className="nomeEstudio">{nome}</p>
           <div className="borderUser">
               <img className="fotoPerfil" src={perfil || 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Fperfil%20off.svg?alt=media&token=59b40760-ce6a-48e8-a0ba-35d2e9adcbbf'} alt=''/>
           </div>
        </div>
        
        </div>
    )
}

export default Header