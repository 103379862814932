import { BsLink45Deg, BsQrCode, BsInfinity, BsFillKeyFill } from "react-icons/bs";
import { HiColorSwatch } from "react-icons/hi";
import { FaRankingStar } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { AiOutlineSafety } from "react-icons/ai";


function AssCAnual({setComponenteAtivo, plano, email}){

    function abrirLink() {
        window.open(`https://wa.me/5561986395151?text=Ol%C3%A1%2C+quero+mudar+meu+plano+para+o+Anual.+O+email+da+minha+conta+Ktalus+Ink++%C3%A9%3A+${email}`, '_blank');
      }

    return(
        <div className="ass-modoAnual">
                    <div className="ass--modo">
                        <div style={{ fontSize:'18px', fontWeight:'700'}} onClick={() => setComponenteAtivo("mensal")}>MENSAL</div>

                       

                        <div className="modoAssativo">ANUAL</div>
                    
                    </div>

               

                    <div style={{display:'flex', flexDirection:'row', marginLeft:'24px'}}>12x de</div>

                    <div className="ass--preco">
                    <div style={{fontSize:'28px', marginRight:'10px'}}>R$</div> 
            <div style={{fontSize:'116px', lineHeight:0.85, fontWeight:700, position:'relative' }}>97
            <div className="ass--mes" style={{fontWeight:400}} >/MÊS</div></div>    
                    </div>

                    <div style={{width:'100%', display:'flex', justifyContent:'center', fontSize:'14px', color:'#dffc01', fontWeight:'700', marginBottom:'16px'}}>ou R$966,12 à vista no Pix ou<br/>Cartão de Crédito {/*ou até<br/> 12x de R$117,17 no Cartão de Crédito*/}</div>

                    <div className="ass--itens">
                    <div className="ass--item"><FaRegCircleCheck style={{ fontSize:'18px', color:'#dffc01'}}/>Catálogo Personalizável</div>
                    <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Seções e Tattoos ilimitadas</div>
                    <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Seção TOP7</div>
                    <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Cartão de Visita Virtual</div>
                    <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Link Exclusivo</div>
                    <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>QR-Code</div>
                    <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Suporte</div>
                    
                    <div className="ass--item" style={{fontWeight:700}}><BsFillKeyFill style={{fontSize:'18px', color:'#dffc01'}}/>Acesso por 365 dias</div>

                </div>
                    {plano !== 'yearly' ? (<div className="ass--botaoCTAA" onClick={abrirLink}>Quero o Plano Anual</div>)
        :(<div className="ass--botaoPatual">Seu Plano Atual</div>)}
                    {plano !== 'yearly' ?(<div style={{fontSize:'12px', color:'#dffc01', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', gap:'4px'}}><AiOutlineSafety style={{fontSize:'16px'}}/> Compra segura pela Kiwify</div>):('')}

                </div>
    )
    
}


export default AssCAnual;