import './progress.css';
import PropTypes from 'prop-types';
import {  AiFillCheckCircle } from "react-icons/ai";


function ProgressoLista () {
   return( <div className="bgProgressSave">
    <div className="progress">
    <AiFillCheckCircle style={{ zIndex:1004, fontSize:'80px', color:'#71FE6E'}}/>
        Dados enviados com sucesso!
        <p style={{color:'#FEEC6E', fontSize:'14px'}}>Em breve entraremos em contato</p>

        

    </div>
</div>)
}




export default ProgressoLista;