import React, { useState } from "react";

function Descricao({ setDescricao }) {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [contagem, setContagem] = useState(0);

  const handleToggleClick = () => {
    const toggleOND = document.querySelector(".toggleOND");
    const toggleOFFD = document.querySelector(".toggleOFFD");
    const descricaoTattoo = document.querySelector("#descricaoTattoo");

    if (!isToggleOn) {
      toggleOND.style.display = "flex";
      toggleOFFD.style.display = "none";
      descricaoTattoo.style.display = "flex";
    } else {
      toggleOND.style.display = "none";
      toggleOFFD.style.display = "flex";
      descricaoTattoo.style.display = "none";
    }

    setIsToggleOn(!isToggleOn);
  };

  // Esta função cuida do evento de pressionar a tecla Enter no textarea
const handleKeyDown = (e, setDescricaoState) => {
  if (e.key === 'Enter') {
    e.preventDefault();  // Prevenir a ação padrão de quebra de linha no textarea
    const cursorPosition = e.target.selectionStart;
    const valor = e.target.value;

    if (valor) { // Verificar se 'valor' não é undefined
      const novoValor = valor.slice(0, cursorPosition) + '\n' + valor.slice(cursorPosition);
      if (novoValor && novoValor.length <= 800)  { // Verificar se 'novoValor' não é undefined
        setDescricaoState(novoValor);
      }
    }
  }
};

  const handleDescricaoChange = (e) => {
    setDescricao(e.target.value);
    setContagem(e.target.value.length);
  };

  return (
    <div>
      <div className="labelToggle">
        Descrição:
        <div className={`toggleOND ${isToggleOn ? "" : "hidden"}`} onClick={handleToggleClick}>
          <div className="toggleIn"></div>
        </div>
        <div className={`toggleOFFD ${isToggleOn ? "hidden" : ""}`} onClick={handleToggleClick}>
          <div className="toggleIn"></div>
        </div>
      </div>
      <textarea
        id="descricaoTattoo"
        rows="4"
        className={`inputTextArea ${isToggleOn ? "" : "hidden"}`}
        maxLength="800"
        type="text"
        autocomplete="off"
        style={{ display: isToggleOn ? "flex" : "none" }}
        onChange={handleDescricaoChange}
        onKeyDown={(e) => handleKeyDown(e, setDescricao)}
      ></textarea>
      <div className={`contadorDesc ${isToggleOn ? "" : "hidden"}`}>{contagem}/800</div>
    </div>
  );
} 

export default Descricao;
