import React, { useState } from "react";

function Tamanho({setTamanho}) {
  const [isToggleOn, setIsToggleOn] = useState(false);

  const handleToggleClick = () => {
    const toggleONT = document.querySelector(".toggleONT");
    const toggleOFFT = document.querySelector(".toggleOFFT");
    const tamInput = document.querySelector("#tam");

    if (!isToggleOn) {
      toggleONT.style.display = "flex";
      toggleOFFT.style.display = "none";
      tamInput.style.visibility = "visible";
      tamInput.style.height = "auto";
    } else {
      toggleONT.style.display = "none";
      toggleOFFT.style.display = "flex";
      tamInput.style.visibility = "hidden";
      tamInput.style.height = "0";
    }

    setIsToggleOn(!isToggleOn);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    // Verifica se o valor é numérico
    if (!/^[0-9]*$/.test(inputValue)) {
      event.target.value = inputValue.replace(/[^0-9]/g, "");
    }
    
    if (inputValue.length > 3) {
      event.target.value = inputValue.slice(0, 3); // Limita o campo a 3 caracteres
    }

    setTamanho(event.target.value);
  };

  return (
    <div className="alCol">
      <div className="labelToggle">
        Tamanho:
        <div className={`toggleONT ${isToggleOn ? "" : "hidden"}`} onClick={handleToggleClick}>
          <div className="toggleIn"></div>
        </div>
        <div className={`toggleOFFT ${isToggleOn ? "hidden" : ""}`} onClick={handleToggleClick}>
          <div className="toggleIn"></div>
        </div>
      </div>
      <div id="tam" className={`linhaTam ${isToggleOn ? "" : "hidden"}`} style={{ 
          visibility: isToggleOn ? "visible" : "hidden",
          height: isToggleOn ? "auto" : "0",
          overflow: "hidden"
        }}>
        <input
          className="inputTam"
          type="text"
          inputMode="numeric"
          autocomplete="off"
          pattern="\d*"  // Aceita apenas números
          name="tamanho"
          onInput={handleInputChange}
        />{" "}
        cm
      </div>
    </div>
  );
}

export default Tamanho;
