import React, { useState } from 'react';
import TattooDetails from "./TattooDetails";

function TattooC(props) {
  const { cor }= props;
  const { estilo }= props;
  const { numero }= props;

  const topBg = {
    vermelho: 'topVermelho', laranja: 'topLaranja', amarelo: 'topAmarelo', verde: 'topVerde', ciano: 'topCiano',
    azul:'topAzul', roxo:'topRoxo', rosa:'topRosa', magenta:'topMagenta', branco: 'topBranco',

    goldVermelho: 'topAmarelo', goldVerde:'topAmarelo', goldAzul:'topAmarelo', goldRoxo:'topAmarelo', goldAmarelo:'topAmarelo',

    silverVermelho: 'topBranco', silverVerde:'topBranco', silverAzul:'topBranco', silverRoxo:'topBranco', silverAmarelo:'topBranco',

    vermelhoVerde:'topVermelho', laranjaCiano:'topLaranja', verdeVermelho:'topVerde', azulAmarelo:'topAzul',
}

  // Estado para controlar a visibilidade do TattooDetails
  const [showDetails, setShowDetails] = useState(false);

  // Função para lidar com o clique, alternando o estado de showDetails
  const handleTattooClick = () => {
    setShowDetails(!showDetails);
  }

  const handleCloseDetails = () => {
    setShowDetails(false);
  }

  return (
    <>
      <div className="tattooS" onClick={handleTattooClick}>
        <div className="imgTattoo">
          <img className="imgT" src={props.img1} />
          {props.top ? <div className={`tTopC ${cor ? topBg[cor] || '' : ''}`}>T<br />O<br />P<br />{props.top}</div> : null}
        </div>
      </div>

      {/* Se showDetails for verdadeiro, exiba o componente TattooDetails */}
      {showDetails && <TattooDetails onClose={handleCloseDetails}
      stDescricao={props.StateDescricao} stTamanho={props.StateTamanho} stPreco={props.StatePreco}
      descricao={props.descricao} nome={props.nomeTattoo} preco={props.preco}
      tamanho={props.tamanho} img1={props.img1} img2={props.img2} img3={props.img3} img4={props.img4}
      cor={cor} estilo={estilo} numero={numero}
      />}
    </>
  )
}

export default TattooC;
