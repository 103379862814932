import "../login/SdiLP.css";
import React, { useRef } from 'react';
import ReactPixel from 'react-facebook-pixel';


function SdiLp (){

    const options = {
        autoConfig: true,  // Configuração automática do Pixel
        debug: false,      // Ativar modo de depuração (opcional)
      };

    ReactPixel.init('370076518847979', options);
    ReactPixel.pageView();

    const linkEspecifico = "https://pay.kiwify.com.br/3nCYZAR";

    // Função para abrir uma nova página ao clicar no botão
    const abrirNovaPagina = () => {
      window.open(linkEspecifico, '_blank'); // '_blank' indica que deve ser aberta em uma nova aba/janela
    };

     // Use useRef para criar a referência
  const targetElementRef1 = useRef(null);
  const targetElementRef2 = useRef(null);
  const targetElementRef3 = useRef(null);

  // Função para rolar até o elemento alvo
  const scrollToElement1 = () => {
    if (targetElementRef1.current) {
      // Utilize a referência diretamente para rolar
      targetElementRef1.current.scrollIntoView({
        behavior: 'smooth', // Adiciona um efeito de rolagem suave
      });
    }
  };

  const scrollToElement2 = () => {
    if (targetElementRef2.current) {
      // Utilize a referência diretamente para rolar
      targetElementRef2.current.scrollIntoView({
        behavior: 'smooth', // Adiciona um efeito de rolagem suave
      });
    }
  };

  const scrollToElement3 = () => {
    if (targetElementRef3.current) {
      // Utilize a referência diretamente para rolar
      targetElementRef3.current.scrollIntoView({
        behavior: 'smooth', // Adiciona um efeito de rolagem suave
      });
    }
  };



    

    return(
        <div style={{width:'100%'}}>
            <section id="d1d" className="dobra1">
                <img className="logoSDI" style={{height:"90px", marginBottom:'32px'}}
                src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Group%20557.webp?alt=media&token=e3da3f12-c427-430b-ae86-f4ef71daf58d"
                alt="SDI"/>

                <div className="dobra1--sessoes">
                    <div className="db1--sessao1">
                        <div className="sessao--titulo subtitulo4">22 Roteiros Estratégicos de Stories para Tatuadores</div>
                        <div className="sessao--texto" style={{fontWeight:'700', marginTop:"16px"}}>Com o passo-a-passo do que postar para:</div>
                        <div className="sessao--texto" style={{marginTop:"16px"}}>✅Despertar o <spam className="txDestaque2" style={{fontWeight:700}}>DESEJO INTENSO</spam> de ser tatuador por você;</div>
                        <div className="sessao--texto" style={{marginTop:"16px"}}>✅Aumentar o <spam className="txDestaque2" style={{fontWeight:700}}>ENGAJAMENTO</spam> do seu perfil;</div>
                        <div className="sessao--texto" style={{marginTop:"16px"}}>✅<spam className="txDestaque2" style={{fontWeight:700}}>LOTAR</spam> sua agenda rapidamente.</div>
                        <div className="sessao--botao" onClick={scrollToElement1}>QUERO MEU ACESSO SDI</div>
                    </div>

                    <div className="db1--sessao2">
                        <img className="mckSDI" style={{width:'500px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Group%20584a.webp?alt=media&token=877a142c-679b-45de-9b5b-3d205f52d69d"
                        alt="SDI"/>
                    </div>
                </div>
            </section>

            <section id="d1t" className="dobra1">
                <img className="logoSDI" style={{height:"90px", marginBottom:'32px'}}
                src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Group%20557.webp?alt=media&token=e3da3f12-c427-430b-ae86-f4ef71daf58d"
                alt="SDI"/>

                    <div className="db1--sessao2">
                        <img className="mckSDI" style={{width:'350px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Group%20584a.webp?alt=media&token=877a142c-679b-45de-9b5b-3d205f52d69d"
                        alt="SDI"/>
                    </div>

                <div className="dobra1--sessoes">
                    <div className="db1--sessao1" style={{ justifyContent:'center'}}>
                    <div className="sessao--titulo subtitulo4" style={{ textAlign:'center'}}>22 Roteiros Estratégicos de Stories para Tatuadores</div>
                        <div className="sessao--texto" style={{fontWeight:'700', marginTop:"16px", textAlign:'center', fontSize:'20px'}}>Com o passo-a-passo do que postar para:</div>
                        <div className="sessao--texto" style={{marginTop:"8px", textAlign:'center', fontSize:'20px'}}>✅Despertar o <spam className="txDestaque2" style={{fontWeight:700}}>DESEJO INTENSO</spam><br/> de ser tatuador por você;</div>
                        <div className="sessao--texto" style={{marginTop:"8px", textAlign:'center', fontSize:'20px'}}>✅Aumentar o <spam className="txDestaque2" style={{fontWeight:700}}>ENGAJAMENTO</spam> do seu perfil;</div>
                        <div className="sessao--texto" style={{marginTop:"8px", textAlign:'center', fontSize:'20px'}}>✅<spam className="txDestaque2" style={{fontWeight:700}}>LOTAR</spam> sua agenda rapidamente.</div>
                        <div className="sessao--botao" onClick={scrollToElement2}>QUERO MEU ACESSO SDI</div>
                    </div>

                    
                </div>
            </section>

            <section id="d1m" className="dobra1">
                <img className="logoSDI" style={{height:"48px", marginBottom:'16px'}}
                src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Group%20558.webp?alt=media&token=549de54e-78b8-49e9-94ce-3e8a3bf93893"
                alt="SDI"/>

                    <div >
                        <img className="mckSDI" style={{width:'300px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Group%20584a.webp?alt=media&token=877a142c-679b-45de-9b5b-3d205f52d69d"
                        alt="SDI"/>
                    </div>

                <div className="dobra1--sessoes" style={{flexDirection:'column'}}>
                <div className="sessao--titulo subtitulo4" style={{ textAlign:'center', fontSize:'36px'}}>22 Roteiros Estratégicos de Stories para Tatuadores</div>
                    <div  style={{display:'flex', flexDirection:'column' ,justifyContent:'center', alignContent:'center', marginLeft:'24px', marginRight:'24px'}}>
                       
                        <div className="sessao--texto" style={{fontWeight:'700', textAlign:'center', fontSize:'20px'}}>Com o passo-a-passo do que postar para:</div>
                        <div className="sessao--texto" style={{marginTop:"8px", textAlign:'center', fontSize:'20px'}}>✅Despertar o <spam className="txDestaque2" style={{fontWeight:700}}>DESEJO INTENSO</spam><br/> de ser tatuador por você;</div>
                        <div className="sessao--texto" style={{marginTop:"8px", textAlign:'center', fontSize:'20px'}}>✅Aumentar o <spam className="txDestaque2" style={{fontWeight:700}}>ENGAJAMENTO</spam> do seu perfil;</div>
                        <div className="sessao--texto" style={{marginTop:"8px", textAlign:'center', fontSize:'20px'}}>✅<spam className="txDestaque2" style={{fontWeight:700}}>LOTAR</spam> sua agenda rapidamente.</div>
                        <div className="sessao--botao"  onClick={scrollToElement3}>QUERO MEU ACESSO SDI</div>
                    </div>

                    
                </div>
            </section>

            <section id="d2d" className="dobra2">
                <div className="dobra1--sessoes">
                    <div className="db2--sessao1">
                        <img className="mckSDI" style={{width:'250px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__agenda%20aberta%20n%C3%A3o.webp?alt=media&token=ba9f9953-2032-4826-a9e3-8de11775ddde"
                        alt="SDI"/>
                    </div>

                    <div className="db2--sessao2">
                    <div className="chega">CHEGA DE POSTAR<br/> <spam className='agenda'>AGENDA ABERTA</spam></div>   
                    <p style={{fontWeight:700}}>Todo mundo já faz isso, e <spam style={{textDecoration:"underline"}}>você não é todo mundo!</spam></p>
                    <p>Para despertar o <strong style={{textTransform:"uppercase"}}>DESEJO INTENSO</strong> pelas suas tattoos em seus seguidores, você precisar falar do que eles realmente querem:</p>
                    <p style={{ color:'#FFD400', textTransform:'uppercase', fontWeight:700}}>o Benefício que sua habilidade de tatuar proporciona...</p>
                    <p>É por isso que os <strong>Roteiros do SDI</strong> foram feitos com base nos <strong style={{color:"#FFD400", textTransform:'uppercase'}}>11 principais benefícios</strong> que a tatuagem pode trazer, como: <strong>Homenagem, Autoestima e Confiança, Cobertura de Cicatrizes</strong> e mais 8 benefícios que estão dentro do SDI…</p>
                    </div>
                </div>
            </section>

            <section id="d2t" className="dobra2">
                <div className="dobra1--sessoes" style={{ justifyContent:'center'}}>
                    <div className="db2--sessao1">
                        <img className="mckSDI" style={{width:'233px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__agenda%20aberta%20n%C3%A3o.webp?alt=media&token=ba9f9953-2032-4826-a9e3-8de11775ddde"
                        alt="SDI"/>
                    </div>

                    <div className="db2--sessao2">
                    <div className="chega">CHEGA DE POSTAR<br/> <spam className='agenda'>AGENDA ABERTA</spam></div>   
                    <p style={{fontWeight:700}}>Todo mundo já faz isso, e <spam style={{textDecoration:"underline"}}>você não é todo mundo!</spam></p>
                    <p>Para despertar o <strong style={{textTransform:"uppercase"}}>DESEJO INTENSO</strong> pelas suas tattoos em seus seguidores, você precisar falar do que eles realmente querem:</p>
                    <p style={{ color:'#FFD400', textTransform:'uppercase', fontWeight:700}}>o Benefício que sua habilidade de tatuar proporciona...</p>
                    <p>É por isso que os <strong>Roteiros do SDI</strong> foram feitos com base nos <strong style={{color:"#FFD400", textTransform:'uppercase'}}>11 principais benefícios</strong> que a tatuagem pode trazer, como: <strong>Homenagem, Autoestima e Confiança, Cobertura de Cicatrizes</strong> e mais 8 benefícios que estão dentro do SDI…</p>
                    </div>
                </div>
            </section>

            <section id="d2m" className="dobra2">
                <div className="dobra1--sessoes" style={{ flexDirection:'column',justifyContent:'center'}}>
                    <div className="db2--sessao1">
                    <div className="chega" style={{fontSize:'43px', color:'#fff'}}>CHEGA DE<br/>POSTAR<br/> <spam className='agenda'style={{fontSize:'58px'}}>AGENDA<br/>ABERTA</spam></div>
                        <img className="mckSDI" style={{width:'146px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__agenda%20aberta%20n%C3%A3o.webp?alt=media&token=ba9f9953-2032-4826-a9e3-8de11775ddde"
                        alt="SDI"/>
                    </div>

                    <div className="db2--sessao2" style={{width:'310px'}} >
                       
                    <p style={{fontWeight:700}}>Todo mundo já faz isso, e <spam style={{textDecoration:"underline"}}>você não é todo mundo!</spam></p>
                    <p>Para despertar o <strong style={{textTransform:"uppercase"}}>DESEJO INTENSO</strong> pelas suas tattoos em seus seguidores, você precisar falar do que eles realmente querem:</p>
                    <p style={{ color:'#FFD400', textTransform:'uppercase', fontWeight:700}}>o Benefício que sua habilidade de tatuar proporciona...</p>
                    <p>É por isso que os <strong>Roteiros do SDI</strong> foram feitos com base nos <strong style={{color:"#FFD400", textTransform:'uppercase'}}>11 principais benefícios</strong> que a tatuagem pode trazer, como: <strong>Homenagem, Autoestima e Confiança, Cobertura de Cicatrizes</strong> e mais 8 benefícios que estão dentro do SDI…</p>
                    </div>
                </div>
            </section>

            <section id="d3d" className="dobra3">
                <p style={{fontWeight:700, fontSize:'32px'}}>Utilizando os Roteiros do SDI nos seus stories, você poderá:</p>
                <div className="blocoBN">
                    <div className="beneficio">
                        <img style={{width:'53px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%E2%AC%86%EF%B8%8F.svg?alt=media&token=7c110029-e33f-4fc6-b449-fe8596d7eb17"
                        alt=""/>
                        <p style={{ fontFamily:'Roboto Condensed' ,fontSize:'20px', padding:'0px 16px', textAlign:'center'}}>Aumentar o engajamento do seu perfil</p>
                    </div>

                    <div className="beneficio">
                        <img style={{width:'53px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%F0%9F%98%8D.svg?alt=media&token=226b5586-4cad-42d5-b558-30a124f1641b"
                        alt=""/>
                        <p style={{ fontFamily:'Roboto Condensed' ,fontSize:'20px', padding:'0px 16px', textAlign:'center'}}>Postar conteúdo que realmente se conecta com sua audiência;</p>
                    </div>

                    <div className="beneficio">
                        <img style={{width:'53px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%E2%9D%A4%EF%B8%8F%E2%80%8D%F0%9F%94%A5.svg?alt=media&token=b9eebc0c-1c55-4ef8-8e6c-7f9f04cc4bd0"
                        alt=""/>
                        <p style={{ fontFamily:'Roboto Condensed' ,fontSize:'20px', padding:'0px 16px', textAlign:'center'}}>Despertar o Desejo Intenso de ser tatuado por você</p>
                    </div>

                    <div className="beneficio">
                        <img style={{width:'53px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%F0%9F%97%93%EF%B8%8F.svg?alt=media&token=cd620563-3d7d-4770-acca-37d30bd9d6e9"
                        alt=""/>
                        <p style={{ fontFamily:'Roboto Condensed' ,fontSize:'20px', padding:'0px 16px', textAlign:'left'}}>Lotar sua agenda com mais facilidade</p>
                    </div>
                </div>
            </section>

            <section id="d3t" className="dobra3">
                <p style={{fontWeight:700, fontSize:'32px', textAlign:'left', marginTop:'-16px'}}>Utilizando os Roteiros do SDI nos<br/> seus stories, você poderá:</p>
                <div className="blocoBN">
                    <div className="beneficio">
                        <img style={{width:'53px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%E2%AC%86%EF%B8%8F.svg?alt=media&token=7c110029-e33f-4fc6-b449-fe8596d7eb17"
                        alt=""/>
                        <p style={{fontFamily:'Roboto Condensed' , fontSize:'20px', padding:'0px 16px', textAlign:'left'}}>Aumentar o engajamento do seu perfil</p>
                    </div>

                    <div className="beneficio">
                        <img style={{width:'53px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%F0%9F%98%8D.svg?alt=media&token=226b5586-4cad-42d5-b558-30a124f1641b"
                        alt=""/>
                        <p style={{ fontFamily:'Roboto Condensed' ,fontSize:'20px', padding:'0px 16px', textAlign:'left'}}>Postar conteúdo que realmente se conecta com sua audiência;</p>
                    </div>
                    </div>
                    <div className="blocoBN">
                    <div className="beneficio">
                        <img style={{width:'53px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%E2%9D%A4%EF%B8%8F%E2%80%8D%F0%9F%94%A5.svg?alt=media&token=b9eebc0c-1c55-4ef8-8e6c-7f9f04cc4bd0"
                        alt=""/>
                        <p style={{fontFamily:'Roboto Condensed' ,fontSize:'20px', padding:'0px 16px', textAlign:'left'}}>Despertar o Desejo Intenso de ser tatuado por você</p>
                    </div>

                    <div className="beneficio">
                        <img style={{width:'53px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%F0%9F%97%93%EF%B8%8F.svg?alt=media&token=cd620563-3d7d-4770-acca-37d30bd9d6e9"
                        alt=""/>
                        <p style={{ fontFamily:'Roboto Condensed' ,fontSize:'20px', padding:'0px 16px', textAlign:'left'}}>Lotar sua agenda com mais facilidade</p>
                    </div>
                </div>
            </section>

            <section id="d3m" className="dobra3" style={{justifyContent:'center'}}>
                <p style={{fontWeight:700, fontSize:'32px', textAlign:'center', marginTop:'-16px', width:'320px'}}>Utilizando os Roteiros do SDI nos seus stories, você poderá:</p>
                <div className="blocoBN" style={{flexDirection:'column'}}>
                    <div className="beneficio">
                        <img style={{width:'40px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%E2%AC%86%EF%B8%8F.svg?alt=media&token=7c110029-e33f-4fc6-b449-fe8596d7eb17"
                        alt=""/>
                        <p style={{ fontSize:'20px', padding:'0px 8px', textAlign:'left'}}>Aumentar o engajamento do seu perfil</p>
                    </div>

                    <div className="beneficio">
                        <img style={{width:'40px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%F0%9F%98%8D.svg?alt=media&token=226b5586-4cad-42d5-b558-30a124f1641b"
                        alt=""/>
                        <p style={{ fontSize:'20px', padding:'0px 16px', textAlign:'left'}}>Postar conteúdo que realmente se conecta com sua audiência;</p>
                    </div>
                    
                    <div className="beneficio">
                        <img style={{width:'40px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%E2%9D%A4%EF%B8%8F%E2%80%8D%F0%9F%94%A5.svg?alt=media&token=b9eebc0c-1c55-4ef8-8e6c-7f9f04cc4bd0"
                        alt=""/>
                        <p style={{ fontSize:'20px', padding:'0px 16px', textAlign:'left'}}>Despertar o Desejo Intenso de ser tatuado por você</p>
                    </div>

                    <div className="beneficio">
                        <img style={{width:'40px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2F%F0%9F%97%93%EF%B8%8F.svg?alt=media&token=cd620563-3d7d-4770-acca-37d30bd9d6e9"
                        alt=""/>
                        <p style={{ fontSize:'20px', padding:'0px 16px', textAlign:'left'}}>Lotar sua agenda com mais facilidade</p>
                    </div>
                </div>
            </section>

            <section id="d4d" className="dobra4">
            <div className="dobra1--sessoes">
                <div className="db4--sessao1">
                    <div className="subtitulo">PARA QUEM É?</div>
                </div>

                <div className="db4--sessao2" >
                    <p style={{ color:'#FFD400' ,fontSize:'20px'}}>Os <strong>Stories do Desejo Intenso</strong> é para o tatuador que:</p>
                    <p style={{fontSize:'20px'}}>👉 Posta “Agenda Aberta”, mas mas o fluxo de agendamentos continua baixo;</p>
                    <p style={{marginTop:'8px',fontSize:'20px'}}>👉 Mesmo tendo muita habilidade, está com dificuldades de atrair clientes;</p>
                    <p style={{marginTop:'8px',fontSize:'20px'}}>👉 Precisa de mais clientes o quanto antes para manter seu Négocio ativo;</p>
                    <p style={{marginTop:'8px',fontSize:'20px'}}>👉 Para quem não tem tempo a perder e precisa de uma solução urgente para gerar novos agendamentos;</p>
                    <p style={{ color:'#FFD400',fontSize:'20px'}}>Se você se identifica com um desses itens, <strong>então o SDI é para você!</strong></p>
                    <div className="sessao--botao" onClick={scrollToElement1}>QUERO MEU ACESSO SDI</div>
                </div>
            </div>

            </section>

            <section id="d4t" className="dobra4">
            <div className="dobra1--sessoes" style={{flexDirection:'column'}}>
                <div className="db4--sessao1" style={{justifyContent:'center'}}>
                    <div className="subtitulo" style={{fontSize:'86px'}}>PARA<br/> QUEM É?</div>
                </div>

                <div className="db4--sessao2" style={{textAlign:'center' ,width:'370px'}}>
                    <p style={{ color:'#FFD400',textAlign:'center'}}>Os <strong>Stories do Desejo Intenso</strong> é para o tatuador que:</p>
                    <p >👉 Posta “Agenda Aberta”, mas mas o fluxo de agendamentos continua baixo;</p>
                    <p style={{marginTop:'8px'}}>👉 Mesmo tendo muita habilidade, está com dificuldades de atrair clientes;</p>
                    <p style={{marginTop:'8px'}}>👉 Precisa de mais clientes o quanto antes para manter seu Négocio ativo;</p>
                    <p style={{marginTop:'8px'}}>👉 Para quem não tem tempo a perder e precisa de uma solução urgente para gerar novos agendamentos;</p>
                    <p style={{ color:'#FFD400',textAlign:'center'}}>Se você se identifica com um desses itens, <strong>então o SDI é para você!</strong></p>
                    <div className="sessao--botao" onClick={scrollToElement2}>QUERO MEU ACESSO SDI</div>
                </div>
            </div>

            

            </section>

            <section id="d4m" className="dobra4">
            <div className="dobra1--sessoes" style={{flexDirection:'column'}}>
                <div className="db4--sessao1" style={{justifyContent:'center'}}>
                    <div className="subtitulo" style={{fontSize:'86px'}}>PARA<br/> QUEM É?</div>
                </div>

                <div className="db4--sessao2" style={{display:'flex', flexDirection:'column',textAlign:'center' ,width:'310px', justifyContent:'center', alignContent:'center'}}>
                    <p style={{ color:'#FFD400',textAlign:'center'}}>Os <strong>Stories do Desejo Intenso</strong> é para o tatuador que:</p>
                    <p >👉 Posta “Agenda Aberta”, mas mas o fluxo de agendamentos continua baixo;</p>
                    <p style={{marginTop:'16px'}}>👉 Mesmo tendo muita habilidade, está com dificuldades de atrair clientes;</p>
                    <p style={{marginTop:'16px'}}>👉 Precisa de mais clientes o quanto antes para manter seu Négocio ativo;</p>
                    <p style={{marginTop:'16px'}}>👉 Para quem não tem tempo a perder e precisa de uma solução urgente para gerar novos agendamentos;</p>
                    <p style={{ color:'#FFD400',textAlign:'center'}}>Se você se identifica com um desses itens, <strong>então o SDI é para você!</strong></p>
                    <div className="sessao--botao" onClick={scrollToElement3}>QUERO MEU ACESSO SDI</div>
                </div>
            </div>

            

            </section>

            <section id="d03d" className="dobra3">
                <p style={{fontWeight:700, fontSize:'32px'}}>QUEM USOU, APROVOU!</p>
                <div className="blocoBN">
                <img style={{width:'250px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Frame%203.webp?alt=media&token=2d55cec9-da7c-4295-a94c-72d8c0863488"
                        alt=""/>

                <img style={{width:'250px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Frame%205.webp?alt=media&token=83d95616-8bbd-42d5-9829-11c86027c88d"
                        alt=""/>

                <img style={{width:'250px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Frame%204.webp?alt=media&token=9796d4e2-77cf-426b-90d8-1cb117679471"
                        alt=""/>
                    
                </div>
            </section>

            <section id="d03t" className="dobra3">
                <p style={{fontWeight:700, fontSize:'32px'}}>QUEM USOU, APROVOU!</p>
                <div className="blocoBN" style={{flexDirection:'column'}}>
                <img style={{width:'250px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Frame%203.webp?alt=media&token=2d55cec9-da7c-4295-a94c-72d8c0863488"
                        alt=""/>

                <img style={{width:'250px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Frame%205.webp?alt=media&token=83d95616-8bbd-42d5-9829-11c86027c88d"
                        alt=""/>

                <img style={{width:'250px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Frame%204.webp?alt=media&token=9796d4e2-77cf-426b-90d8-1cb117679471"
                        alt=""/>
                    
                </div>
            </section>

            <section id="d6d"  className="dobra5" ref={targetElementRef1}>
            <div className="subtitulo2" style={{fontSize:'96px'}}>O QUE VOCÊ VAI LEVAR...</div>
            <div className="dobra1--sessoes">
            <img style={{width:'430px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__v%20dt.webp?alt=media&token=7a15e6d9-e25f-449e-8236-1d3c98284ef5"
                        alt=""/>
          

            <img style={{width:'430px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__b%20dt.webp?alt=media&token=a91d5d95-c512-4d3d-8225-38728911160f"
                        alt=""/>
            </div>

            <div className="dobra1--sessoes" style={{gap:'94px', marginTop:'32px'}}>
            <img style={{width:'307px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__pre%C3%A7o%20(1).webp?alt=media&token=119ba669-fd9c-4db0-852d-a2045b634893"
                        alt=""/>

            <div className="sessao--botao" style={{width:'430px'}} onClick={abrirNovaPagina}>QUERO MEU ACESSO SDI</div>           
            </div>
            
            </section>

            <section id="d6t"  className="dobra5" ref={targetElementRef2}>
            <div className="subtitulo2" style={{fontSize:'64px'}}>O QUE VOCÊ VAI LEVAR...</div>
            <div className="dobra1--sessoes">
            <img style={{width:'310px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__v%20mb.webp?alt=media&token=a1280484-7b07-4879-8427-bf265fd0f7c1"
                        alt=""/>
          

            <img style={{width:'310px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__b%20mb.webp?alt=media&token=8623388f-8074-42e0-8b68-f86ee4c3a968"
                        alt=""/>
            </div>

            <div className="dobra1--sessoes" style={{gap:'48px', marginTop:'32px'}}>
            <img style={{width:'307px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__pre%C3%A7o%20(1).webp?alt=media&token=119ba669-fd9c-4db0-852d-a2045b634893"
                        alt=""/>

            <div className="sessao--botao" style={{width:'300px'}} onClick={abrirNovaPagina}>QUERO MEU ACESSO SDI</div>           
            </div>
            
            </section>

            <section id="d6m"  className="dobra5" ref={targetElementRef3}>
            <div className="subtitulo2" style={{fontSize:'64px', textAlign:'center', lineHeight:'1', marginBottom:'32px'}}>O QUE VOCÊ VAI LEVAR...</div>
            <div className="dobra1--sessoes" style={{flexDirection:'column'}}>
            <img style={{width:'310px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Group%20569.webp?alt=media&token=4462bb53-6b23-457e-9044-39deee12bf8d"
                        alt=""/>
          

            <img style={{width:'310px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Group%20568.webp?alt=media&token=4794a823-c24b-4fb6-a367-afb0ff30ebfa"
                        alt=""/>
            </div>

            <div className="dobra1--sessoes" style={{gap:'48px', marginTop:'32px', flexDirection:'column'}}>
            <img style={{width:'307px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__pre%C3%A7o%20(1).webp?alt=media&token=119ba669-fd9c-4db0-852d-a2045b634893"
                        alt=""/>

            <div className="sessao--botao" style={{width:'300px'}} onClick={abrirNovaPagina}>QUERO MEU ACESSO SDI</div>           
            </div>
            
            </section>

            <section id="d7d" className="dobra6">
            <img style={{width:'205px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__selo-garantia-7-dias-small.webp?alt=media&token=d7e0f666-be3c-45a9-9411-faf908f3e156"
                        alt=""/>

            <div style={{display:'flex', flexDirection:'column', width:'430px'}}>
            <div className="subtitulo2" style={{fontSize:'48px'}}>GARANTIA DE 7 DIAS</div>
            <p style={{marginTop:'0px'}}>Se bater aquela insegurança de "E se não funcionar?", fique tranquilo.<br/>
Você tem Garantia de 7 dias. Ou funciona, ou você pega seu dinheiro de volta.</p>
            </div>
          
            </section>

            <section id="d7t" className="dobra6">
            <img style={{width:'205px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__selo-garantia-7-dias-small.webp?alt=media&token=d7e0f666-be3c-45a9-9411-faf908f3e156"
                        alt=""/>

            <div style={{display:'flex', flexDirection:'column', width:'330px'}}>
            <div className="subtitulo2" style={{fontSize:'48px'}}>GARANTIA DE 7 DIAS</div>
            <p style={{marginTop:'0px'}}>Se bater aquela insegurança de "E se não funcionar?", fique tranquilo.<br/>
Você tem Garantia de 7 dias. Ou funciona, ou você pega seu dinheiro de volta.</p>
            </div>
          
            </section>

            <section id="d7m" className="dobra6" style={{flexDirection:'column'}}>
            <img style={{width:'205px'}}
                        src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__selo-garantia-7-dias-small.webp?alt=media&token=d7e0f666-be3c-45a9-9411-faf908f3e156"
                        alt=""/>

            <div style={{display:'flex', flexDirection:'column', width:'330px'}}>
            <div className="subtitulo2" style={{fontSize:'48px', textAlign:'center'}}>GARANTIA DE 7 DIAS</div>
            <p style={{marginTop:'0px', textAlign:'center'}}>Se bater aquela insegurança de "E se não funcionar?", fique tranquilo.<br/>
Você tem Garantia de 7 dias. Ou funciona, ou você pega seu dinheiro de volta.</p>
            </div>
          
            </section>

            <section  className="dobra7">
            <div className="subtitulo3" style={{fontSize:'48px'}}>PERGUNTAS FREQUENTES</div> 
            
            <div className="question">
            👉 Em quanto tempo vou receber meu acesso?
            <p style={{fontSize:'18px', fontWeight:'400', textAlign:'center'}}>Imediatamente após a aprovação do pagamento. Você irá receber no seu email todas as informações da sua compra e como acessar o roteiros do SDI.</p>
            </div>

            <div className="question">
            👉 O pagamento é único?
            <p style={{fontSize:'18px', fontWeight:'400', textAlign:'center'}}>Sim! Pagamento único e você acessa quando quiser.</p>
            </div>

            <div className="question">
            👉 Por quanto tempo terei acesso?
            <p style={{fontSize:'18px', fontWeight:'400', textAlign:'center'}}>Seu acesso será vitalício, ou seja, não tem validade.</p>
            </div>
            
            </section>

            <section  className="dobra8">
            <div style={{color:'rgba(255,255,255,0.3)', fontSize:'12px'}}>© 2023 Ktalus Ink - Todos os direitos reservados</div>
            </section>

        </div>
    )
}


export default SdiLp;