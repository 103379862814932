import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import ProgressoLista from "../../components/C_Body/Items/saveProgressLista";

function RegisterLista() {
    const { codigoParceiro } = useParams(); // Captura o código do parceiro da URL
    const [showProgress, setShowProgress] = useState(false);
    const [showEmailAlert, setShowEmailAlert] = useState(false);
    const [parceriaData, setParceriaData] = useState(null);

    // Função para buscar os dados da parceria no Firestore
    const fetchParceriaData = async (codigo) => {
        try {
            const parceriaRef = doc(db, "parceria", codigo); // Busca o documento com o ID igual ao código do parceiro
            const parceriaSnap = await getDoc(parceriaRef);
            
            if (parceriaSnap.exists()) {
                setParceriaData(parceriaSnap.data());
            } else {
                console.error("Documento de parceria não encontrado");
                setParceriaData({}); // Definir como objeto vazio se o documento não for encontrado
            }
        } catch (error) {
            console.error("Erro ao buscar dados da parceria:", error);
            setParceriaData({}); // Definir como objeto vazio em caso de erro
        }
    };

    useEffect(() => {
        if (codigoParceiro) {
            fetchParceriaData(codigoParceiro);
        }
    }, [codigoParceiro]);

    // Estado para gerenciar os valores dos campos do formulário
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        whats: '',
        insta: ''
    });

    const EnviarMensagemAPI = (nome, whats, insta) => {
        const enviarMensagem = async () => {
            const apiUrl = 'https://teste.uzapi.com.br:3333/sendText';
            const sessionKey = '123456';
            const requestBody = {
                session: 'Ktalus',
                number: '+5561986395151',
                text: `Nome: ${nome}\nWhatsapp: ${whats}\nInsta: ${insta}`,
            };
        
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'SessionKey': sessionKey,
                    },
                    body: JSON.stringify(requestBody),
                });
        
                if (!response.ok) {
                    throw new Error(`Erro na chamada API: ${response.statusText}`);
                }
        
                console.log('Mensagem enviada com sucesso!');
            } catch (error) {
                console.error('Erro ao enviar mensagem:', error);
            }
        };
        
        // Chama a função para enviar a mensagem
        enviarMensagem();
    };

    // Função para atualizar os valores do formulário
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleEnviarClick = async () => {
        const { nome, whats, insta } = formData;

        // Verificar se todos os campos estão preenchidos
        if (!nome || !whats || !insta) {
            setShowEmailAlert(true); // Se algum campo estiver vazio, mostrar alerta
            return;
        }

        try {
            const listaEsperaRef = doc(db, "listaEspera", nome);
            await setDoc(listaEsperaRef, { 
                nome, 
                whats, 
                insta, 
                convidado: false,
                codigoParceiro // Salvar o código do parceiro junto com os dados
            });

            setShowProgress(true);
            EnviarMensagemAPI(nome, whats, insta); // Passa os dados para enviar a mensagem
        } catch (error) {
            console.error("Erro ao enviar dados:", error);
        }
    };

    return (
        <>
        <div className="registerP">
            <div style={{position:'absolute', bottom:'8px', color:'rgba(255,255,255,0.3)', fontSize:'12px'}}>
                © 2024 Ktalus - Todos os direitos reservados
            </div>
            {showProgress && <ProgressoLista />}
            <div className='r--header' style={{marginTop:'40px'}}>
                <img style={{ width: "186px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2"
                    alt="Ktalus"/>
                {parceriaData && parceriaData.nomeParceiro && (
                    <span className="h1sub" style={{fontWeight:'700'}}>
                        Você recebeu um convite de <span style={{color:'#dffc01'}}>{parceriaData.nomeParceiro}</span> para solicitar o teste da Plataforma KTALUS.
                    </span>
                )}
            </div>

            {parceriaData && parceriaData.nomeParceiro && (
                <div className="r--card" style={{paddingBottom:'8px', paddingTop:'8px'}}>
                    <div className="r--form">
                        {parceriaData?.nomeParceiro && (
                            <div className="alert">Preencha os campos abaixo<br /> e entraremos em contato</div>
                        )}

                        <div className='form--input'>
                            <div className='label'>Nome do estúdio ou profissional</div>
                            <input 
                                id="nome" 
                                type="text" 
                                name="nome" 
                                value={formData.nome}
                                onChange={handleInputChange}
                                placeholder="Digite o nome aqui"  
                            />
                        </div>

                        <div className='form--input'>
                            <div className='label'>WhatsApp (DDD + Celular)</div>
                            <input 
                                id="whats" 
                                type="tel" 
                                pattern="[0-9]{2} [0-9]{5}-[0-9]{4}" 
                                name="whats" 
                                value={formData.whats}
                                onChange={handleInputChange}
                                placeholder="XX 9XXXX-XXXX"
                            />
                        </div>

                        <div className='form--input'>
                            <div className='label'>Instagram</div>
                            <input 
                                id="insta" 
                                type="text" 
                                name="insta" 
                                value={formData.insta}
                                onChange={handleInputChange}
                                placeholder="Digite seu @ do Instagram"  
                            />
                        </div>

                        {showEmailAlert && (
                            <div className="alert">⚠️ Todos os campos devem ser preenchidos</div>
                        )}

                        <div className="TUPP">
                            Ao clicar em 'Enviar', você aceita receber mensagens da Ktalus via WhatsApp ou Instagram.
                        </div>

                        <button type="button" className="login" onClick={handleEnviarClick}>
                            Enviar
                        </button>
                    </div>
                </div>
            )}
        </div>
        </>
    );
}

export default RegisterLista;
