import React, { useEffect, useState } from 'react';
import HeaderPage from "../../components/C_Body/HeaderPage";
import { getFirestore, collection, query, where, getDocs, onSnapshot, doc, updateDoc, orderBy } from 'firebase/firestore';
import { HiOutlineFolderAdd } from "react-icons/hi";
import Secao from "../../components/C_Body/Secao";
import AddSecao from "../../components/C_Body/Items/AddSecao";
import Top from "../../components/C_Body/Top";

function P02Catalogo ({user, estudio, setComponenteAtivo, closeDeleteTattoo, openDeleteTattoo, onTattooSelect, openEditTattoo, closeEditTattoo, onSecaoSelect, openEditSecao, closeEditSecao, closeDeleteSecao, openDeleteSecao   }){
    console.log('ES :', estudio)
    const [secoes, setSecoes] = useState([]);
    const [userOnline, setUserOnline] = useState(false);
    const [mostrarAddSecao, setMostrarAddSecao] = useState(false);

    useEffect(() => {
        let unsubscribe = () => {};
    
        const buscarSecoes = async () => {
            if (!estudio) { // Certifique-se de que o 'estudio' não é undefined
                return;
            }
    
            const db = getFirestore();
            
            const secoesQuery = query(
                collection(db, 'sessoes'),
                where('EstudioID', '==', estudio),
                orderBy('ordem', 'asc')
            );
    
            unsubscribe = onSnapshot(secoesQuery, (snapshot) => {
                const novasSecoes = [];
                let novoStatusOnline = false;
                snapshot.forEach((doc) => {
                    const secaoData = { id: doc.id, ...doc.data() };
                    novasSecoes.push(secaoData);
                    if (secaoData.online) {
                        novoStatusOnline = true;
                    }
                });
                setSecoes(novasSecoes);
                setUserOnline(novoStatusOnline);
            });
        };
    
        buscarSecoes();
    
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [estudio]);

    const handleToggleOnline = () => {
        setUserOnline(!userOnline);
        const db = getFirestore();
        secoes.forEach((secao) => {
            const secaoRef = doc(db, 'sessoes', secao.id);
            updateDoc(secaoRef, { online: !userOnline });
        });
    };

    const mostrarComponenteAddSecao = () => {
        setMostrarAddSecao(true);
    };

    const ocultarComponenteAddSecao = () => {
        setMostrarAddSecao(false);
    };



    return(
        <div className=' ' style={{ fontFamily:'montserrat',boxSizing:'border-box', }}>
            <HeaderPage pg={'Catálogo'} setComponenteAtivo={setComponenteAtivo} />
        
            <div className="bgly3" style={{ margin:'0px', padding:'24px', borderRadius:'16px', width:'-webkit-fill-available', }}>
                <div style={{ color:'#dffc01', display:'flex', flexDirection:'row', fontSize:'28px', fontWeight:'700', justifyContent:'space-between', alignItems:'center'}}>
                Seções
                
                <div className='addSecao'  onClick={mostrarComponenteAddSecao}>
                    <HiOutlineFolderAdd className="iconCat" />
                    <div  >Add Seção</div>
                </div>
                </div>
                <hr style={{ marginBottom:'16px', marginTop:'8px' }} ></hr>
                

            <Top eID={estudio} />

            {mostrarAddSecao && <AddSecao onClose={ocultarComponenteAddSecao} estudioID={estudio} />}

            {secoes.map((secao) => (
                <Secao
                    key={secao.id}
                    EstudioID={secao.EstudioID}
                    ID_Sessao={secao.id}
                    nomeSessao={secao.nomeSessao}
                    online={secao.online}
                    ordem={secao.ordem}
                    openDeleteTattoo={openDeleteTattoo} closeDeleteTattoo={closeDeleteTattoo} onTattooSelect={onTattooSelect} openEditTattoo={openEditTattoo} closeEditTattoo={closeEditTattoo}
                    openDeleteSecao={openDeleteSecao} closeDeleteSecao={closeDeleteSecao} openEditSecao={openEditSecao} closeEditSecao={closeEditSecao}
                    onSecaoSelect={onSecaoSelect} 
                />
            ))}
        </div>
        </div>
    )
}


export default P02Catalogo;



