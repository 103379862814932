import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importando o hook useNavigate
import { FaCheckCircle } from "react-icons/fa";
function Confirm() {
  const navigate = useNavigate(); // Inicializando o hook useNavigate

  // Função para redirecionar ao clicar no botão
  const handleClick = () => {
    navigate('/home'); // Redireciona para "/home"
  };

  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', flexDirection:'column'}}>
      <img style={{ height:'64px', marginBottom:'40px'}} 
        src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2'
        alt="Logo Ktalus"
      />
      <FaCheckCircle style={{color:'#dffc01', fontSize:'32px'}}/>
      <h1 style={{color:'#fff', gap:'16px'}}>Assinatura confirmada<br/>com sucesso!</h1>
      <div 
        className='ass--botaoCTAM' 
        
        onClick={handleClick} // Chama a função ao clicar
      >
        Acessar Plataforma
      </div>
    </div>
  );
}

export default Confirm;
