import React, { useState, useEffect, useRef } from "react";
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import { getFirestore, getDocs, query, where, collection } from "firebase/firestore";
import firebase from '../../services/firebaseConfig';

function PosP1({ onNext, formData, setFormData }) {
  // O estado para os valores dos campos
  const [nome, setNome] = useState(formData.nome);
  const [ddd, setDdd] = useState(formData.ddd);
  const [celular, setCelular] = useState(formData.celular);
  const [link, setLink] = useState(formData.link);
  const [disponibilidade, setDisponibilidade] = useState(formData.disponibilidade);
  const nomeRef = useRef(null);
const dddRef = useRef(null);
const celularRef = useRef(null);
const linkRef = useRef(null);

const handleKeyDown = (e, nextRef) => {
    if (e.key === 'Enter') {
      nextRef.current.focus();
      window.scrollTo(0, nextRef.current.offsetTop - 100);
    }
  };

  // Inicializa o Firestore
  const db = getFirestore(firebase);

  // Atualiza o nome no estado quando o input muda
  const handleNomeChange = (e) => {
    let valor = e.target.value;
    if (valor.length <= 25) {
        valor = valor.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      setNome(valor);
    }
  };

  // Atualiza o DDD no estado quando o input muda
  const handleDddChange = (e) => {
    let valor = e.target.value;
    if (/^\d*$/.test(valor) && valor.length <= 2) {
      setDdd(valor);
    }
  };

  // Atualiza o celular no estado quando o input muda
  const handleCelularChange = (e) => {
    let valor = e.target.value.replace(/\D/g, '');
    if (valor.length <= 9) {
      valor = valor.replace(/^(\d{1})(\d{0,4})(\d{0,4})/, '$1 $2 $3').trim();
      setCelular(valor);
    }
  };

  

  // Verifica a disponibilidade do link quando o input muda
  useEffect(() => {
    if (link.length >= 6) {
      const timer = setTimeout(async () => {
        setDisponibilidade("verificando");
        const q = query(collection(db, "estudio"), where("nickname", "==", link));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setDisponibilidade("disponivel");
        } else {
          setDisponibilidade("indisponivel");
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [link]);

 // Atualiza o link no estado quando o input muda
const handleLinkChange = (e) => {
    let valor = e.target.value.replace(/[^a-zA-Z0-9_]/g, '');
    valor = valor.toLowerCase();  // Converte a string para minúsculas
    setLink(valor);
  };

  const handleNextClick = () => {
    let valid = true; // Variável para verificar se todos os campos estão preenchidos corretamente

    // Função para esconder alertas após 5 segundos
    const hideAlert = (elementId) => {
        setTimeout(() => {
            document.getElementById(elementId).style.display = "none";
        }, 5000);
    }

    // Verifica se o campo nome está vazio
    if (nome === "") {
        document.getElementById("aNome").style.display = "block";
        hideAlert("aNome");
        valid = false;
    } else {
        document.getElementById("aNome").style.display = "none";
    }

    // Verifica se os campos ddd ou celular estão vazios
    if (ddd === "" || celular === "") {
        document.getElementById("aWhats").style.display = "block";
        hideAlert("aWhats");
        valid = false;
    } else {
        document.getElementById("aWhats").style.display = "none";
    }

     // Verifica se o campo link está vazio ou indisponível
     if (link.length < 6 || disponibilidade === "indisponivel") {
      document.getElementById("aLink").style.display = "block";
      hideAlert("aLink");
      valid = false;
    } else {
      document.getElementById("aLink").style.display = "none";
    }

    // Se todos os campos estão preenchidos corretamente, execute a função onNext
    if (valid) {
        setFormData({ nome, ddd, celular, link, disponibilidade });
        onNext();
      }
};


    return(
        <div className='pr'>
            <img style={{ width: "150px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2"
                    alt="Ktalus"/>

<div className='pr--labels'>

  <span style={{marginTop:'24px' ,fontSize:'24px', fontWeight:'700'}}>Passo 1 <span style={{ fontWeight:'100', color: '#dffc01'}}>de 5</span></span>
  <span style={{ color:'#dffc01',marginBottom:'8px', fontSize:'14px'}}>Definir os principais dados do seu catálogo</span>
  <hr style={{ background:'#dffc01',marginBottom:'24px',}}/>


  <div className="bgly" style={{ borderRadius:'16px', padding:'8px 8px 0px 8px', marginBottom:'0', display:'flex',flexDirection:'column', justifyContent:'left', width:'-webkit-fill-available'}}>
                <label02>Nome do estúdio ou profissional</label02>
                <input
                    type='text'
                    name='nome'
                    placeholder='Digite aqui o nome'
                    value={nome}
                    
                    ref={nomeRef}
                    onKeyDown={(e) => handleKeyDown(e, dddRef)}
                    onChange={handleNomeChange} autocomplete="off" />
                {/* Outros elementos aqui */}

                <div id="aNome" className='alertP'>Digite o nome</div></div>

                <div className="bgly" style={{ borderRadius:'16px', padding:'8px 8px 0px 8px', marginBottom:'0',display:'flex',flexDirection:'column', justifyContent:'left', boxSizing:'border-box', }}>
                <label02>WhatsApp</label02>
                <div className='pr--whats'>
                    <input
                        type="tel"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name='ddd'
                        
                        placeholder='DDD'
                        style={{ width: "25%", textAlign: "center" }}
                        value={ddd}
                        ref={dddRef}
                        onKeyDown={(e) => handleKeyDown(e, celularRef)}
                        onChange={handleDddChange} autocomplete="off" />
                    <input
                        type="tel"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name='celular'
                        
                        placeholder='9 XXXX XXXX'
                        style={{ width: "70%", textAlign: "center" }}
                        value={celular}
                        ref={celularRef}
                        onKeyDown={(e) => handleKeyDown(e, linkRef)}
                        onChange={handleCelularChange} autocomplete="off" />
                </div>
                <div id="aWhats" className='alertP'>Digite seu WhatsApp</div>
                </div>

                <div className="bgly" style={{ borderRadius:'16px', padding:'8px 8px 0px 8px', marginBottom:'0', display:'flex',flexDirection:'column', justifyContent:'left', width:'-webkit-fill-available'}}>
                    <label02>Endereço de link</label02>
                    
                    
                    <div className='pr--verif'>
        <input className='pr--verifLink'  type="text" name='link' ref={linkRef} value={link} onChange={handleLinkChange} autocomplete="off"  />
        { link.length >= 4 ? (
  <>
   
    { disponibilidade === "verificando" && <AiOutlineLoading3Quarters className="verificando"/> }
    {disponibilidade === "disponivel" && link.length >= 6 && <AiFillCheckCircle className="disponivel" />}
    {(disponibilidade === "indisponivel" || link.length < 6) && <AiFillCloseCircle className="indisponivel" />}
  </>
) : null }
      </div>
      <div style={{ fontSize:'10px', marginTop:'-16px', textAlign:'right'}}>mín. 6 caracteres</div>
      <p className='pr--link'>ktalus.pro/<span className='pr--linkNome'>{link}</span></p>
     
                    <div id="aLink" className='alertP'>Escolha um endereço válido</div>
                    </div>


                    

                    

                
            </div>

            <div className='pr--buttons'>
                <button className='pr--back' style={{visibility:"hidden"}} >Voltar</button>
                <button className='pr--next' onClick={handleNextClick}>Avançar</button>

            </div>
            

        </div>
    )
}


export default PosP1;