import React, { useState, useEffect } from "react";
import { MdDeleteForever } from "react-icons/md";
import CropEasy from "../../../pages/membros/crop/CropEasy";
import "../Items/items.css";
import Photos from "./Photos";
import Descricao from "./Descricao";
import Tamanho from "./Tamanho";
import Preco from "./Preco";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../../services/firebaseConfig";

function AddTattooTest({ onClose, eID, sID }) {
  const [imageData, setImageData] = useState({});
  const [nomeTattoo, setNomeTattoo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [tamanho, setTamanho] = useState(0);
  const [preco, setPreco] = useState(0);
  const [stateTamanho, setStateTamanho] = useState(false);
  const [stateDescricao, setStateDescricao] = useState(false);
  const [statePreco, setStatePreco] = useState(false);

  const handleCroppedImage = (inputId, croppedImage) => {
    setImageData({ ...imageData, [inputId]: croppedImage });
  };

  const uploadImageAndGetURL = async (imageDataKey) => {
    if (!imageData[imageDataKey]) return "";

    const storage = getStorage();
    const storageRef = ref(storage, `photos/${Date.now()}.jpeg`);

    // Convertendo a imagem em Blob
    const response = await fetch(imageData[imageDataKey]);
    const blob = await response.blob();

    await uploadBytes(storageRef, blob);
    return await getDownloadURL(storageRef);
  };

  const handleSave = async () => {
    try {
      const img1URL = await uploadImageAndGetURL("ph1");
      const img2URL = await uploadImageAndGetURL("ph2");
      const img3URL = await uploadImageAndGetURL("ph3");
      const img4URL = await uploadImageAndGetURL("ph4");

      const q = query(collection(db, "photos"), where("SecaoID", "==", sID));
      const querySnapshot = await getDocs(q);
      const ordem = querySnapshot.size;

      await addDoc(collection(db, "photos"), {
        img1: img1URL,
        img2: img2URL,
        img3: img3URL,
        img4: img4URL,
        nomeTattoo: nomeTattoo,
        StateDescricao: document.querySelector(".toggleOND").style.display === "flex",
        StateTamanho: document.querySelector(".toggleONT").style.display === "flex",
        StatePreco: document.querySelector(".toggleONP").style.display === "flex",
        descricao: descricao,
        preco: preco,
        tamanho: tamanho,
        top: "",
        EstudioID: eID,
        SecaoID: sID,
        ordem: ordem
      });

      alert("Documento adicionado com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar o documento: ", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest(".cardAddSecao")) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
        // Verifica se o clique foi dentro do CropEasy
        if (e.target.closest('.comp')) {
            return;
        }

        if (!e.target.closest(".cardAddSecao")) {
            onClose();
        }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
    };
}, [onClose]);


  return (
    <div className="bgAcao">
      <div className="cardAddSecao">
        <div className="nameAction">
          <i className="fa-regular fa-folder iconAction"></i>
          Adicionar tattoo test
        </div>

        <div className="contCard">
          <Photos handleCroppedImage={handleCroppedImage} />

          <div style={{ display: "block", textAlign: "left" }}>
            Nome da Tattoo:*
            <input
              id="nomeTattoo"
              className="input"
              type="text"
              name="nomeTatto"
              placeholder="Digite aqui o nome da tattoo"
              onChange={(e) => setNomeTattoo(e.target.value)}
            />
          </div>

          <Descricao setDescricao={setDescricao} setStateDescricao={setStateDescricao} />

          <div className="TamPreco">
            <Tamanho setTamanho={setTamanho} setStateTamanho={setStateTamanho} />
            <Preco setPreco={setPreco} setStatePreco={setStatePreco} />
          </div>

          <div className="botoesCard">
            <button id="cancel" className="brCardCancel">
              Cancelar
            </button>
            <button id="saveT" className="btCardConfirm" onClick={handleSave}>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTattooTest;
