import { LuFilePlus } from "react-icons/lu";
import Tattoo from "./Tattoo";
import { useEffect, useState } from "react";
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import AddTattoo from "./Items/AddTattoo";

function ConteudoSecao({ secaoID, estudioID, openDeleteTattoo, closeDeleteTattoo, handleCancelar, onTattooSelect, openEditTattoo, closeEditTattoo }) {
    const [showAddTattoo, setShowAddTattoo] = useState(false);

    const handleShowAddTattoo = () => {
        setShowAddTattoo(true);
    };

    

    const handleCloseAddTattoo = () => {
        setShowAddTattoo(false);
    };

    const [tattoos, setTattoos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const db = getFirestore();
        const tattoosQuery = query(
            collection(db, 'tattoos'),
            where('SecaoID', '==', secaoID)
        );

        const unsubscribe = onSnapshot(tattoosQuery, (snapshot) => {
            const tattooData = [];
            snapshot.forEach((doc) => {
                tattooData.push({ id: doc.id, ...doc.data() });
            });

            // Ordenar os dados em ordem crescente com base no campo 'ordem'
            tattooData.sort((a, b) => a.ordem - b.ordem);

            setTattoos(tattooData);
            setIsLoading(false);
        });

        return () => {
            unsubscribe();
        }
    }, [secaoID]);

    return (
        <div className="sConteudo">
           {!showAddTattoo && ( // Exibe o botão apenas se o modal não estiver aberto
                <div className='addTattoo' onClick={handleShowAddTattoo}>
                    <LuFilePlus className="icon"/>
                    <p className='txBt'>Adicionar Tattoo</p>
                </div>
            )}

            {showAddTattoo && (
                <AddTattoo sID={secaoID} eID={estudioID} onClose={handleCloseAddTattoo} />
            )}

            {isLoading ? (
                <p className="tattooZero">Carregando...</p>
            ) : tattoos.length === 0 ? (
                <p className="tattooZero">Ainda não há tattoos nesta seção</p>
            ) : (
                tattoos.map((tattoo) => (
                    <Tattoo
                    key={tattoo.id} 
                        id={tattoo.id} scID={tattoo.SecaoID} eID={tattoo.EstudioID}
                        stDesc={tattoo.StateDescricao} stPreco={tattoo.StatePreco} stTamanho={tattoo.StateTamanho}
                        nomeT={tattoo.nomeTattoo} descr={tattoo.descricao}
                        capa={tattoo.img1} img1={tattoo.img1} img2={tattoo.img2} img3={tattoo.img3} img4={tattoo.img4} openDeleteTattoo={openDeleteTattoo} closeDeleteTattoo={closeDeleteTattoo}
                        
                        tam={tattoo.tamanho} preco={tattoo.preco} index={tattoo.ordem} handleCancelar={handleCancelar} onTattooSelect={onTattooSelect} openEditTattoo={openEditTattoo} closeEditTattoo={closeEditTattoo} 
                    />
                ))
            )}
        </div>    
    )
}

export default ConteudoSecao;
