import  React, {  useEffect } from 'react';
import './details.css'
import './bts.css'
import { MdClose } from "react-icons/md";
import Slide from './slide';
import { getImageURL } from '../register/GlifosImg'; 
import { BsWhatsapp } from "react-icons/bs";
import Carousel from './CarrosselFramer';




function TattooDetails ({ onClose, stDescricao, stTamanho, stPreco, descricao, nome, preco, tamanho, img1, img2, img3, img4, cor, estilo, numero }){
  



    const card = {
        vermelho:'cardBranco bordCVermelho', laranja:'cardBranco bordCLaranja', amarelo:'cardBranco bordCAmarelo', verde:'cardBranco bordCVerde', ciano:'cardBranco bordCCiano',
        azul:'cardBranco bordCAzul', roxo:'cardBranco bordCRoxo', rosa:'cardBranco bordCRosa', magenta:'cardBranco bordCMagenta', branco:'cardBranco bordCBranco',
    
        goldVermelho: 'cardVermelho bordCAmarelo', goldVerde:'cardVerde bordCAmarelo', goldAzul:'cardAzul bordCAmarelo', goldRoxo:'cardRoxo bordCAmarelo', goldAmarelo:'cardAmarelo bordCAmarelo',
        silverVermelho: 'cardVermelho bordCBranco', silverVerde:'cardVerde bordCBranco', silverAzul:'cardAzul bordCBranco', silverRoxo:'cardRoxo bordCBranco', silverAmarelo:'cardAmarelo bordCBranco',
        vermelhoVerde:'cardVerde bordCVermelho', laranjaCiano:'cardCiano bordCLaranja', verdeVermelho:'cardVermelho bordCVerde', azulAmarelo:'cardAmarelo bordCAzul',
    
      };

      const botao = {
        vermelho: 'btVermelho', laranja: 'btLaranja', amarelo: 'btAmarelo', verde: 'btVerde', ciano: 'btCiano',
        azul:'btAzul', roxo:'btRoxo', rosa:'btRosa', magenta:'btMagenta', branco: 'btBranco',
    
        goldVermelho: 'btAmarelo', goldVerde:'btAmarelo', goldAzul:'btAmarelo', goldRoxo:'btAmarelo', goldAmarelo:'btAmarelo',
    
        silverVermelho: 'btBranco', silverVerde:'btBranco', silverAzul:'btBranco', silverRoxo:'btBranco', silverAmarelo:'btBranco',
    
        vermelhoVerde:'btVermelho', laranjaCiano:'btLaranja', verdeVermelho:'btVerde', azulAmarelo:'btAzul',
    }

    const title = {
        vermelho: 'tttVermelho', laranja: 'tttLaranja', amarelo: 'tttAmarelo', verde: 'tttVerde', ciano: 'tttCiano',
        azul:'tttAzul', roxo:'tttRoxo', rosa:'tttRosa', magenta:'tttMagenta', branco: 'tttBranco',
    
        goldVermelho: 'tttAmarelo', goldVerde:'tttAmarelo', goldAzul:'tttAmarelo', goldRoxo:'tttAmarelo', goldAmarelo:'tttAmarelo',
    
        silverVermelho: 'tttBranco', silverVerde:'tttBranco', silverAzul:'tttBranco', silverRoxo:'tttBranco', silverAmarelo:'tttBranco',
    
        vermelhoVerde:'tttVermelho', laranjaCiano:'tttLaranja', verdeVermelho:'tttVerde', azulAmarelo:'tttAzul',
    }

    const abrirWhatsApp = (numero) => {
        window.open(`https://wa.me/${numero}?text=Oi!+Gostei+da+tattoo+${nome}+e+gostaria+de+fazer+um+orçamento`, '_blank');
      };

      const textoFormatado = descricao ? descricao.split('\n').map((str, index, array) => 
      <React.Fragment key={index}>
          {str}
          {index !== array.length - 1 && <br />}
      </React.Fragment>
  ) : '';
  
    
      useEffect(() => {
        // Adiciona um novo estado no histórico do navegador
        // Isso faz com que o botão voltar possa ser ativado
        window.history.pushState(null, null, window.location.pathname);
    
        const handlePopState = (event) => {
          event.preventDefault(); // previne o comportamento padrão de voltar
          onClose(); // chama a função handleClick
          window.history.pushState(null, null, window.location.pathname); // reinsere o estado
        };
    
        // Adiciona o evento popstate ao window
        window.addEventListener('popstate', handlePopState);
    
        // Remove o evento ao desmontar o componente
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
    
      }, []);

    return(
        <div className='tDet--bg'>
            <div className={`tDet--card ${cor ? card[cor] || '' : ''}`}>
                <img className='tDet--cantoA' 
                src={getImageURL(estilo, cor)} style={{ display: estilo === 'estiloN' ? 'none' : 'flex' }}
            />

            <img className='tDet--cantoD' 
                src={getImageURL(estilo, cor)} style={{ display: estilo === 'estiloN' ? 'none' : 'flex' }}
            />

            <div className='tDet--close' onClick={onClose}><MdClose onClick={onClose}/></div>

            
            <Carousel img1={img1} img2={img2} img3={img3} img4={img4} stT={stTamanho} stP={stPreco} tam={tamanho} preco={preco}/>
                    {/*<Slide img1={img1} img2={img2} img3={img3} img4={img4} stT={stTamanho} stP={stPreco} tam={tamanho} preco={preco}/>*/}
                    <div className='tDet--title'>{nome}</div>
                    <div className='tDet--Desc' style={{ display: stDescricao ? 'block' : 'none' }}>{textoFormatado}</div>
                
                
                {numero !== '55' && (<div id='ctaButton' className={`tDet--button ${cor ? botao[cor] || '' : ''}`} onClick={() => abrirWhatsApp(numero)}><BsWhatsapp style={{marginRight:'8px'}}/> Fazer Orçamento</div>)}
            </div>
        </div>
    )
}


export default TattooDetails;