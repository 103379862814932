import "../login/SdiLP.css";
import React, { useRef } from 'react';
import ReactPixel from 'react-facebook-pixel';


function SdiLpO (){

    const options = {
        autoConfig: true,  // Configuração automática do Pixel
        debug: false,      // Ativar modo de depuração (opcional)
      };

    ReactPixel.init('370076518847979', options);
    ReactPixel.pageView();
    ReactPixel.track('PageView', {
        content_name: 'PgObrigado', // Nome da segunda página
      });

    


    

    return(
        <div style={{width:'100%', height:'100%'}}>
            <section className="dobra1" style={{height:'100vh'}}>
                <img className="logoSDI" style={{height:"90px", marginBottom:'32px'}}
                src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/sdi%2FAnyConv.com__Group%20557.webp?alt=media&token=e3da3f12-c427-430b-ae86-f4ef71daf58d"
                alt="SDI"/>

                <div className="dobra1--sessoes">
                    <div className="db1--sessao1" style={{width:'300px'}}>
                        <div className="sessao--titulo subtitulo4" style={{textAlign:'center'}}>Parabéns pela ótima decisão!</div>
                        <div className="sessao--texto" style={{textAlign:'center', marginTop:"16px"}}>Agora é só conferir seu email, pois em instantes você receberá por lá o seu acesso ao SDI</div>
                        
                    </div>

                   
                </div>
            </section>

           

            <section  className="dobra8">
            <div style={{color:'rgba(255,255,255,0.3)', fontSize:'12px'}}>© 2023 Ktalus Ink - Todos os direitos reservados</div>
            </section>

        </div>
    )
}


export default SdiLpO;