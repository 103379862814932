



function TattooTop(){


    return(
        <div className="TattoComPosicao">
            <div className="tattooTop">12</div>
            <img className="posicao"
            src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/top7%2F7a%20(1).png?alt=media&token=39e337cb-303b-4a2f-8f05-23825a5b03eb"
            />
        </div>
    )
}

export default TattooTop;