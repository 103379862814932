import React, { useRef, useEffect, useState } from "react";
import TattooC from "./TattooC";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { onSnapshot, query, where, collection, orderBy } from "firebase/firestore";
import { db } from '../../services/firebaseConfig';
import { motion } from "framer-motion";

function SecaoC(props) {
  const { cor }= props;
  const { estilo }= props;
  const { numero }= props;

  const secaoTRef = useRef(null);
  const [tattoosData, setTattoosData] = useState([]); 
  
  const easeInOutCubic = (t) => {
    return t < 0.5
      ? 4 * t * t * t
      : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };
  
  let animando = false;
  
  const suaveScroll = (destino, elemento) => {
    if (!animando) {
      animando = true;
      let posicaoAtual = elemento.scrollLeft;
      let diferenca = destino - posicaoAtual;
      let duracao = 200; // em milissegundos
      let inicio = null;
  
      const passo = (tempoAtual) => {
        if (!inicio) inicio = tempoAtual;
        let progresso = (tempoAtual - inicio) / duracao;
  
        // Aplicando a função de easing
        let easing = easeInOutCubic(progresso);
        
        let novaPosicao = posicaoAtual + diferenca * easing;
  
        elemento.scrollLeft = novaPosicao;
  
        if (progresso < 1) {
          window.requestAnimationFrame(passo);
        } else {
          animando = false;
        }
      };
  
      window.requestAnimationFrame(passo);
    }
  };
  
  const moverParaDireita = () => {
    if (secaoTRef.current) {
      let destino = secaoTRef.current.scrollLeft + 120;
      suaveScroll(destino, secaoTRef.current);
    }
  };
  
  const moverParaEsquerda = () => {
    if (secaoTRef.current) {
      let destino = secaoTRef.current.scrollLeft - 120;
      suaveScroll(destino, secaoTRef.current);
    }
  };

  /*useEffect(() => {
    const handleWheel = (e) => {
      // Desativar a rolagem vertical padrão
      e.preventDefault();
      
      // Ativar a rolagem horizontal
      if (e.deltaY !== 0) {
        secaoTRef.current.scrollLeft += e.deltaY;
      }
    };

    const secaoTElement = secaoTRef.current;
    secaoTElement.addEventListener("wheel", handleWheel, { passive: false }); // Adicionar { passive: false } para permitir preventDefault

    // Limpar o evento quando o componente for desmontado
    return () => {
      secaoTElement.removeEventListener("wheel", handleWheel);
    };
  }, []);*/


  useEffect(() => {
    const q = query(
      collection(db, 'tattoos'),
      where('SecaoID', '==', props.id),
      orderBy('ordem')
    );

    // Observa as mudanças em tempo real
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const novasTattoos = [];
      querySnapshot.forEach((doc) => {
        novasTattoos.push({ id: doc.id, ...doc.data() });
      });
      setTattoosData(novasTattoos);
    });

    // Limpa o listener ao desmontar o componente
    return () => unsubscribe();
  }, [props.id]);
  
  return (
    <div className="secaoC" style={{ display: props.online ? 'flex' : 'none' }}>
     {props.nomeSessao}
     
      <div className="secaoT" ref={secaoTRef}>
          <div className="moveLeft" onClick={moverParaEsquerda}>
            <HiOutlineChevronLeft style={{fontSize: 50}}/>
          </div>

          <div className="moveRight" onClick={moverParaDireita}>
            <HiOutlineChevronRight style={{fontSize: 50}}/>
          </div>

         {/* Renderize TattooC para cada tattoo */}
         {tattoosData.map((tattoo, index) => (
                    <TattooC key={index} {...tattoo} cor={cor} estilo={estilo} numero={numero}/>
                ))}

                
        
       
      </div>
    </div>
  );
}

export default SecaoC;
