import React, { useState, useEffect } from "react";
import { MdDeleteForever } from "react-icons/md";
import CropEasy from "../../../pages/membros/crop/CropEasy";
import "../Items/items.css";
import PhotosEdit from "./PhotosEdit";
import EditDescricao from "./EditDescricao";
import Tamanho from "./Tamanho";
import EditPreco from "./EditPreco";
import { collection, doc, updateDoc, getDoc, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db } from "../../../services/firebaseConfig";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import EditTamanho from "./EditTamanho";
import { BiSolidEdit } from "react-icons/bi";


function EditTattoo({ handleCancelar, ttID, nomeT, stDesc, descr,
  capa, img1, img2, img3, img4,
   stPreco, preco, stTamanho, tam, setIsSettingsVisible }) {
  const [tattooData, setTattooData] = useState({});
  const [imageData, setImageData] = useState({});
  const [formData, setFormData] = useState({
    nomeT: '',
    stDesc: '',
    descr: '',
    img1: '',
    img2: '',
    img3: '',
    img4: '',
    preco: '',
    stPreco: '',
    tam: '',
    stTamanho: '',
  });

  const handleCroppedImage = (inputId, croppedImage) => {
    setImageData({ ...imageData, [inputId]: croppedImage });
  };

  useEffect(() => {
    setFormData({
      nomeT: nomeT,
      stDesc: stDesc,
      descr: descr,
      img1: img1,
      img2: img2,
      img3: img3,
      img4: img4,
      preco: preco,
      stPreco: stPreco,
      tam: tam,
      stTamanho: stTamanho,
    });
  }, [nomeT, stDesc, descr, img1, img2, img3, img4, preco, stPreco, tam, stTamanho]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    let newValue = value;
  
    // 1. Garante que o primeiro caractere seja maiúsculo.
    newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);
  
    // 2. Limita o comprimento a 30 caracteres.
    if (newValue.length > 30) {
      newValue = newValue.slice(0, 30);
    }
  
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSave = async () => {
    const tattooRef = doc(db, 'tattoos', ttID);

    try {
      await updateDoc(tattooRef, {
        nomeTattoo: formData.nomeT,
        StateDescricao: formData.stDesc,
        descricao: formData.descr,
        StatePreco: formData.stPreco,
        preco: formData.preco,
        StateTamanho: formData.stTamanho,
        tamanho: formData.tam,
      });
      console.log("Documento atualizado com sucesso!");
      {/*setIsSettingsVisible();*/}
      handleCancelar();
    } catch (err) {
      console.error("Erro ao atualizar o documento: ", err);
    }
  };

  return (
    <div className="bgAcao">
      <div className="cardAddSecao">
        <div className="nameAction">
          <BiSolidEdit style={{fontSize: '24px'}}/>
          Editar Tattoo
        </div>

        <div className="contCard">
        {/*{tattooData && (
            <PhotosEdit  handleCroppedImage={handleCroppedImage} formData={formData} setFormData={setFormData}/>
        )}*/}
          <div style={{ display: "block", textAlign: "left" }}>
            Nome da Tattoo:*
            <input
              id="nomeTattoo"
              className="input"
              type="text"
              autocomplete="off"
              name="nomeT"
              placeholder="Digite aqui o nome da tattoo"
              value={formData.nomeT}
          onChange={handleChange}
              // Adicione um onChange se você quiser que o usuário possa editar o nome da tattoo
            />
          </div>

          <EditDescricao formData={formData} setFormData={setFormData}
            // Passe os valores necessários como props para o componente Descricao, se necessário
          />

          <div className="TamPreco">
            <EditTamanho formData={formData} setFormData={setFormData}
              // Passe os valores necessários como props para o componente Tamanho, se necessário
            />
            <EditPreco formData={formData} setFormData={setFormData}
              // Passe os valores necessários como props para o componente Preco, se necessário
            />
          </div>

          <div className="botoesCard" style={{marginTop:'32px'}}>
            <button id="cancel" className="brCardCancel" onClick={handleCancelar}>
              Cancelar
            </button>
            <button id="saveT" className="btCardConfirm" onClick={handleSave}>
              Salvar
            </button>
          </div>
        </div>
      </div>

      <Dialog>
        <CircularProgress />
      </Dialog>
    </div>
  );
}

export default EditTattoo;
