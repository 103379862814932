import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './details.css';

const Carousel = ({ img1, img2, img3, img4, stT, stP, tam, preco }) => {
  // Filtra imagens válidas (não vazias)
  const images = [img1, img2, img3, img4].filter((img) => img);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [carouselX, setCarouselX] = useState(0);
  const carouselRef = useRef();

  const slideWidth = 286; // Tamanho fixo do slide em pixels

  // Detecta se o dispositivo é mobile
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  // Atualiza a posição x do carrossel com base no índice atual
  useEffect(() => {
    setCarouselX(-currentIndex * slideWidth);
  }, [currentIndex]);

  // Função para ir para o próximo slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Função para voltar para o slide anterior
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel-container">
      {/* Carrossel */}
      <motion.div
        ref={carouselRef}
        className="carousel"
        drag={isMobile ? false : 'x'}
        dragConstraints={{
          left: -(slideWidth * (images.length - 1)),
          right: 0,
        }}
        animate={{ x: carouselX }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        {images.map((src, index) => (
          <motion.div
            className="carousel-item"
            key={index}
            style={{ minWidth: `${slideWidth}px` }}
          >
            <img src={src} alt={`Slide ${index}`} />
          </motion.div>
        ))}
      </motion.div>

      {/* Setas de Navegação */}
      {images.length > 1 && (
        <>
          <button className="carousel-button left" onClick={prevSlide}>
            &#8249;
          </button>
          <button className="carousel-button right" onClick={nextSlide}>
            &#8250;
          </button>
        </>
      )}

      {/* Bolinhas de Indicadores */}
      {images.length > 1 && (
        <div className="carousel-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      )}
      <div className='tDet--tamanho' style={{ display: stT ? 'flex' : 'none' }}>{tam} cm</div>
      <div className='tDet--preco' style={{ display: stP ? 'flex' : 'none' }}>R${preco}</div>
    </div>
  );
};

export default Carousel;
