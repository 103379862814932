// sdired.js

import React, { useEffect } from 'react';

function SDIred() {
  useEffect(() => {
    // Realize o redirecionamento ao carregar a página
    window.location.href = "https://pay.kiwify.com.br/3nCYZAR";
  }, []); // O segundo parâmetro vazio indica que o useEffect deve executar apenas uma vez, ao montar o componente

  // Retornar algo (pode ser vazio, já que será redirecionado)
  return <div></div>;
}

export default SDIred;
