import { Route, Routes } from "react-router-dom";
import Login from "../pages/login/LoginPage";
import Register from "../pages/register/register";
import Home from "../pages/membros/AMembros";
import Home2 from "../pages/membros/AMembros2";
import Img from "../pages/membros/Img";
import Bio from "../pages/bio/bio";
import Pos from "../pages/register/PosRegister";
import Personalizacao from "../pages/register/Personalizacao";
import NewEstudio from "../pages/register/NewEstudio";
import Convites from "../pages/convites/convites";
import Termo from "../components/C_Body/TermosePolitica/termo";
import Politica from "../components/C_Body/TermosePolitica/politica";
import RegisterLista from "../pages/register/register copy";
import Register2 from "../pages/register/register copy 2";
import SDIred from "../pages/login/sdired";
import SdiLp from "../pages/login/SdiLp";
import SdiLpO from "../pages/login/SdiLpO";
import Home3 from "../pages/membros/DKT/AMembros3";
import MembrosPage from "../pages/membros/Main";
import Clientes from "../pages/membros/Clientes";
import Termo2 from "../components/C_Body/TermosePolitica/termo2";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Confirm from "../pages/login/confirm"; // Importando o hook corretamente


/* global fbq */

export function AppRoutes(){

    useEffect(() => {
        if (window.location.hostname === "ktalus.pro" && window.location.pathname === "/") {
          window.location.href = "http://site.ktalus.pro";
        }
      }, []);
      
      const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/knew") {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

      fbq("init", "370076518847979");
      fbq("track", "PageView");

      fbq("init", "1053040542642778");
      fbq("track", "PageView");

      fbq("init", "513513738392966");
      fbq("track", "PageView");


    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/kcfm") {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

      fbq("init", "370076518847979");
      fbq("track", "PageView");
      fbq("trackCustom", "AssConfirm");

      fbq("init", "1053040542642778");
      fbq("track", "PageView");
fbq("trackCustom", "AssConfirm");

      fbq("init", "513513738392966");
      fbq("track", "PageView");
      fbq("trackCustom", "AssConfirm");

    }
  }, [location]);

    return (
     
        <Routes>
            <Route path="/login" element={<Login/>} />
            <Route path="/knew" element={<Register2/>} />
            <Route path="/convite/:codigoParceiro" element={<RegisterLista />} />
            {/*<Route path="/home" element={<Home/> } />*/}
            <Route path="/home" element={<MembrosPage/> } />
            
            <Route path="/img" element={<Img/> } />
            <Route path="/pos" element={<NewEstudio/> } />
            
            <Route path="/:estudioId" Component={Bio} />
            <Route path="/x1xxx" element={<Convites/> } />
            <Route path="/kter" element={<Termo2/> } />
            <Route path="/kpol" element={<Politica/> } />
            <Route path="/klist" element={<RegisterLista/> } />
            <Route path="/ksdi" element={<SDIred/> } />
            <Route path="/sdi" element={<SdiLp/> } />
            <Route path="/sdio" element={<SdiLpO/> } />
            <Route path="/kcli" element={<Clientes/> } />
            <Route path="/kcfm" element={<Confirm/> } />
            
        </Routes>
     
    )
}