import './progress.css';
import PropTypes from 'prop-types';


const ProgressoSave = ({progress}) => {
   return( <div className="bgProgressSave">
    <div className="progress">
        Salvando Tattoo

        <div className='progress--container'>
            <div className='progress--barra' style={{ width: `${progress}%` }}/>
        </div>

    </div>
</div>)
}

ProgressoSave.propTypes = {
    progress: PropTypes.number.isRequired,
  };


export default ProgressoSave;