import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet'; 
import './index.css';
//import './AMembros.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Roboto+Serif:wght@300;400;700&family=Roboto+Slab:wght@300;400;700&family=Roboto+Condensed:wght@400;700&family=Smooch+Sans:wght@400;700&family=SUSE:wght@100..800&family=Alegreya:ital,wght@0,400..900;1,400..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Playpen+Sans:wght@100..800&family=Rajdhani:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap';
document.head.appendChild(link);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
