import React, { useState, useEffect } from "react";

import {  AiOutlineClose } from "react-icons/ai";
import AssCMensal from "./P06AssCMensal";
import AssCAnual from "./P06AssCAnual";

function AssC({ setMostrarPlanosC, plano, email }){
    const [componenteAtivo, setComponenteAtivo] = useState("mensal");

    

    return(
        <div className="bgAcao">
           <div className={componenteAtivo === "mensal" ? "cardAssM" : "cardAssA"}>
                
               

<div className="closePlan" onClick={() => setMostrarPlanosC(false)}><AiOutlineClose/></div>

               {componenteAtivo === 'mensal' && <AssCMensal plano={plano} setComponenteAtivo={setComponenteAtivo} email={email}/>}
               {componenteAtivo === 'anual' && <AssCAnual plano={plano} setComponenteAtivo={setComponenteAtivo} email={email} />}




                

               

            </div>
        </div>
    )
}


export default AssC;