import { useState, useEffect } from 'react';
import {  AiOutlineClose } from "react-icons/ai";
import { getImageURL } from '../register/GlifosImg'; 
import { FaLocationDot } from "react-icons/fa6";


function Sobre({nome, tema, estilo, capa, descricao, setMostrarSobre, endereco }){

    const temaS = {
        vermelho: 'pgBranco bordVermelho', laranja: 'pgBranco bordLaranja', amarelo: 'pgBranco bordAmarelo', verde: 'pgBranco bordVerde', ciano: 'pgBranco bordCiano',
        azul:'pgBranco bordAzul', roxo:'pgBranco bordRoxo', rosa:'pgBranco bordRosa', magenta:'pgBranco bordMagenta', branco: 'pgBranco bordBranco',

        goldVermelho: 'pgVermelho bordAmarelo', goldVerde:'pgVerde bordAmarelo', goldAzul:'pgAzul bordAmarelo', goldRoxo:'pgRoxo bordAmarelo', goldAmarelo:'pgAmarelo bordAmarelo',

        silverVermelho: 'pgVermelho bordBranco', silverVerde:'pgVerde bordBranco', silverAzul:'pgAzul bordBranco', silverRoxo:'pgRoxo bordBranco', silverAmarelo:'pgAmarelo bordBranco',
    
        vermelhoVerde:'pgVerde bordVermelho', laranjaCiano:'pgCiano bordLaranja', verdeVermelho:'pgVermelho bordVerde', azulAmarelo:'pgAzul bordAmarelo',
    };

    const temaFade2 = {
        vermelho:'fdBranco2', laranja:'fdBranco2', amarelo:'fdBranco2', verde:'fdBranco2', ciano:'fdBranco2',
    azul:'fdBranco2', roxo:'fdBranco2', rosa:'fdBranco2', magenta:'fdBranco2', branco:'fdBranco2',
    
    goldVermelho: 'fdVermelho2', goldVerde:'fdVerde2', goldAzul:'fdAzul2', goldRoxo:'fdRoxo2', goldAmarelo:'fdAmarelo2',
    silverVermelho: 'fdVermelho2', silverVerde:'fdVerde2', silverAzul:'fdAzul2', silverRoxo:'fdRoxo2', silverAmarelo:'fdAmarelo2',
    vermelhoVerde:'fdVerde2', laranjaCiano:'fdCiano2', verdeVermelho:'fdVermelho2', azulAmarelo:'fdAmarelo2',
    }

    const text = {
        vermelho: 'txVermelho', laranja: 'txLaranja', amarelo: 'txAmarelo', verde: 'txVerde', ciano: 'txCiano',
        azul:'txAzul', roxo:'txRoxo', rosa:'txRosa', magenta:'txMagenta', branco: 'txBranco',
      
        goldVermelho: 'txAmarelo', goldVerde:'txAmarelo', goldAzul:'txAmarelo', goldRoxo:'txAmarelo', goldAmarelo:'txAmarelo',
      
        silverVermelho: 'txBranco', silverVerde:'txBranco', silverAzul:'txBranco', silverRoxo:'txBranco', silverAmarelo:'txBranco',
      
        vermelhoVerde:'txVermelho', laranjaCiano:'txLaranja', verdeVermelho:'txVerde', azulAmarelo:'txAzul',
      }

    useEffect(() => {
        // Adiciona um novo estado no histórico do navegador
        // Isso faz com que o botão voltar possa ser ativado
        window.history.pushState(null, null, window.location.pathname);
    
        const handlePopState = (event) => {
          event.preventDefault(); // previne o comportamento padrão de voltar
          setMostrarSobre(); // chama a função handleClick
          //window.history.pushState(null, null, window.location.pathname); // reinsere o estado
        };
    
        // Adiciona o evento popstate ao window
        window.addEventListener('popstate', handlePopState);
    
        // Remove o evento ao desmontar o componente
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
    
      }, []);

      const textoFormatado = descricao ? descricao.split('\n').map((str, index, array) => 
      index === array.length - 1 ? str : <>
          {str}
          <br />
      </>
  ) : '';



    return(
        <div className="bgSobre">
            <div className={`cardSobre ${tema ? temaS[tema] || '' : ''}`}>
                <img className='AssCantoA' style={{ display: estilo === 'estiloN' ? 'none' : 'flex' }}
                        src={getImageURL(estilo, tema)}
                        alt=''/>
                <img className='AssCantoD' style={{ display: estilo === 'estiloN' ? 'none' : 'flex' }}
                        src={getImageURL(estilo, tema)}
                        alt=''/>
                <div className="closePlan" onClick={setMostrarSobre}><AiOutlineClose/></div>
                <div className="sobre--capa">
                        <img className="capa--img" src={capa} alt=""/>
                        <div className={`fade2 ${tema ? temaFade2[tema] || '' : ''}`}></div>
                        
                    </div>
                <div className="sobre--conteudo">
                
                    <div className={`sobre--title ${tema ? text[tema] || '' : ''}`}>{nome}</div>

                    

                    <div className="sobre--texto">
                   {textoFormatado}
                    </div>
                    <div className='' style={{display: endereco ? 'flex' : 'none', flexDirection:'row', alignItems:'center', justifyContent:'start', width:'100%', gap:'6px', background:'rgba(255,255,255,0.1)', padding:'8px', borderRadius:'8px', boxSizing:'border-box'}}>
                    <FaLocationDot style={{fontSize:'24px'}} />
                    <div className='endereco'>{endereco}</div>
                    </div>
                </div>



            </div>


        </div>
    )
}


export default Sobre;