import './termoPolitica.css';

function Termo2 (){


    return(
        <div className="termopage">
            <div className='termo--cab'>
                <img className='logoK'
                src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2'
                alt=''/>
                <h1>Termos e condições de uso</h1>
            </div>

            <h2 >1. Termos</h2>
      <p >
        Ao acessar ao site{' '}
        <a href="https://ktalus.pro">Plataforma Ktalus</a>, você concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis e concorda que é responsável pelo cumprimento de todas as leis locais. Se não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.
      </p>
      <h2 >2. Uso de Licença</h2>
      <p >
      É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site da Plataforma Ktalus, apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título. Sob esta licença, você não pode:
      </p>
      <ol>
        <li >Modificar ou copiar os materiais;</li>
        <li >Usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);</li>
        <li >Tentar descompilar ou fazer engenharia reversa de qualquer software contido no site da Plataforma Ktalus;</li>
        <li >Remover quaisquer direitos autorais ou outras notações de propriedade dos materiais;</li>
        <li >Transferir os materiais para outra pessoa ou 'espelhar' os materiais em qualquer outro servidor.</li>
      </ol>
      <p >
      Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida pela Plataforma Ktalus a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrônico ou impresso.
      </p>
      <h2 >3. Isenção de responsabilidade</h2>
      <ol>
        <li >
        O acesso à Plataforma Ktalus só será permitido mediante a adesão a um plano de assinatura mensal ou anual, ou durante o período de teste gratuito oferecido. Caso não haja o pagamento da assinatura ou o período de teste termine, o acesso ao catálogo será imediatamente suspenso, impossibilitando a visualização e edição dos dados. O pagamento deve ser efetuado utilizando o mesmo e-mail cadastrado na plataforma.
        </li>
      </ol>
      <h2 >4. Isenção de responsabilidade</h2>
   <p>Os materiais no site da Plataforma Ktalus são fornecidos 'como estão'. A Plataforma Ktalus não oferece garantias, expressas ou implícitas, e, por este meio, isenta-se de todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialidade, adequação a um fim específico ou não violação de propriedade intelectual.</p>
   <p>A Plataforma Ktalus também não garante a precisão, resultados prováveis ou confiabilidade do uso dos materiais no site, ou de outra forma relacionada a esses materiais ou a qualquer site vinculado.</p>

      <h2 >5. Limitações</h2>
      <p >
      Em nenhum caso, a Plataforma Ktalus ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucros, ou devido à interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais na Plataforma Ktalus, mesmo que um representante autorizado da plataforma tenha sido notificado da possibilidade de tais danos.
      </p>
      <h2 >6. Precisão dos materiais</h2>
      <p >
      Os materiais exibidos no site da Plataforma Ktalus podem incluir erros técnicos, tipográficos ou fotográficos. A Plataforma Ktalus não garante que qualquer material em seu site seja preciso, completo ou atual. A Plataforma Ktalus pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio.
      </p>
      <h2 >7. Inatividade de Conta</h2>
      <p >
      A Plataforma Ktalus reserva o direito de excluir dados, incluindo tatuagens e arquivos, associados a uma conta de usuário inativa por mais de 90 dias, o que significa ausência de pagamento durante esse período. A Plataforma Ktalus pode enviar notificações de lembrete ao e-mail registrado antes da exclusão dos dados. A exclusão por inatividade não gera responsabilidade à Plataforma Ktalus.
      </p>
      <br />
      <h2 >8. Links</h2>
      <p >
      A Plataforma Ktalus não analisou todos os sites vinculados ao seu site e não se responsabiliza pelo conteúdo de qualquer site vinculado. A inclusão de um link não implica endosso pela Plataforma Ktalus do site. O uso de qualquer site vinculado é por conta e risco do usuário.
      </p>
      <br />

  
      <h2 >9. Modificações</h2>
      <p >
      A Plataforma Ktalus pode revisar estes termos de serviço a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual destes termos.
      </p>
      <h2 >10. Lei aplicável</h2>
      <p >
      Estes termos são regidos e interpretados de acordo com as leis locais aplicáveis à Plataforma Ktalus, e você se submete à jurisdição exclusiva dos tribunais daquela localidade.
      </p>


        </div>
    )
}


export default Termo2;