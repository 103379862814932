import React, { useState, useEffect } from "react";

function EditPreco({formData, setFormData}) {
  const [preco, setPreco] = useState(formData.preco);
  const [stPreco, setStPreco] = useState(formData.stPreco);

  useEffect(() => {
    // Sincronizando o estado local com o estado do formulário.
    setPreco(formData.preco);
    setStPreco(formData.stPreco);
  }, [formData]);

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR').format(value);
  };

  const handleToggleClick = (status) => {
    setStPreco(status);
    setFormData({ ...formData, stPreco: status });
  };

  const handleDescricaoChange = (e) => {
    const value = e.target.value.replace(/[.,]/g, "");
    const newPreco = value === "" ? "" : parseFloat(value);
    setPreco(newPreco);
    setFormData({ ...formData, preco: newPreco });
  };

  return (
    <div className="alCol">
      <div className="labelToggle">
        Preço:
        <div className='toggleONP' style={{ display: stPreco ? 'flex' : 'none' }}
        onClick={() => handleToggleClick(false)}
      >
          <div className="toggleIn"></div>
        </div>
        <div className='toggleOFFP' style={{ display: !stPreco ? 'flex' : 'none' }}
        onClick={() => handleToggleClick(true)}
      >
          <div className="toggleIn"></div>
        </div>
      </div>
      <input
        id="preco"
        className={`inputPreco ${stPreco ? "" : "hidden"}`}
        type="text"
        name="preco"
        autocomplete="off"
        value={formatPreco(preco)}
        onChange={handleDescricaoChange}
        inputMode="numeric"
        style={{
          visibility: stPreco ? "visible" : "hidden",
          height: stPreco ? "auto" : "0",
          overflow: "hidden"
        }}
      />
    </div>
  );
}

export default EditPreco;
