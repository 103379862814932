import './progress.css';
import PropTypes from 'prop-types';


const Progresso = ({progress}) => {
   return( <div className="bgProgress">
    <div className="progress">
        <div className='progress--logo'>
            <img className='progress-img'
            src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2' 
            alt=''/>
            
        </div>

        <div className='progress--container'>
            <div className='progress--barra' style={{ width: `${progress}%` }}/>
        </div>

    </div>
</div>)
}

Progresso.propTypes = {
    progress: PropTypes.number.isRequired,
  };


export default Progresso;