import React, { useState, useEffect } from 'react';
import { GiAlliedStar } from "react-icons/gi";
import { FaCircleCheck } from "react-icons/fa6";
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../services/firebaseConfig';


function DefinirCrs({ eID, onClose, topNumber, id }) {
  const [tattoos, setTattoos] = useState([]);
  const [selectedTattooId, setSelectedTattooId] = useState(null); // Novo estado para armazenar o ID do documento selecionado


  useEffect(() => {
    const q = query(collection(db, 'tattoos'), where('EstudioID', '==', eID));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tattoosData = [];
      querySnapshot.forEach((doc) => {
        tattoosData.push({ id: doc.id, ...doc.data() });
      });
      setTattoos(tattoosData);
    });

    return () => unsubscribe();
  }, [eID]);

  const handleSelect = (id) => {
    setSelectedTattooId(id);
  };

  const handleSave = async () => {
    if (selectedTattooId) {
      const selectedTattoo = tattoos.find(t => t.id === selectedTattooId); // Encontra a tattoo selecionada
      const estudioDocRef = doc(db, 'estudio', eID); // Referência para o documento do estúdio
  
      let campo = ''; // Vamos armazenar aqui o campo que queremos atualizar
  
      // Dependendo do valor da prop 'id', escolhemos o campo correspondente
      switch(id) {
        case '1':
          campo = 'imgBio1';
          break;
        case '2':
          campo = 'imgBio2';
          break;
        case '3':
          campo = 'imgBio3';
          break;
        case '4':
          campo = 'imgBio4';
          break;
        case '5':
          campo = 'imgBio5';
          break;
        case '6':
          campo = 'imgBio6';
          break;
        case '7':
          campo = 'imgBio7';
          break;
        // ... Continue até o 7
        default:
          return; // Se o id não for válido, saímos da função
      }
  
      // Atualiza o documento do estúdio
      await updateDoc(estudioDocRef, {
        [campo]: selectedTattoo.img1
      });
  
      onClose(); // Fecha o modal ou a ação que foi aberta
    }
  };



  return (
    <div className="bgAcao">
      <div className="cardAddSecao" onClick={(e) => e.stopPropagation()}>
        <div className="nameAction">
          <GiAlliedStar className="iconCat" />
          Definir Tattoo {id}
        </div>

        <div className="contCard">
          <div className="results">
            {tattoos.map((tattoo) => (
              <div className="cardTopSet2" key={tattoo.id} onClick={() => handleSelect(tattoo.id)}>
                <img className="capaTop1" src={tattoo.img1 || ''} alt="" />
                <div className="selectTop" style={{ display: selectedTattooId === tattoo.id ? 'block' : 'none' }}>
                  <FaCircleCheck style={{ fontSize: '32px', marginTop: '32px' }} />
                </div>
              </div>
            ))}
          </div>

          <div className="botoesCard">
            <button className="brCardCancel" onClick={() => onClose()}>
              Cancelar
            </button>
            <button className="btCardConfirm" onClick={() => handleSave()} disabled={!selectedTattooId}>
            
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefinirCrs;
