import React, { useState, useRef, useEffect } from 'react';
import { BiSolidImageAdd } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { getFirestore, addDoc, collection, setDoc, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getImageURL } from './GlifosImg'; 
import { useNavigate } from "react-router-dom";
import ProgressoSavePos from '../../components/C_Body/Items/saveProgressPos';
import EnviarMensagemAPI from './whats';
import emailjs from 'emailjs-com';


function Perso2({ onNext, onBack, formData, setFormData, initialCroppedPhotoURL, email }) {

  
  
  const fontPage = {
    roboto: 'fontRoboto', slab: 'fontSlab', serif: 'fontSerif', alegreya: 'fontAlegreya', suse: 'fontSuse', rajdhani: 'fontRajdhani',
    playpen: 'fontPlaypen', josefin:'fontJosefin', playfair:'fontPlayfair', nunito:'fontNunito'
  };

  const colorPage = {
    vermelho:'pagePadrao bVer', laranja:'pagePadrao bLan', amarelo:'pagePadrao bAma', verde:'pagePadrao bVerd', ciano:'pagePadrao bCia',
    azul:'pagePadrao bAzu', roxo:'pagePadrao bRox', rosa:'pagePadrao bRos', magenta:'pagePadrao bMag', branco:'pagePadrao bBra',

    goldVermelho: 'pageVer bAma', goldVerde:'pageVerd bAma', goldAzul:'pageAzu bAma', goldRoxo:'pageRox bAma', goldAmarelo:'pageAma bAma',

    silverVermelho: 'pageVer bBra', silverVerde:'pageVerd bBra', silverAzul:'pageAzu bBra', silverRoxo:'pageRox bBra', silverAmarelo:'pageAma bBra',

    vermelhoVerde:'pageVerd bVer', laranjaCiano:'pageCia bLan', verdeVermelho:'pageVer bVerd', azulAmarelo:'pageAma bAzu',
    
    
  };

  const colorBorder = {
    vermelho:'bVer', laranja:'bLan', amarelo:'bAma', verde:'bVerd', ciano:'bCia',
    azul:'bAzu', roxo:'bRox', rosa:'bRos', magenta:'bMag', branco:'bBra',

    goldVermelho: 'bAma', goldVerde:'bAma', goldAzul:'bAma', goldRoxo:'bAma', goldAmarelo:'bAma',

    silverVermelho: 'bBra', silverVerde:'bBra', silverAzul:'bBra', silverRoxo:'bBra', silverAmarelo:'bBra',

    vermelhoVerde:'bVer', laranjaCiano:'bLan', verdeVermelho:'bVerd', azulAmarelo:'bAzu',
    
    
  };
  const navigate = useNavigate();
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleEstiloSelect = (style) => {
    setFormData({ ...formData, estilo: style });
  };


  const handleSave = async () => {
    setShowProgress(true); 
    setProgress(10);
    const db = getFirestore();
    const storage = getStorage();
    const auth = getAuth();

    // Obter usuário atual
    const user = auth.currentUser;

    let downloadURL = '';

    if (initialCroppedPhotoURL) {
        // Carregar a imagem para o Firebase Storage se houver uma imagem disponível
        const storageRef = ref(storage, `estudios/${user.uid}.jpg`);
        const imageBlob = await fetch(initialCroppedPhotoURL).then(r => r.blob());

        const uploadTask = uploadBytesResumable(storageRef, imageBlob);

        await new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Monitorar o progresso do upload
                },
                (error) => {
                    // Tratar erros
                    reject(error);
                },
                async () => {
                    // Upload completo, obter a URL de download
                    downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve();
                }
            );
        });
    }

    setProgress(40);

    // Funções para obter data atual e data de validade
    const getDataAtual = () => {
        const data = new Date();
        return data.toISOString();
    }

    const getDataValidade = () => {
        const data = new Date();
        data.setDate(data.getDate() + 7);
        return data.toISOString();
    }

    // Adicionar documento ao Firestore
    const docRef = await addDoc(collection(db, "estudio"), {
        nomeEstudio: formData.nome,
        nickname: formData.link,
        userID: user.uid,
        whatsapp: `55${formData.ddd.replace(/\s+/g, '')}${formData.celular.replace(/\s+/g, '')}`,
        perfil: downloadURL || '', // Se downloadURL estiver vazio, perfil será uma string vazia
        imgBio1: '', imgBio2: '', imgBio3: '', imgBio4: '', imgBio5: '', imgBio6: '', imgBio7: '',
        tema: formData.cor, fonte: formData.fonte, estilo: formData.estilo, 
        sobre: false, descricao: '', capaSobre: '',
        modoTest: true, statusConta: true, plano: 'Teste', dataConta: getDataAtual(), dataVal: getDataValidade(),
        proxCobranca: '', lastPagData: '', lastPagValor: '',
    });

    setProgress(80);

    // Atualizar o campo iD_estudio com o ID do documento criado
    await setDoc(docRef, { iD_estudio: docRef.id }, { merge: true });

    // Atualizar o campo 'estudio' no documento 'user' com o ID do documento que foi criado na coleção 'estudio'
    const q = query(collection(db, "user"), where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        setDoc(doc.ref, { estudio: docRef.id, pos: true }, { merge: true });
    });

    console.log("Documento escrito com o ID: ", docRef.id);
    setProgress(100);

    EnviarMensagemAPI({
        numero: `55${formData.ddd.replace(/\s+/g, '')}${formData.celular.replace(/\s+/g, '')}`,
        nomeEstudio: `${formData.nome}`,
    });

    setTimeout(() => {
        navigate('/home');
    }, 2000);
};


  useEffect(() => {
    if (!formData.estilo) { 
      setFormData(prevState => ({
        ...prevState,
        estilo: "estiloN" 
      }));
    }
  }, []);


    return(
        <div className='pr'>
          {showProgress && <ProgressoSavePos progress={progress} />}
          <img style={{ width: "150px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2"
                    alt="Ktalus"/>

<span style={{marginTop:'24px' ,fontSize:'24px', fontWeight:'700', color:"#fff"}}>Passo 5 <span style={{ fontWeight:'100', color: '#dffc01'}}>de 5</span></span>
  <span style={{ color:'#dffc01',marginBottom:'8px',fontSize:'14px'}}>Definir o estilo de bordas do seu catálogo</span>
  <hr style={{ background:'#dffc01',marginBottom:'24px',}}/>

<div className="pr--prevCont bgly">
            <div className="pr--prevCont--title">Preview</div>
            <div className={`previewPerfil ${formData.fonte ? fontPage[formData.fonte] || '' : ''} ${formData.cor ? colorPage[formData.cor] || '' : ''}`}>
                  <div className='previewPerfilb'></div>
                  
                  <img className='Prev--cantoD' 
                  src={getImageURL(formData.estilo, formData.cor)}
                  alt='' style={{ display: formData.estilo === 'estiloN' ? 'none' : 'flex' }}/>

                  <img className='Prev--cantoC' 
                  src={getImageURL(formData.estilo, formData.cor)}
                  alt='' style={{ display: formData.estilo === 'estiloN' ? 'none' : 'flex' }}/>

                  <img className='Prev--cantoB' 
                  src={getImageURL(formData.estilo, formData.cor)}
                  alt=''style={{ display: formData.estilo === 'estiloN' ? 'none' : 'flex' }}/>

                  <img className='Prev--cantoA' 
                  src={getImageURL(formData.estilo, formData.cor)}
                  alt=''style={{ display: formData.estilo === 'estiloN' ? 'none' : 'flex' }}/>




                  <div className={`prepreviewPerfil-borda ${formData.cor ? colorBorder[formData.cor] || '' : ''}`}>
                    <img className="prepreviewPerfil-foto" src={initialCroppedPhotoURL || 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Fperfil%20off.svg?alt=media&token=59b40760-ce6a-48e8-a0ba-35d2e9adcbbf'} alt="" background="#fff"/>
                  </div>
                  {formData.nome}
                </div>
                <p className='p--Info'>Você poderá mudar a personalização depois</p>

          </div>

    <div className='pr--labelsPerso'>

    <div className="bgly2" style={{ width:'-webkit-fill-available', padding:'16px', borderRadius:'16px'}}>

    <label>Escolha um estilo de bordas: </label>
    <div className='estilo'>

      <div id='estiloN' className={`estilo--container ${formData.estilo === "estiloN" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estiloN")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F01%20none.png?alt=media&token=8f2ae227-56e9-480e-ada2-16274cde4446&_gl=1*14hlihh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyMjAwLjYwLjAuMA..'
        alt=''/>
      </div>

      <div id='estilo01' className={`estilo--container ${formData.estilo === "estilo1" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo1")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fst1.svg?alt=media&token=4f6af557-6ee0-4e35-a2fa-9d85734c5669'
        alt=''/>
      </div>

      <div id='estilo02' className={`estilo--container ${formData.estilo === "estilo2" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo2")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fst2.svg?alt=media&token=87606c53-1ae1-421a-9f02-92480b5a95d6'
        alt=''/>
      </div>

      <div id='estilo03' className={`estilo--container ${formData.estilo === "estilo3" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo3")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fst3.svg?alt=media&token=5e12306e-a64a-439b-82cb-58bd37e464fa'
        alt=''/>
      </div>

      <div id='estilo04' className={`estilo--container ${formData.estilo === "estilo4" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo4")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4.svg?alt=media&token=231c33d9-f345-4e65-92b4-e19d4ae9c3bf'
        alt=''/>
      </div>

      <div id='estilo05' className={`estilo--container ${formData.estilo === "estilo5" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo5")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5.svg?alt=media&token=5baed75c-d347-43f9-aca3-6c39cedd31a3'
        alt=''/>
      </div>

      <div id='estilo06' className={`estilo--container ${formData.estilo === "estilo6" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo6")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6.svg?alt=media&token=55dfe06d-004e-474c-bd82-ae3ea32a491b'
        alt=''/>
      </div>

      <div id='estilo07' className={`estilo--container ${formData.estilo === "estilo7" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo7")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8.svg?alt=media&token=d3b59a47-78d3-43b4-8eac-c481ed008f1c'
        alt=''/>
      </div>

      {/*<div id='estilo08' className={`estilo--container ${formData.estilo === "estilo8" ? "estiloActive" : ""}`} onClick={() => handleEstiloSelect("estilo3")}>
        <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F04%20style.png?alt=media&token=7121066f-98de-4a58-a5c4-27ce4ae7c108&_gl=1*4b0kjh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyNDQxLjQ5LjAuMA..'
        alt=''/>
      </div>*/}

    </div> </div>
                
   
                
</div>
      <div className='pr--buttons'>
      <button className='pr--back' onClick={onBack} >Voltar</button>
        <button className='pr--next' onClick={handleSave} >Salvar</button>
      </div>
    

    </div>
  );
}

export default Perso2;