import React, { useEffect, useState } from 'react';
import ClipboardJS from 'clipboard';
import HeaderPage from "../../components/C_Body/HeaderPage";
import CustomQRCode from "./QrCode";
import { HiLink } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa6";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { AiOutlineCheckCircle } from "react-icons/ai";

function P10SDI({ perfil, nick, setComponenteAtivo }) {
    const [showAlert, setShowAlert] = useState(false);

    function abrirLink() {
        window.open('https://drive.google.com/file/d/1sptUnew8DThBd72C6_OZK1G6iKqK8B-l/view?usp=sharing', '_blank');
      }

    return (
        <>
            <HeaderPage pg={'Stories do Desejo Intenso'} setComponenteAtivo={setComponenteAtivo} />
            <div className="linkpage">
              <div style={{color:"#fff", fontSize:'24px', fontWeight:700, maxWidth:'360px', marginTop:'32px'}}>Você ganhou<br/>1 Roteiro Estratégico</div> 
              <div style={{color:"#FEEC6E", fontSize:'18px', fontWeight:400, maxWidth:'360px'}}>que faz parte do Stories do<br/> Desejo Intenso(SDI)</div>
              <div style={{color:"#FEEC6E", fontSize:'18px', fontWeight:400, maxWidth:'360px', marginTop:'32px', marginBottom:'16px'}}>Para baixar, clique no botão abaixo</div>
              <div id='copyLink' className="btPadrao" onClick={abrirLink}>
                    {/* Ícones e outros elementos */}
                    <FaArrowAltCircleDown style={{ fontSize: '24px' }} /> Baixar Roteiro
                </div>   
            </div>
        </>
    );
}

export default P10SDI;
