import './Moldura.css';
import { getImageURL } from '../register/GlifosImg'; 


function Moldura({cor, estilo}){
    const tema = {
        vermelho: 'bordVermelho', laranja: 'bordLaranja', amarelo: 'bordAmarelo', verde: 'bordVerde', ciano: 'bordCiano',
        azul:'bordAzul', roxo:'bordRoxo', rosa:'bordRosa', magenta:'bordMagenta', branco: 'bordBranco',

        goldVermelho: 'bordAmarelo', goldVerde:'bordAmarelo', goldAzul:'bordAmarelo', goldRoxo:'bordAmarelo', goldAmarelo:'bordAmarelo',

        silverVermelho: 'bordBranco', silverVerde:'bordBranco', silverAzul:'bordBranco', silverRoxo:'bordBranco', silverAmarelo:'bordBranco',
    
        vermelhoVerde:'bordVermelho', laranjaCiano:'bordLaranja', verdeVermelho:'bordVerde', azulAmarelo:'bordAmarelo',
    };



    return(
        <div id="moldura">
                <div id='borda' className={cor ? tema[cor] || '' : ''}></div>
                <img alt=''
                src={getImageURL(estilo, cor)}
                className='cantoA'style={{ display: estilo === 'estiloN' ? 'none' : 'flex' }}/>

                <img alt=''
                src={getImageURL(estilo, cor)}
                className='cantoB'style={{ display: estilo === 'estiloN' ? 'none' : 'flex' }}/>

                <img alt=''
                src={getImageURL(estilo, cor)}
                className='cantoC'style={{ display: estilo === 'estiloN' ? 'none' : 'flex' }}/>
                
                <img alt=''
                src={getImageURL(estilo, cor)}
                className='cantoD'style={{ display: estilo === 'estiloN' ? 'none' : 'flex' }}/>

                
              </div>
    )
}

export default Moldura;