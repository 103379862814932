import './progress.css';
import PropTypes from 'prop-types';


const ProgressoSavePos = ({progress}) => {
   return( <div className="bgProgressSave">
    <div className="progress">
        Concluindo conta...

        <div className='progress--container'>
            <div className='progress--barra' style={{ width: `${progress}%` }}/>
        </div>

    </div>
</div>)
}

ProgressoSavePos.propTypes = {
    progress: PropTypes.number.isRequired,
  };


export default ProgressoSavePos;