import React, { useState, useRef, useEffect } from 'react';
import { BiSolidImageAdd } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { getFirestore, addDoc, collection, setDoc, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import CropEasy1x1 from '../membros/crop/CropEasy1x1';

function PosP2({ onNext, onBack, initialCroppedPhotoURL, setGlobalCroppedPhotoURL, formData }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [croppedPhotoURL, setCroppedPhotoURL] = useState(initialCroppedPhotoURL);
  
  const fileInputRef = useRef(null);

  useEffect(() => {
    setGlobalCroppedPhotoURL(croppedPhotoURL);
  }, [croppedPhotoURL, setGlobalCroppedPhotoURL]);

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxFileSize = 20 * 1024 * 1024; // 10 MB em bytes
  
    if (file) {
      // Verifique o tamanho do arquivo
      if (file.size <= maxFileSize) {
        setSelectedFile(file);
        setOpenCrop(true);
      } else {
        alert('O arquivo é muito grande! Por favor, selecione um arquivo de no máximo 10 MB.');
      }
    }
  };
  

  const handleRemove = () => {
    setSelectedFile(null); 
    setCroppedPhotoURL(null); 
    setOpenCrop(false); 
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  

  {/*const handleSave = async () => {
    const db = getFirestore();
    const storage = getStorage();
    const auth = getAuth();
  

    // Obter usuário atual
    const user = auth.currentUser;

    // Carregar a imagem para o Firebase Storage
    const storageRef = ref(storage, `estudios/${user.uid}/${formData.nome}/perfil.jpg`);
    const imageBlob = await fetch(initialCroppedPhotoURL).then(r => r.blob());
    
    const uploadTask = uploadBytesResumable(storageRef, imageBlob);

    uploadTask.on('state_changed', 
      (snapshot) => {
        // Monitorar o progresso do upload
      }, 
      (error) => {
        // Tratar erros
      }, 
      () => {
        // Upload completo, obter a URL de download
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // Adicionar documento ao Firestore
          const docRef = await addDoc(collection(db, "estudio"), {
            nomeEstudio: formData.nome,
            nickname: formData.link,
            userID: user.uid,
            whatsapp: `55${formData.ddd.replace(/\s+/g, '')}${formData.celular.replace(/\s+/g, '')}`,
            perfil: downloadURL,
            imgBio1: '', imgBio2: '', imgBio3: '', imgBio4: '', imgBio5: '', imgBio6: '', imgBio7: ''
          });

          // Agora que o documento foi criado, atualize-o para adicionar o campo iD_estudio com o ID do documento criado
    await setDoc(docRef, { iD_estudio: docRef.id }, { merge: true });

    // Busque o documento na coleção 'user' onde o campo 'uid' seja igual ao ID do usuário atual
    const q = query(collection(db, "user"), where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);

    // Atualize o campo 'estudio' com o ID do documento que foi criado na coleção 'estudio'
    querySnapshot.forEach((doc) => {
      setDoc(doc.ref, { estudio: docRef.id }, { merge: true });
    });

    console.log("Documento escrito com o ID: ", docRef.id);
  });
      }
    );
  };*/}
  

    return(
        <div className='pr'>
            <img style={{ width: "150px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2"
                    alt="Ktalus"/>
                    

    <div className='pr--labels2'>

    <span style={{marginTop:'24px' ,fontSize:'24px', fontWeight:'700'}}>Passo 2 <span style={{ fontWeight:'100', color: '#dffc01'}}>de 5</span></span>
  <span style={{ color:'#dffc01', marginBottom:'8px',fontSize:'14px'}}>Definir uma foto de perfil</span>
  <hr style={{ background:'#dffc01',marginBottom:'24px',}}/>


            <input id='fotoperfil' 
             type='file' 
             ref={fileInputRef} 
             accept=".jpg, .jpeg, .png, .webp, .heic, .heif" 
             onChange={handleFileChange} 
             style={{display:'none'}} 
      />

      {croppedPhotoURL ? (
        <div className='pr--previewFoto'>
          <img className='prevFoto' src={croppedPhotoURL} alt='' />
          <div className='remover' onClick={handleRemove}>
            <BsTrash style={{fontSize:'24px'}} />
            Remover
          </div>
        </div>
      ) : (
        <div className='pr--upload' onClick={handleClickUpload}>
          <BiSolidImageAdd style={{fontSize:'64px'}} />
          Carregar foto de perfil
        </div>
      )}

        {openCrop && (
        <div className={openCrop ? "crop-popup" : "crop-popup-hidden"}>
        <CropEasy1x1 
        photoURL={URL.createObjectURL(selectedFile)} 
        setOpenCrop={setOpenCrop} 
        setCroppedPhotoURL={setCroppedPhotoURL} 
        handleRemove={handleRemove}
        />
        </div>
        )}

</div>
      <div className='pr--buttons'style={{ }}>
        <button className='pr--back' onClick={onBack}>Voltar</button>
        <button className='pr--next' onClick={onNext}>Avançar</button>
      </div>
    

    </div>
  );
}

export default PosP2;