import React, { useRef, useEffect, useState } from "react";
import TattooC from "./TattooC";
import TattooTop from "./TattooTop";
import { FaRankingStar, FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { db } from '../../services/firebaseConfig';
import { onSnapshot, query, collection, where, orderBy } from "firebase/firestore";
import { NumberTop } from './NumberTop'; 
import TattooDetails from "./TattooDetails";
import { GrNext, GrPrevious} from "react-icons/gr";
import { GiStaryu } from "react-icons/gi";

function SecaoTop({eID, cor, style, estilo, numero}) {

  const [detailsTop, setDetailsTop] = useState(false);

  const clickTDetails = () => {
    setTatuagemSelecionada(!tatuagemSelecionada);
  };

  const handleCloseDetails = () => {
    setTatuagemSelecionada(false);
  };
  
  const secaoTRef = useRef(null);
  const [top1Tattoo, setTop1Tattoo] = useState(null);
  const [top2Tattoo, setTop2Tattoo] = useState(null);
  const [top3Tattoo, setTop3Tattoo] = useState(null);
  const [top4Tattoo, setTop4Tattoo] = useState(null);
  const [top5Tattoo, setTop5Tattoo] = useState(null);
  const [top6Tattoo, setTop6Tattoo] = useState(null);
  const [top7Tattoo, setTop7Tattoo] = useState(null);


  const bord = {
    vermelho: 'ttVermelho', laranja: 'ttLaranja', amarelo: 'ttAmarelo', verde: 'ttVerde', ciano: 'ttCiano',
    azul:'ttAzul', roxo:'ttRoxo', rosa:'ttRosa', magenta:'ttMagenta', branco: 'ttBranco',

    goldVermelho: 'ttAmarelo', goldVerde:'ttAmarelo', goldAzul:'ttAmarelo', goldRoxo:'ttAmarelo', goldAmarelo:'ttAmarelo',

    silverVermelho: 'ttBranco', silverVerde:'ttBranco', silverAzul:'ttBranco', silverRoxo:'ttBranco', silverAmarelo:'ttBranco',

    vermelhoVerde:'ttVermelho', laranjaCiano:'ttLaranja', verdeVermelho:'ttVerde', azulAmarelo:'ttAzul',
}

const icon = {
  vermelho: 'icVermelho', laranja: 'icLaranja', amarelo: 'icAmarelo', verde: 'icVerde', ciano: 'icCiano',
  azul:'icAzul', roxo:'icRoxo', rosa:'icRosa', magenta:'icMagenta', branco: 'icBranco',

  goldVermelho: 'icAmarelo', goldVerde:'icAmarelo', goldAzul:'icAmarelo', goldRoxo:'icAmarelo', goldAmarelo:'icAmarelo',

  silverVermelho: 'icBranco', silverVerde:'icBranco', silverAzul:'icBranco', silverRoxo:'icBranco', silverAmarelo:'icBranco',

  vermelhoVerde:'icVermelho', laranjaCiano:'icLaranja', verdeVermelho:'icVerde', azulAmarelo:'icAzul',
}
const easeInOutCubic = (t) => {
  return t < 0.5
    ? 4 * t * t * t
    : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
};

let animando = false;

const suaveScroll = (destino, elemento) => {
  if (!animando) {
    animando = true;
    let posicaoAtual = elemento.scrollLeft;
    let diferenca = destino - posicaoAtual;
    let duracao = 200; // em milissegundos
    let inicio = null;

    const passo = (tempoAtual) => {
      if (!inicio) inicio = tempoAtual;
      let progresso = (tempoAtual - inicio) / duracao;

      // Aplicando a função de easing
      let easing = easeInOutCubic(progresso);
      
      let novaPosicao = posicaoAtual + diferenca * easing;

      elemento.scrollLeft = novaPosicao;

      if (progresso < 1) {
        window.requestAnimationFrame(passo);
      } else {
        animando = false;
      }
    };

    window.requestAnimationFrame(passo);
  }
};

const moverParaDireita = () => {
  if (secaoTRef.current) {
    let destino = secaoTRef.current.scrollLeft + 120;
    suaveScroll(destino, secaoTRef.current);
  }
};

const moverParaEsquerda = () => {
  if (secaoTRef.current) {
    let destino = secaoTRef.current.scrollLeft - 120;
    suaveScroll(destino, secaoTRef.current);
  }
};




  /*useEffect(() => {
    const handleWheel = (e) => {
      // Desativar a rolagem vertical padrão
      e.preventDefault();
      
      // Ativar a rolagem horizontal
      if (e.deltaY !== 0) {
        secaoTRef.current.scrollLeft += e.deltaY;
      }
    };

    const secaoTElement = secaoTRef.current;
    secaoTElement.addEventListener("wheel", handleWheel, { passive: false }); // Adicionar { passive: false } para permitir preventDefault

    // Limpar o evento quando o componente for desmontado
    return () => {
      secaoTElement.removeEventListener("wheel", handleWheel);
    };
  }, []);*/

  const fetchTopTattoo = (topValue, setStateFunc) => {
    const q = query(
      collection(db, 'tattoos'),
      where('EstudioID', '==', eID),
      where('top', '==', topValue)
    );

    onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();
        setStateFunc({ id: querySnapshot.docs[0].id, ...docData });
      } else {
        setStateFunc(null);
      }
    });
  };

   // Usando useEffect para buscar tatuagens com valores de "top" de 1 a 7
   useEffect(() => {
    fetchTopTattoo(1, setTop1Tattoo);
    fetchTopTattoo(2, setTop2Tattoo);
    fetchTopTattoo(3, setTop3Tattoo);
    fetchTopTattoo(4, setTop4Tattoo);
    fetchTopTattoo(5, setTop5Tattoo);
    fetchTopTattoo(6, setTop6Tattoo);
    fetchTopTattoo(7, setTop7Tattoo);
    // ... (E assim por diante até fetchTopTattoo(7, setTop7Tattoo))
  }, [eID]);

  const [tatuagemSelecionada, setTatuagemSelecionada] = useState(null);

  // Função para mostrar detalhes da tatuagem
const mostrarDetalhes = (tatuagem) => {
  setTatuagemSelecionada(tatuagem);
};



  const TattooComPosicao = ({ tattoo, posicaoUrl }) => {
    return (
      <div className="TattoComPosicao">
        {tattoo ? (  // Verificar se tattoo não é nulo
          <div className={`tattooTop ${cor ? bord[cor] || '' : ''}`}>
            <img className="tattooTop--img" src={tattoo.img1} alt=""/>
          </div>
        ) : (
          <div className="tattooTop--placeholder">Carregando...</div>
        )}
        <img className="posicao" src={posicaoUrl} alt="" />
      </div>
    );
  };

  return (
    <div className="secaoTop" style={style}>
      <div className="cabTop">
        <div className="titleTop"><GiStaryu className= {`iconTop ${cor ? icon[cor] || '' : ''}`} style={{marginRight: "8px", fontSize:"40px"}}/>
        TOP 7</div>
        <div className="nextBackTop">
          <div className="btNXPV" onClick={moverParaEsquerda}><FaAngleLeft /></div> {/* Aplicando referência aqui */}
          <div className="btNXPV" onClick={moverParaDireita}><FaAngleRight /></div> {/* Aplicando referência aqui */}
      </div>
      </div>
      
      <div className="secaoTop1" ref={secaoTRef}>

      <div id="TTop7" className="TattoComPosicao" onClick={() => mostrarDetalhes(top7Tattoo)}>
      <div className={`tattooTop ${cor ? bord[cor] || '' : ''}`}>
              <img className="tattooTop--img" src={top7Tattoo ? top7Tattoo.img1 : 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/Group%20272.svg?alt=media&token=c2adaba8-ea60-4a5d-8089-9775f6e8284f'} alt=""/>
            </div>
            <img className="posicao"
            src={NumberTop('7',cor)}
            />

        </div>

        <div id="TTop6" className="TattoComPosicao" onClick={() => mostrarDetalhes(top6Tattoo)}>
        <div className={`tattooTop ${cor ? bord[cor] || '' : ''}`}>
              <img className="tattooTop--img" src={top6Tattoo ? top6Tattoo.img1 : 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/Group%20272.svg?alt=media&token=c2adaba8-ea60-4a5d-8089-9775f6e8284f'} alt=""/>
            </div>
            <img className="posicao"
            src={NumberTop('6',cor)}
            />

            
        </div>

        <div id="TTop5" className="TattoComPosicao" onClick={() => mostrarDetalhes(top5Tattoo)}>
        <div className={`tattooTop ${cor ? bord[cor] || '' : ''}`}>
              <img className="tattooTop--img" src={top5Tattoo ? top5Tattoo.img1 : 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/Group%20272.svg?alt=media&token=c2adaba8-ea60-4a5d-8089-9775f6e8284f'} alt=""/>
            </div>
            <img className="posicao"
            src={NumberTop('5',cor)}
            />

            
        </div>

        <div id="TTop4" className="TattoComPosicao" onClick={() => mostrarDetalhes(top4Tattoo)}>
        <div className={`tattooTop ${cor ? bord[cor] || '' : ''}`}>
              <img className="tattooTop--img" src={top4Tattoo ? top4Tattoo.img1 : 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/Group%20272.svg?alt=media&token=c2adaba8-ea60-4a5d-8089-9775f6e8284f'} alt=""/>
            </div>
            <img className="posicao"
            src={NumberTop('4',cor)}
            />

            
        </div>

        <div id="TTop3" className="TattoComPosicao" onClick={() => mostrarDetalhes(top3Tattoo)}>
        <div className={`tattooTop ${cor ? bord[cor] || '' : ''}`}>
              <img className="tattooTop--img" src={top3Tattoo ? top3Tattoo.img1 : 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/Group%20272.svg?alt=media&token=c2adaba8-ea60-4a5d-8089-9775f6e8284f'} alt=""/>
            </div>
            <img className="posicao"
            src={NumberTop('3',cor)}
            />

        </div>

        <div id="TTop2" className="TattoComPosicao" onClick={() => mostrarDetalhes(top2Tattoo)}>
            <div className={`tattooTop ${cor ? bord[cor] || '' : ''}`}>
              <img className="tattooTop--img" src={top2Tattoo ? top2Tattoo.img1 : 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/Group%20272.svg?alt=media&token=c2adaba8-ea60-4a5d-8089-9775f6e8284f'} alt=""/>
            </div>
            <img className="posicao"
            src={NumberTop('2',cor)}
            />

        </div>

        <div id="TTop1" className="TattoComPosicao" style={{marginRight:"24px"}} onClick={() => mostrarDetalhes(top1Tattoo)}>
        <div className={`tattooTop ${cor ? bord[cor] || '' : ''}`}>
              <img className="tattooTop--img" src={top1Tattoo ? top1Tattoo.img1 : 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/Group%20272.svg?alt=media&token=c2adaba8-ea60-4a5d-8089-9775f6e8284f'} alt=""/>
            </div>
            <img className="posicao"
            src={NumberTop('1',cor)}
            />

        </div>

        {tatuagemSelecionada && <TattooDetails onClose={handleCloseDetails} cor={cor} estilo={estilo} numero={numero}
             stDescricao={tatuagemSelecionada.StateDescricao} 
             stTamanho={tatuagemSelecionada.StateTamanho}
             stPreco={tatuagemSelecionada.StatePreco}
             descricao={tatuagemSelecionada.descricao}
             nome={tatuagemSelecionada.nomeTattoo}
             preco={tatuagemSelecionada.preco}
             tamanho={tatuagemSelecionada.tamanho}
             img1={tatuagemSelecionada.img1}
             img2={tatuagemSelecionada.img2}
             img3={tatuagemSelecionada.img3}
             img4={tatuagemSelecionada.img4}/>}
        
       
      </div>
    </div>
  );
}

export default SecaoTop;
