import "../membros/AMembros.css"; // Importe seus estilos personalizados
import React, { useState, useRef } from "react";
import CropEasy from "./crop/CropEasy";
import AddTattooTest from "../../components/C_Body/Items/AddTattooTest";

function ImageCropper() {
  

  return (
    <div>
      <div>
        <h1>Meu WebApp</h1>
        <button>Mostrar AddTattoo</button>

        <AddTattooTest/>
        
      </div>
    </div>
  );
}

export default ImageCropper;
