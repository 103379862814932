import React, { useState, useEffect } from "react";

import AssTestMensal from "./P06AssTestMensal";
import AssTestAnual from "./P06AssTestAnual";
import {  AiOutlineClose } from "react-icons/ai";

function AssTest({ setMostrarPlanos}){
    const [componenteAtivo, setComponenteAtivo] = useState("mensal");



    return(
        <div className="bgAcao">
            <div className={componenteAtivo === "mensal" ? "cardAssM" : "cardAssA"}>
                
               

<div className="closePlan" onClick={() => setMostrarPlanos(false)}><AiOutlineClose/></div>

               {componenteAtivo === 'mensal' && <AssTestMensal setComponenteAtivo={setComponenteAtivo}/>}
               {componenteAtivo === 'anual' && <AssTestAnual setComponenteAtivo={setComponenteAtivo}/>}




                

               

            </div>
        </div>
    )
}


export default AssTest;