import React, { useState, useEffect, useRef } from "react";
import {
  updateDoc,
  getDocs,
  query,
  where,
  getFirestore,
  collection,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { BiSolidChevronDown, BiSolidChevronUp } from "react-icons/bi";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { SlOptionsVertical } from "react-icons/sl";
import ConteudoSecao from "./ConteudoSecao";
import "./../../pages/membros/AMembros.css";
import SettingsSecao from "./Items/SettingsSecao";

function Secao({
  EstudioID,
  ID_Sessao,
  nomeSessao,
  online,
  ordem,
  openDeleteTattoo,
  closeDeleteTattoo,
  openEditTattoo,
  closeEditTattoo,
  onTattooSelect,
  onSecaoSelect,
  openEditSecao,
  closeEditSecao,
  closeDeleteSecao,
  openDeleteSecao,
}) {
  const [isOnline, setIsOnline] = useState(online);
  const [isConteudoVisivel, setIsConteudoVisivel] = useState(false);
  const [quantidadeSecoes, setQuantidadeSecoes] = useState(0);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [isIconSTAVisible, setIsIconSTAVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const settingsRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleOnline = () => {
    setIsOnline(!isOnline);
    const db = getFirestore();
    const secaoRef = doc(db, "sessoes", ID_Sessao);
    updateDoc(secaoRef, { online: !isOnline });
  };

  const toggleConteudoVisivel = () => {
    setIsConteudoVisivel(!isConteudoVisivel);
  };

  const handleBaixoClick = async () => {
    const db = getFirestore();

    const secoesQuery = query(
      collection(db, "sessoes"),
      where("EstudioID", "==", EstudioID),
      where("ordem", "==", ordem + 1)
    );

    const secoesSnapshot = await getDocs(secoesQuery);

    if (!secoesSnapshot.empty) {
      secoesSnapshot.forEach(async (secaoDoc) => {
        const sessaoProximaRef = doc(db, "sessoes", secaoDoc.id);
        await updateDoc(sessaoProximaRef, { ordem });

        const secaoAtualRef = doc(db, "sessoes", ID_Sessao);
        await updateDoc(secaoAtualRef, { ordem: ordem + 1 });
      });
    }
  };

  const handleCimaClick = async () => {
    const db = getFirestore();

    const secoesQuery = query(
      collection(db, "sessoes"),
      where("EstudioID", "==", EstudioID),
      where("ordem", "==", ordem - 1)
    );

    const secoesSnapshot = await getDocs(secoesQuery);

    if (!secoesSnapshot.empty) {
      secoesSnapshot.forEach(async (secaoDoc) => {
        const sessaoAnteriorRef = doc(db, "sessoes", secaoDoc.id);
        await updateDoc(sessaoAnteriorRef, { ordem });

        const secaoAtualRef = doc(db, "sessoes", ID_Sessao);
        await updateDoc(secaoAtualRef, { ordem: ordem - 1 });
      });
    }
  };

  const handleSettingsClick = () => {
    setIsSettingsVisible(!isSettingsVisible);
    setIsIconSTAVisible(false);
  };

  const handleClickOutsideSettings = (e) => {
    if (settingsRef.current && !settingsRef.current.contains(e.target)) {
      setIsSettingsVisible(false);
      setIsIconSTAVisible(true);
    }
  };

  useEffect(() => {
    const db = getFirestore();
    const secoesQuery = query(
      collection(db, "sessoes"),
      where("EstudioID", "==", EstudioID)
    );

    const unsubscribe = onSnapshot(secoesQuery, (snapshot) => {
      setQuantidadeSecoes(snapshot.size);
    });

    return () => {
      unsubscribe();
    };
  }, [EstudioID]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideSettings);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSettings);
    };
  }, []);

  return (
    <div className="secao">
      <div className="sParte1">
<div style={{ background:'rgba(223,252,1,0.2', borderRadius:'16px 0px 16px 0px', paddingLeft:'16px', paddingRight:'8px', height:'40px', width:'60%',
  display:'flex', flexDirection:'row', marginTop:'-8px',
}}>
        <div className="sNome" onClick={toggleConteudoVisivel}>
          <p className="nomeSecao" style={{ fontSize:'20px'}}> {isMobile && nomeSessao.length > 12
              ? `${nomeSessao.substring(0, 12)}...`
              : nomeSessao}</p>
          {isConteudoVisivel ? (
            <BiSolidChevronUp className="iconNS" />
          ) : (
            <BiSolidChevronDown className="iconNS" />
          )}
        </div>
        </div>

        <div className="sSettings">
          {isOnline ? (
            <div className="toggleOnOut active" onClick={toggleOnline}>
              <p className="toggleOnText">ON</p>
              <div className="toggleInON"></div>
            </div>
          ) : (
            <div className="toggleOffOut active" onClick={toggleOnline}>
              <div className="toggleInOff"></div>
              <p className="toggleOffText">OFF</p>
            </div>
          )}
          {isIconSTAVisible && (
            <SlOptionsVertical
              className="iconSTA"
              onClick={handleSettingsClick}
            />
          )}
          {!isIconSTAVisible && (
            <SlOptionsVertical
              className="iconSTD"
              onClick={handleSettingsClick}
            />
          )}
        </div>
      </div>

      {isConteudoVisivel && <ConteudoSecao estudioID={EstudioID} secaoID={ID_Sessao} openDeleteTattoo={openDeleteTattoo} closeDeleteTattoo={closeDeleteTattoo} 
      openEditTattoo={openEditTattoo} closeEditTattoo={closeEditTattoo} onTattooSelect={onTattooSelect} />}

      <div className="sParte2">
        <div style={{visibility: ordem === 0 ? "hidden" : "visible", width:'40px', height:'24px', background:'rgba(255,255,255,0.1)', borderRadius:'16px'}}>
        <AiOutlineArrowUp
          className="iconNS"
          onClick={handleCimaClick}
          style={{
            visibility: ordem === 0 ? "hidden" : "visible",
          }}
        /> </div>
        #
        <div style={{visibility: ordem === quantidadeSecoes - 1 ? "hidden" : "visible", width:'40px', height:'24px', background:'rgba(255,255,255,0.1)', borderRadius:'16px'}}>
        <AiOutlineArrowDown
          className="iconNS"
          onClick={handleBaixoClick}
          style={{
            visibility:
              ordem === quantidadeSecoes - 1 ? "hidden" : "visible",
          }}
        /></div>
      </div>

      {isSettingsVisible && (
        <div ref={settingsRef}>
          <SettingsSecao scID={ID_Sessao} nmSecao={nomeSessao} eID={EstudioID} setIsSettingsVisible={()=>setIsSettingsVisible(false)} onSecaoSelect={onSecaoSelect}
            openDeleteSecao={openDeleteSecao} closeDeleteSecao={closeDeleteSecao} openEditSecao={openEditSecao} closeEditSecao={closeEditSecao}
            />
        </div>
      )}
    </div>
  );
}

export default Secao;
