import React, { useState, useEffect } from "react";

function EditTamanho({formData, setFormData}) {
  const [tam, setTam] = useState(formData.tam);
  const [stTamanho, setStTamanho] = useState(formData.stTamanho);

  useEffect(() => {
    // Sincronizando o estado local com o estado do formulário.
    setTam(formData.tam);
    setStTamanho(formData.stTamanho);
  }, [formData]);

  const handleToggleClick = (status) => {
    setStTamanho(status);
    setFormData({ ...formData, stTamanho: status });
  };

  const handleTamChange = (e) => {
    let newTam = e.target.value;
  
    // Verifica se o valor é numérico
    if (!/^[0-9]*$/.test(newTam)) {
      newTam = newTam.replace(/[^0-9]/g, "");
    }
  
    // Limita a entrada a 3 caracteres
    if (newTam.length > 3) {
      newTam = newTam.slice(0, 3);
    }
  
    // Atualiza o estado local e o estado do formulário
    setTam(newTam);
    setFormData({ ...formData, tam: newTam });
  };

  return (
    <div className="alCol">
      <div className="labelToggle">
        Tamanho:
        <div className='toggleONT'
        style={{ display: stTamanho ? 'flex' : 'none' }} 
        onClick={() => handleToggleClick(false)}
      >
          <div className="toggleIn"></div>
        </div>
        <div 
          className="toggleOFFT"
          style={{ display: !stTamanho ? 'flex' : 'none' }} 
          onClick={() => handleToggleClick(true)}
        >
          <div className="toggleIn"></div>
        </div>
      </div>
      <div id="tam" className='linhaTam'style={{ display: stTamanho ? 'flex' : 'none' }}>
      <input
        id="tamE"
        className='inputTam'
        type="text"
        name="tam"
        autocomplete="off"
        inputMode="numeric"
        style={{ display: stTamanho ? 'flex' : 'none' }}
        value={tam}
        onChange={handleTamChange}
      />
        cm
      </div>
    </div>
  );
}

export default EditTamanho;
