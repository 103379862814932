import './items.css';
import { useEffect, useRef } from 'react';
import { MdDeleteForever } from "react-icons/md";
import { getDocs, query, where, collection, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll, list } from "firebase/storage";
import { db } from '../../../services/firebaseConfig';

function DeleteSecao({ eID, scID, nome, handleCancelar, setIsSettingsVisible }) {
  const delSecaoRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (delSecaoRef.current && !delSecaoRef.current.contains(event.target)) {
        handleCancelar();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleCancelar]);

  const deleteFolderContents = async (path) => {
    const storage = getStorage();
    const folderRef = ref(storage, path);

    try {
      const listResult = await listAll(folderRef);
      const deletionPromises = listResult.items.map((itemRef) => deleteObject(itemRef));
      await Promise.all(deletionPromises);
    } catch (error) {
      console.error('Erro ao deletar os arquivos da pasta:', error);
    }
  };

  const decrementOrderForSections = async (currentOrder) => {
    try {
      // Consulte todos os documentos cujo EstudioID é igual a eID e ordem > currentOrder
      const sessoesRef = collection(db, "sessoes");
      const queryRef = query(sessoesRef, where("EstudioID", "==", eID), where("ordem", ">", currentOrder));
      const snapshot = await getDocs(queryRef);
  
      // Atualize o campo ordem de cada documento, subtraindo 1
      const updatePromises = snapshot.docs.map((doc) => {
        const data = doc.data();
        const newOrder = data.ordem - 1;
        return updateDoc(doc.ref, { ordem: newOrder });
      });
  
      await Promise.all(updatePromises);
  
    } catch (error) {
      console.error("Erro ao decrementar o campo ordem:", error);
    }
  };
  

  const handleDeletar = async () => {
    try {
      // Obter o campo ordem da seção atual
      const sessoesRef = collection(db, "sessoes");
      const q1 = query(sessoesRef, where("ID_Sessao", "==", scID));
      const sessoesSnapshot = await getDocs(q1);
  
      if (!sessoesSnapshot.empty) {
        const currentOrder = sessoesSnapshot.docs[0].data().ordem;
  
        // Decrementar o campo ordem em todas as seções relevantes
        await decrementOrderForSections(currentOrder);
      }
  
      // Deletar o conteúdo da pasta correspondente na Storage
      const folderPath = `tattoos/${eID}/${scID}`;
      await deleteFolderContents(folderPath);
  
      // Deletar os documentos relacionados da coleção 'tattoos'
      const tattoosRef = collection(db, "tattoos");
      const q2 = query(tattoosRef, where("SecaoID", "==", scID));
      const tattoosSnapshot = await getDocs(q2);
      const tattooDeletionPromises = tattoosSnapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(tattooDeletionPromises);
  
      // Deletar o documento da seção da coleção 'sessoes'
      if (!sessoesSnapshot.empty) {
        const docRef = doc(db, "sessoes", sessoesSnapshot.docs[0].id);
        await deleteDoc(docRef);
      }
  
      {/*setIsSettingsVisible();*/}
      handleCancelar();
    } catch (error) {
      console.error('Erro ao deletar a seção:', error);
    }
  };
  

  return (
    <div className="bgAcao">
      <div className="cardAddSecaoDel" ref={delSecaoRef}>
        <div className="delAction">
          <MdDeleteForever className='iconSet' />
          Deletar seção
        </div>

        <div className="contCardDel">
          Tem certeza que deseja deletar a seção <span className="nomeSDel">{nome}?</span>

          <div className="botoesCardDel">
            <button className="brCardCancelDel" onClick={handleCancelar}>
              Não, cancelar
            </button>
            <button className="btCardConfirmDel" onClick={handleDeletar}>
              Sim, deletar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteSecao;
