import { BsLink45Deg, BsQrCode, BsInfinity, BsFillKeyFill } from "react-icons/bs";
import { HiColorSwatch } from "react-icons/hi";
import { FaRankingStar } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { AiOutlineSafety } from "react-icons/ai";


function AssTestMensal({setComponenteAtivo}){

    function abrirLink() {
        window.open('https://pay.kiwify.com.br/EZ0h0qe', '_blank');
      }
      


    return(
        <div className="ass-modoMensal" >
        <div className="ass--modo">
            <div className="modoAssativo">MENSAL</div>

            {/*<div className="toggleM" onClick={() => setComponenteAtivo("anual")}>
                <div className="toggleM--ballM"></div>
            </div>*/}

            <div className="brilho-texto" onClick={() => setComponenteAtivo("anual")} style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'4px', color:'#Fff', fontWeight:'700'}}>ANUAL<span style={{fontSize:'12px', color:'#dffc01'}}>-34%</span></div>
        
        </div>

      

        <div className="ass--preco">
            <div style={{fontSize:'28px', marginRight:'10px'}}>R$</div> 
            <div style={{fontSize:'116px', lineHeight:0.85, fontWeight:700, position:'relative' }}>127
            <div className="ass--mes" style={{fontWeight:400}} >/MÊS</div></div> 
              
        </div>

        <div style={{width:'100%', display:'flex', justifyContent:'center', color:'#dffc01', fontWeight:'700', fontSize:'14px', marginBottom:'24px' }}>no Pix ou Cartão de Crédito</div>


        <div className="ass--itens">
                <div className="ass--item"><FaRegCircleCheck style={{ fontSize:'18px', color:'#dffc01'}}/>Catálogo Personalizável</div>
                <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Seções e Tattoos ilimitadas</div>
                <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Seção TOP7</div>
                <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Cartão de Visita Virtual</div>
                <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Link Exclusivo</div>
                <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>QR-Code</div>
                <div className="ass--item"><FaRegCircleCheck style={{fontSize:'18px', color:'#dffc01'}}/>Suporte</div>
                
                <div className="ass--item" style={{fontWeight:700}}><BsFillKeyFill style={{fontSize:'18px', color:'#dffc01'}}/>Acesso por 30 dias</div>

        </div>

        <div className="ass--botaoCTAM" onClick={abrirLink}>Quero o Plano Mensal</div>
        <div style={{fontSize:'12px', color:'#dffc01', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', gap:'4px'}}><AiOutlineSafety style={{fontSize:'16px'}}/> Compra segura pela Kiwify</div>

    </div>
    )
    
}


export default AssTestMensal;