import './items.css';
import { useEffect } from 'react';
import { MdDeleteForever } from "react-icons/md";
import { getStorage, ref, listAll } from "firebase/storage"; // Mantemos apenas o necessário
import { db } from '../../../services/firebaseConfig';

function DeleteConta({ userID, handleCancelar }) {

  // Função para encontrar o conteúdo da pasta 'tattoos' no Firebase Storage
  const encontrarPasta = async () => {
    const storage = getStorage();
    const pastaPath = 'tattoos'; // Caminho da pasta 'tattoos'
    const folderRef = ref(storage, pastaPath); // Referência para a pasta no Storage

    try {
      console.log(`Listando arquivos na pasta: ${folderRef.fullPath}`); // Log do caminho da pasta
      const listResult = await listAll(folderRef); // Lista todos os arquivos da pasta
      
      if (listResult.items.length === 0) {
        console.log(`A pasta '${pastaPath}' está vazia ou não existe.`);
        return; // Retorna se a pasta estiver vazia ou não existir
      }

      console.log(`Arquivos encontrados na pasta '${pastaPath}':`); // Log dos arquivos encontrados
      listResult.items.forEach(itemRef => {
        console.log(`Arquivo encontrado: ${itemRef.fullPath}`); // Log de cada arquivo encontrado
      });
      
    } catch (error) {
      console.error('Erro ao encontrar o conteúdo da pasta no Storage:', error);
    }
  };

  // Função para lidar com a confirmação da localização da pasta
  const handleConfirmFind = async () => {
    console.log(`Tentando encontrar a pasta 'tattoos'`); // Log para verificar o caminho
    await encontrarPasta(); // Encontra a pasta
  };

  // useEffect para fazer a busca ao carregar o componente
  useEffect(() => {
    console.log("O ID do user é " + userID);
  }, [userID]); // Apenas loga o ID do usuário

  return (
    <div className="bgAcao">
      <div className="cardAddSecaoDel">
        <div className="delAction">
          <MdDeleteForever className='iconSet' />
          Encontrar Conta Ktalus
        </div>

        <div className="contCardDel" style={{ color: '#fff' }}>
          Tem certeza que deseja encontrar esta conta?<br />
          Isso não fará alterações

          <div className="botoesCardDel">
            <button className="brCardCancelDel" onClick={handleCancelar}>
              Não, cancelar
            </button>
            <button className="btCardConfirmDel" onClick={handleConfirmFind}>
              Sim, encontrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteConta;
